import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { create, fetchById, fetchList, fetchSelectList, remove, update } from './ContractApi';
import { IContractCUModel } from './ContractModels';
import { contractPaging, contractSelectValuesStatus, contractsForSelect } from './ContractSlice';

export const fetchContracts = createAsyncThunk('contracts/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = contractPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchContractsForSelect = createAsyncThunk(
    'contracts/selectList',
    async (args: { search: string; fields?: string[] }) => {
        return await fetchSelectList(args.search, args.fields || []);
    },
    {
        condition(_, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;

            if (contractSelectValuesStatus(state) !== 'idle' || (contractsForSelect(state) ?? []).length) {
                return false;
            }
        }
    }
);
export const fetchContractById = createAsyncThunk('contracts/byId', async (args: number) => {
    return await fetchById(args);
});

export const createContract = createAsyncThunk('contracts/create', async (args: IContractCUModel) => {
    return await create(args);
});

export const updateContract = createAsyncThunk(
    'contracts/update',
    async (args: { id: number; data: IContractCUModel }) => {
        return await update(args.id, args.data);
    }
);

export const removeContract = createAsyncThunk(
    'contracts/remove',
    async (args: { id: number; onSuccess?: () => void }) => {
        await remove(args.id).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);
