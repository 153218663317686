import { useCallback } from 'react';
import { FieldsGenerator, IFieldsGeneratorProps, ISupportedValueType } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { requestTypeForSelect } from '@/data/RequestTypes/RequestTypeSlice';
import { getMegalaxExportMapSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import IntegrationsSettingsMegalaxExportMapItemEnum from '@/utils/enums/MegalaxSettings/IntegrationsSettingsMegalaxExportMapItemEnum';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const MegalaxExportMap = (props: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getMegalaxExportMapSettingsItems);
    const requestTypeList = useAppSelector(requestTypeForSelect) ?? [];
    const { t } = useAppTranslation();

    const keysList = [
        IntegrationsSettingsMegalaxExportMapItemEnum.replacementHolidayLeave,
        IntegrationsSettingsMegalaxExportMapItemEnum.holidayCompensation,
        IntegrationsSettingsMegalaxExportMapItemEnum.holiday,
        IntegrationsSettingsMegalaxExportMapItemEnum.sick,
        IntegrationsSettingsMegalaxExportMapItemEnum.doctor,
        IntegrationsSettingsMegalaxExportMapItemEnum.donatingBlood,
        IntegrationsSettingsMegalaxExportMapItemEnum.dayWithTheKids,
        IntegrationsSettingsMegalaxExportMapItemEnum.maternalLeave,
        IntegrationsSettingsMegalaxExportMapItemEnum.replacementLeave,
        IntegrationsSettingsMegalaxExportMapItemEnum.unexcusedAbsence,
        IntegrationsSettingsMegalaxExportMapItemEnum.unpaidLeave,
        IntegrationsSettingsMegalaxExportMapItemEnum.caringForAFamilyMember,
        IntegrationsSettingsMegalaxExportMapItemEnum.wedding,
        IntegrationsSettingsMegalaxExportMapItemEnum.funeral,
        IntegrationsSettingsMegalaxExportMapItemEnum.obstaclesEmployer,
        IntegrationsSettingsMegalaxExportMapItemEnum.paidLeave
    ];

    const getOptions = useCallback(
        (values: ISupportedValueType, key: IntegrationsSettingsMegalaxExportMapItemEnum) =>
            requestTypeList
                .filter(
                    (item) =>
                        !keysList.filter((keyItem) => keyItem !== key).some((keyItem) => values[keyItem] == item.id) ||
                        item.id == values[key]
                )
                .map((item) => ({ id: `${item.id}`, label: item.name })),
        [requestTypeList]
    );

    return (
        <FieldsGenerator
            {...props}
            name={SettingsTypesEnum.MegalaxExportMap}
            fields={[
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.replacementHolidayLeave,
                        label: t('label.replacementHolidayLeave', 'Replacement Holiday Leave'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.replacementHolidayLeave
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.replacementHolidayLeave),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.holidayCompensation,
                        label: t('label.holidayCompensation', 'Holiday Compensation'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.holidayCompensation
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.holidayCompensation),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.holiday,
                        label: t('label.holiday', 'Holiday'),
                        value: (getSettingsItemValueByKey(data, IntegrationsSettingsMegalaxExportMapItemEnum.holiday) ??
                            '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.holiday),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.sick,
                        label: t('label.sick', 'Sick'),
                        value: (getSettingsItemValueByKey(data, IntegrationsSettingsMegalaxExportMapItemEnum.sick) ??
                            '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.sick),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.doctor,
                        label: t('label.doctor', 'Doctor'),
                        value: (getSettingsItemValueByKey(data, IntegrationsSettingsMegalaxExportMapItemEnum.doctor) ??
                            '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.doctor),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.donatingBlood,
                        label: t('label.donatingBlood', 'Donating Blood'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.donatingBlood
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.donatingBlood),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.dayWithTheKids,
                        label: t('label.dayWithTheKids', 'Day With The Kids'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.dayWithTheKids
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.dayWithTheKids),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.maternalLeave,
                        label: t('label.maternalLeave', 'Maternal Leave'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.maternalLeave
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.maternalLeave),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.replacementLeave,
                        label: t('label.replacementLeave', 'Replacement Leave'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.replacementLeave
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.replacementLeave),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.unexcusedAbsence,
                        label: t('label.unexcusedAbsence', 'Unexcused Absence'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.unexcusedAbsence
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.unexcusedAbsence),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.unpaidLeave,
                        label: t('label.unpaidLeave', 'Unpaid Leave'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.unpaidLeave
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.unpaidLeave),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.caringForAFamilyMember,
                        label: t('label.caringForAFamilyMember', 'Caring For A Family Member'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.caringForAFamilyMember
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.caringForAFamilyMember),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.wedding,
                        label: t('label.wedding', 'Wedding'),
                        value: (getSettingsItemValueByKey(data, IntegrationsSettingsMegalaxExportMapItemEnum.wedding) ??
                            '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.wedding),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.funeral,
                        label: t('label.funeral', 'Funeral'),
                        value: (getSettingsItemValueByKey(data, IntegrationsSettingsMegalaxExportMapItemEnum.funeral) ??
                            '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.funeral),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.obstaclesEmployer,
                        label: t('label.obstaclesEmployer', 'Obstacles Employer'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.obstaclesEmployer
                        ) ?? '') as string,
                        options: (values) =>
                            getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.obstaclesEmployer),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: IntegrationsSettingsMegalaxExportMapItemEnum.paidLeave,
                        label: t('label.paidLeave', 'Paid Leave'),
                        value: (getSettingsItemValueByKey(
                            data,
                            IntegrationsSettingsMegalaxExportMapItemEnum.paidLeave
                        ) ?? '') as string,
                        options: (values) => getOptions(values, IntegrationsSettingsMegalaxExportMapItemEnum.paidLeave),
                        width: 6,
                        validation: {
                            deps: keysList
                        }
                    }
                }
            ]}
        />
    );
};

export default MegalaxExportMap;
