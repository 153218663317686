import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFilters } from '@/data/Filters';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { isPlanClosed } from '@/data/SchedulePlans/SchedulePlanSlice';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeUser } from '@/utils/UserHelper';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    create,
    fetchById,
    fetchList,
    fetchSchedulerList,
    fetchSelectList,
    patchState,
    recalculateFund,
    remove,
    update
} from './UserToRequestApi';
import { IUserToRequestCUModel } from './UserToRequestModels';
import { requestPaging } from './UserToRequestSlice';

export const fetchUserToRequests = createAsyncThunk('userToRequests/list', async (args: Partial<IPaging>, thunkAPI) => {
    const currentPaging = requestPaging(thunkAPI.getState() as IRootState);

    return await fetchList({ ...currentPaging, ...args });
});

export const fetchUserToRequestsForSelect = createAsyncThunk(
    'userToRequests/selectList',
    async (args: { search: string; fields?: string[]; filters?: IFilters }) => {
        return await fetchSelectList(args.search, args.fields, args.filters);
    }
);

export const fetchUserToRequestsForScheduler = createAsyncThunk(
    'userToRequests/schedulerList',
    async (args: { filters?: IFilters }) => {
        return await fetchSchedulerList(args.filters);
    }
);

export const patchStateToRequest = createAsyncThunk(
    'userToRequest/setState',
    async (
        args: { id: number; schedulePlanId: number; value: UserToRequestsStateEnum; onLoadingFinished?: () => void },
        { dispatch, getState }
    ) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            if (args.onLoadingFinished) {
                args.onLoadingFinished();
            }

            return Promise.reject();
        }

        return await patchState(args.id, args.value, args.schedulePlanId)
            .then((response) => {
                dispatch(
                    addNotification({
                        variant: 'success',
                        context:
                            response.request?.state === UserToRequestsStateEnum.ACCEPTED
                                ? 'message.info.requestToUserWasApproved'
                                : 'message.info.requestToUserWasRejected',
                        defaultMessage:
                            response.request?.state === UserToRequestsStateEnum.ACCEPTED
                                ? 'Request to {{user}} was approved'
                                : 'Request to {{user}} was rejected',
                        values: { user: serializeUser(response.request?.user) }
                    })
                );

                return response;
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        variant: 'error',
                        message: error.message
                    })
                );

                return Promise.reject(error);
            })
            .finally(() => {
                if (args.onLoadingFinished) {
                    args.onLoadingFinished();
                }
            });
    }
);

export const fetchRecalculateFunds = createAsyncThunk('userToRequests/recalculateRequestFund', async (id: number) =>
    recalculateFund(id)
);

export const fetchUserToRequestById = createAsyncThunk('userToRequests/byId', async (args: number) => {
    return await fetchById(args);
});

export const createUserToRequest = createAsyncThunk('userToRequests/create', async (args: IUserToRequestCUModel) => {
    return await create(args);
});

export const updateUserToRequest = createAsyncThunk(
    'userToRequests/update',
    async (args: { id: number; data: Partial<IUserToRequestCUModel> }) => {
        return await update(args.id, args.data);
    }
);

export const removeUserToRequest = createAsyncThunk(
    'userToRequests/remove',
    async (args: { id: number; onSuccess?: () => void }) => {
        await remove(args.id).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);
