import { useCallback } from 'react';
import { IInnerValuesType } from '@/base/FormGenerator/utils';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createNeed, updateNeed } from '@/data/Needs/NeedActions';
import { INeedItemCUModel, INeedModel } from '@/data/Needs/NeedModels';
import { needById, needCreatingStatus, needPaging, needUpdatingStatus, updatePaging } from '@/data/Needs/NeedReducer';
import { fetchPeriodsForSelect } from '@/data/Periods/PeriodActions';
import { periodsForSelect } from '@/data/Periods/PeriodSlice';
import { fetchSkillsForSelect } from '@/data/Skills/SkillActions';
import { skillsForSelect } from '@/data/Skills/SkillSlice';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import DateHelper from '@/helpers/date/DateHelper';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import NeedTypesOfDaysEnum from '@/utils/enums/NeedTypesOfDaysEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';

type IProps = Omit<ICuProps<INeedModel>, 'resource'>;

type INeedItem = {
    id?: number;
    min: number;
    need_range: ITimeRangeOrTimeAndLengthValueType;
};

const NeedForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const periodsData = useAppSelector(periodsForSelect);
    // const rolesData = useAppSelector(rolesForSelect);
    const skillsData = useAppSelector(skillsForSelect);
    const workplacesData = useAppSelector(workplacesForSelect);
    const loadedData = useAppSelector((state) => needById(state, id));
    const paging = useAppSelector(needPaging);

    const handleData = (values: IOutputValueType) => ({
        role_id: undefined, //values.role_id === '' ? undefined : (values.role_id as number),
        skill_id: values.skill_id === '' ? undefined : (values.skill_id as number),
        workplace_id: values.workplace_id as number,
        period_id: values.period_id as number,
        days_type: values.days_type as NeedTypesOfDaysEnum,
        days: [NeedTypesOfDaysEnum.DaysPicker, NeedTypesOfDaysEnum.ContractFilling].some(
            (item) => item === values.days_type
        )
            ? (values.days as number)
            : null,
        number_of_days:
            values.days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek ? (values.number_of_days as number) : null,
        description: values.description as string | undefined,
        holiday: (values.holiday || false) as boolean,
        weekends: values.days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek && values.weekends === true,
        reservations: (values.reservations || false) as boolean,
        need_items: ((values.requirements || []) as INeedItem[]).map(
            (item) =>
                ({
                    id: item.id,
                    min: item.min,
                    max: item.min,
                    start: DateHelper.formatTime(item.need_range.start),
                    duration: item.need_range.duration ?? 0,
                    is_range: item.need_range.isRange ?? false
                }) as INeedItemCUModel
        )
    });
    const handleCreate = useCallback((values: IOutputValueType) => createNeed(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => updateNeed({ id: id!, data: handleData(values) }),
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);
    const handleOpen = useCallback(() => {
        dispatch(fetchPeriodsForSelect({ search: '' }));
        // dispatch(fetchRolesForSelect({ search: '' }));
        dispatch(fetchSkillsForSelect({ search: '' }));
        dispatch(fetchWorkplacesForSelect({ search: '' }));
    }, []);

    const sortCallback = useCallback((inputs: IInnerValuesType[]) => {
        (inputs as INeedItem[]).sort((prev, next) => {
            const now = DateHelper.now();

            if ((prev.need_range.start ?? now) > (next.need_range.start ?? now)) {
                return 1;
            } else {
                return -1;
            }
        });
    }, []);

    const daysOptions = Object.keys(NeedTypesOfDaysEnum).map((key) => ({
        id: NeedTypesOfDaysEnum[key as keyof typeof NeedTypesOfDaysEnum],
        label: t(`enums.needTypesOfDays.${formatPascalToCamel(key)}`, key)
    }));

    return (
        <CuForm
            {...props}
            id={id}
            name="need"
            resource={PermissionsEnum.Needs}
            maxWidth="lg"
            creatingStatus={useAppSelector(needCreatingStatus)}
            updatingStatus={useAppSelector(needUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'holiday',
                        label: t('label.holiday', 'Holiday'),
                        value: loadedData?.holiday,
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    display: ({ days_type }) => days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek,
                    props: {
                        name: 'weekends',
                        label: t('label.weekends', 'Weekends'),
                        value: loadedData?.weekends,
                        width: 6,
                        validation: {
                            deps: 'days_type'
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: 'days_type',
                        required: true,
                        label: t('label.typeOfDay', 'Type Of Day'),
                        value: loadedData?.days_type,
                        options: daysOptions,
                        //TODO: proč to tady musí být aby začal fungovat display na days, ...
                        validation: {
                            deps: 'days_type'
                        }
                    }
                },
                {
                    type: 'weekdays',
                    display: ({ days_type }) =>
                        [NeedTypesOfDaysEnum.DaysPicker, NeedTypesOfDaysEnum.ContractFilling].some(
                            (item) => item === days_type
                        ),
                    props: {
                        name: 'days',
                        value: loadedData?.days ?? undefined,
                        multiple: true,
                        width: 12,
                        validation: {
                            deps: 'days_type'
                        },
                        sx: { alignItems: 'center' }
                    }
                },
                {
                    type: 'textField',
                    display: ({ days_type }) => days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek,
                    props: {
                        required: ({ days_type }) => days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek,
                        name: 'number_of_days',
                        label: t('label.numberOfDays', 'Number Of Days'),
                        type: 'number',
                        value: loadedData?.number_of_days ?? undefined,
                        validation: {
                            deps: 'days_type'
                        },
                        InputProps: {
                            inputProps: {
                                step: 1,
                                min: 1,
                                max: 7
                            }
                        }
                    }
                },
                // {
                //     type: 'select',
                //     props: {
                //         required: ({ skill_id }) => !skill_id,
                //         name: 'role_id',
                //         label: t('label.role', 'Role'),
                //         value: loadedData?.role_id?.toString(),
                //         options: rolesData.map((item) => ({
                //             id: item.id.toString(),
                //             label: t(`roles.${item.name.toLowerCase()}`, item.name)
                //         })),
                //         width: 6,
                //         validation: {
                //             deps: 'skill_id'
                //         }
                //     }
                // },
                {
                    type: 'select',
                    props: {
                        required: true, //({ role_id }) => !role_id,
                        name: 'skill_id',
                        label: t('label.skill', 'Skill'),
                        value: loadedData?.skill_id?.toString(),
                        options: skillsData.map((item) => ({
                            id: item.id.toString(),
                            label: item.name
                        })),
                        // width: 6,
                        width: 12
                        // validation: {
                        //     deps: 'role_id'
                        // }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'workplace_id',
                        label: t('label.workplace', 'Workplace'),
                        value: loadedData?.workplace_id.toString(),
                        options: workplacesData.map((item) => ({
                            id: item.id.toString(),
                            label: item.name
                        })),
                        width: 6
                    }
                },
                {
                    type: 'select',
                    props: {
                        name: 'period_id',
                        placeholder: t('label.all', 'All'),
                        label: t('label.period', 'Period'),
                        value: loadedData?.period_id?.toString(),
                        options: periodsData.map((period) => ({
                            id: period.id.toString(),
                            label: period.name
                        })),
                        width: 6
                    }
                },
                {
                    type: 'multiRowInputs',
                    sort: sortCallback,
                    props: {
                        name: 'requirements',
                        value: loadedData?.need_items.map((item) => ({
                            ...item,
                            need_range: {
                                start: DateHelper.fromTimeString(item.start),
                                duration: item.duration ?? 0,
                                isRange: false
                            } as ITimeRangeOrTimeAndLengthValueType
                        })),
                        inputs: [
                            {
                                type: 'textField',
                                display: (values, parentValues) =>
                                    parentValues?.days_type !== NeedTypesOfDaysEnum.ContractFilling,
                                props: {
                                    required: ({ days_type }) => days_type !== NeedTypesOfDaysEnum.ContractFilling,
                                    name: 'min',
                                    label: t('label.min', 'Min'),
                                    type: 'number',
                                    width: 2,
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    validation: {
                                        deps: ['days_type'],
                                        min: {
                                            value: 1,
                                            message: t('message.info.minimalValueIs1', 'Minimal value is {{value}}.', {
                                                value: 1
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                type: 'timeRangeOrTimeAndLength',
                                props: {
                                    required: true,
                                    name: 'need_range',
                                    label: {
                                        start: t('label.needStart', 'Need start'),
                                        end: t('label.needEnd', 'Need end'),
                                        length: t('label.length', 'Length'),
                                        range: t('label.range', 'Range')
                                    },
                                    minutesStep: 15,
                                    width: 8
                                }
                            }
                        ],
                        validation: {
                            deps: ['days_type']
                        }
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'description',
                        label: t('label.description', 'Description'),
                        value: loadedData?.description,
                        width: 12,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                }
            ]}
            onOpen={handleOpen}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default NeedForm;
