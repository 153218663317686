import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { getDelaysCsvReportFile } from '@/data/SchedulePlanDayShiftDelays/schedulePlanDayShiftDelayActions';
import { ISchedulePlanDayShiftDelayModel } from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelayModels';
import { detachShiftFromDay, updateAssignedShift } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftActions';
import { selectSchedulePlanDayShiftEntities } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { IRootState } from '@/data/store';
import { getErrorMessageForSchedule } from '@/helpers/schedule';

export type ISchedulePlanDayShiftDelaySlice = {
    downloadCsvFileOfDelayStatus: IRequestState;
};

const initialState: ISchedulePlanDayShiftDelaySlice = {
    downloadCsvFileOfDelayStatus: 'idle'
};

const adapter = createEntityAdapter<ISchedulePlanDayShiftDelayModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => (a.id < b.id ? 1 : -1)
});

const schedulePlanDayShiftDelaySlice = createSlice({
    name: 'schedulePlanDayShiftDelays',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        patchSchedulePlanDayShiftDelays(state, action: PayloadAction<ISchedulePlanDayShiftDelayModel[]>) {
            adapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDelaysCsvReportFile.pending, (state) => {
                state.downloadCsvFileOfDelayStatus = 'loading';
            })
            .addCase(getDelaysCsvReportFile.fulfilled, (state) => {
                state.downloadCsvFileOfDelayStatus = 'idle';
            })
            .addCase(getDelaysCsvReportFile.rejected, (state) => {
                state.downloadCsvFileOfDelayStatus = 'failed';
            })
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.upsertMany(state, [
                    ...action.payload.schedule_plan_day_shifts
                        .map(({ schedule_plan_day_shift_delays = [] }) => schedule_plan_day_shift_delays)
                        .flat(),
                    ...action.payload.schedule_plan_day_shifts_without_definition
                        .map(({ schedule_plan_day_shift_delays = [] }) => schedule_plan_day_shift_delays)
                        .flat()
                ]);
            })
            .addCase(detachShiftFromDay.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_day_shift_id === action.meta.arg.id
                        ) as ISchedulePlanDayShiftDelayModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(updateAssignedShift.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (delayItem) =>
                                action.payload.old?.schedule_plan_day_shift_skills.some(
                                    (shiftSkillItem) =>
                                        shiftSkillItem.schedule_plan_day_shift_id ===
                                            delayItem?.schedule_plan_day_shift_id &&
                                        shiftSkillItem.id === delayItem?.schedule_plan_day_shift_skill_id
                                )
                        ) as ISchedulePlanDayShiftDelayModel[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[schedulePlanDayShiftDelaySlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default schedulePlanDayShiftDelaySlice;

export const selectSchedulePlanDayShiftDelays = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => selectSchedulePlanDayShiftEntities(state),
    (delays, schedulePlanDayShifts) => {
        return delays
            .map((item) => ({
                ...item,
                assigned_shift: schedulePlanDayShifts[item.schedule_plan_day_shift_id] ?? null
            }))
            .filter(
                ({ assigned_shift, delay_in_minutes }) =>
                    assigned_shift !== null && typeof assigned_shift.user_id === 'number' && delay_in_minutes > 0
            )
            .map((item) => ({
                ...item,
                message: getErrorMessageForSchedule(item)
            }));
    }
);
export const selectSchedulePlanDayShiftDelaysBySchedulePlanId = createSelector(
    (_state: IRootState, schedulePlanId: number) => schedulePlanId as number,
    (state: IRootState) => selectSchedulePlanDayShiftDelays(state),
    (schedulePlanId, delays) => {
        const result = delays.filter((item) => item.assigned_shift.schedule_plan_id === schedulePlanId);

        return result.length === 0 ? null : result;
    }
);

export const { patchSchedulePlanDayShiftDelays } = schedulePlanDayShiftDelaySlice.actions;
export const isDelayCsvFileLoading = (state: IRootState) => getState(state).downloadCsvFileOfDelayStatus === 'loading';
