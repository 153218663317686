import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { removeShift } from '@/data/Shifts/ShiftActions';
import { IShiftToWorkplaceModel } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceModels';
import { IRootState } from '@/data/store';
import {
    createWorkplace,
    fetchWorkplaceById,
    fetchWorkplaces,
    removeWorkplace,
    updateWorkplace
} from '@/data/Workplaces/WorkplaceActions';

type IState = {};

const initialState: IState = {};

const getKey = (entity: IShiftToWorkplaceModel) => `${entity.shift_id}_${entity.workplace_id}_${entity.type}`;

const adapter = createEntityAdapter<IShiftToWorkplaceModel>({
    selectId: getKey,
    sortComparer: (a, b) => getKey(a).localeCompare(getKey(b))
});

const shiftToWorkplaceSlice = createSlice({
    name: 'shiftToWorkplaces',
    initialState: adapter.getInitialState<IState>(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.payload.workplace_id
                        ) as IShiftToWorkplaceModel[]
                    ).map(getKey)
                );
                adapter.upsertMany(state, action.payload.workplace.shift_to_workplaces);
            })
            .addCase(fetchWorkplaces.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.data.flatMap(({ shift_to_workplaces }) => shift_to_workplaces)
                );
            })
            .addCase(fetchWorkplaceById.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift, ...entity }) => entity)
                );
            })
            .addCase(updateWorkplace.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.meta.arg.id
                        ) as IShiftToWorkplaceModel[]
                    ).map(getKey)
                );
                adapter.addMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift, ...entity }) => entity)
                );
            })
            .addCase(createWorkplace.fulfilled, (state, action) => {
                adapter.addMany(
                    state,
                    action.payload.shift_to_workplaces.map(({ shift, ...entity }) => entity)
                );
            })
            .addCase(removeWorkplace.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.workplace_id === action.meta.arg.id
                        ) as IShiftToWorkplaceModel[]
                    ).map(getKey)
                );
            })
            .addCase(removeShift.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.shift_id === action.meta.arg.id
                        ) as IShiftToWorkplaceModel[]
                    ).map(getKey)
                );
            });
    }
});

const getState = (state: IRootState) => state[shiftToWorkplaceSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default shiftToWorkplaceSlice;
export const shiftToWorkplaceAll = adapterSelectors.selectAll;
