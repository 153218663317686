import { fetchDownloadFile, fetchGet, urlBuilderWithParams } from '@/data/request';
import {
    IFetchUserHoursList,
    IFetchUserShiftsSummaries,
    IFetchUserSkillsList,
    IUserHoursResponse,
    IUserShiftsSummariesResponse,
    IUserSkillsResponse
} from '@/data/Summaries/SummariesModels';

export function fetchUserShiftsList(args: IFetchUserShiftsSummaries) {
    const url = urlBuilderWithParams('/api/summaries/users-shifts', args);

    return fetchGet<IUserShiftsSummariesResponse[]>(url);
}
export function fetchUserShiftsFile(args: IFetchUserShiftsSummaries) {
    const url = urlBuilderWithParams('/api/summaries/users-shifts-file', args);

    return fetchDownloadFile(url);
}
export function fetchUserHoursList(args: IFetchUserHoursList) {
    const url = urlBuilderWithParams('/api/summaries/users-hours', args);

    return fetchGet<IUserHoursResponse[]>(url);
}
export function fetchUserHoursFile(args: IFetchUserHoursList) {
    const url = urlBuilderWithParams('/api/summaries/users-hours-file', args);

    return fetchDownloadFile(url);
}

export function fetchUserSkillsList(args: IFetchUserSkillsList) {
    const url = urlBuilderWithParams('/api/summaries/users-skills', args);

    return fetchGet<IUserSkillsResponse[]>(url);
}
export function fetchUserSkillsFile(args: IFetchUserSkillsList) {
    const url = urlBuilderWithParams('/api/summaries/users-skills-file', args);

    return fetchDownloadFile(url);
}
