import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { styled, SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactNode, useState } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export type IItem = {
    label: string;
    value: string | ReactNode;
    valueSx?: SxProps;
    labelSx?: SxProps;
    allowCopy?: boolean;
};

type IProps = {
    items: IItem[];
};

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    }
}

const StackInformation = ({ items }: IProps) => {
    const { t } = useAppTranslation();
    const [open, setOpen] = useState(false);
    const [vertical, setVertical] = useState<SnackbarOrigin['vertical']>('top');
    const [horizontal, setHorizontal] = useState<SnackbarOrigin['horizontal']>('center');

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                message={t('label.copied', 'Copied')}
                key={vertical + horizontal}
            />
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
                data-testid="stack-content"
            >
                {items.map((item, index) => (
                    <Item key={index} data-testid={`stack-${index}`}>
                        <Typography variant="body1" component="div" sx={item.labelSx}>
                            {item.label}:
                        </Typography>
                        <Typography variant="body1" component="div" sx={item.valueSx}>
                            {item.value}
                            {item.allowCopy && typeof item.value === 'string' && (
                                <IconButton
                                    title={t('label.copy', 'Copy')}
                                    onClick={() => {
                                        copyTextToClipboard(typeof item.value === 'string' ? item.value : '').then(
                                            () => {
                                                setOpen(true);
                                                setVertical('top');
                                                setHorizontal('center');
                                                setTimeout(() => {
                                                    handleClose();
                                                }, 1000);
                                            }
                                        );
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            )}
                        </Typography>
                    </Item>
                ))}
            </Stack>
        </>
    );
};

export default StackInformation;
