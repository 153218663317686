import { useMemo } from 'react';
import useAppTranslation from '@/hooks/useAppTranslation';
import { defaultLng } from '@/utils/i18next';

type Props = {
    timeZone?: string | null;
    timeStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
    dateStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
};

const useLocalizeDateTimeFormatter = ({ timeZone, timeStyle, dateStyle }: Props = {}) => {
    const { i18n } = useAppTranslation();

    return useMemo(() => {
        const lng = i18n.language ?? defaultLng;

        return new Intl.DateTimeFormat(lng, {
            timeZone: timeZone ?? 'UTC',
            dateStyle: dateStyle ?? 'short',
            timeStyle: timeStyle ?? 'short'
        });
    }, [timeZone, i18n.resolvedLanguage, i18n.language]);
};

export default useLocalizeDateTimeFormatter;
