import { z } from 'zod';
import { ISettingsItemValue, ISettingsValueFromBeAbstractModel } from '@/data/SettingsItems/SettingsItemModels';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';

export type IApplicationSettingItem<
    ApplicationKey extends ApplicationSettingsItemTypesEnum,
    KeyOptions = string
> = ISettingsItemValue & {
    application_key: ApplicationKey;
    key: KeyOptions;
};

type IApplicationSettingsFromBeAbstractModel<TypeModel extends IApplicationSettingItems> = {
    application_key: IApplicationSettingsKey;
    value: (Omit<TypeModel, 'value'> & ISettingsValueFromBeAbstractModel)[];
};

export type IApplicationSettingsDataModel<TypeModel> = {
    application_key: string;
    value: TypeModel[];
};

/********************************* Keys *********************************/
type IApplicationSettingsKey = ApplicationSettingsItemTypesEnum;

/********************************* Settings configs *********************************/
export type ISchedulePlanConfig = {
    can_take_trade_without_counteroffer?: boolean;
    on_holiday_insert_set_shift_as_empty?: boolean;
    dnd_shift_between_days?: boolean;
    limitation_of_agents_and_empty_shifts?: string;
};
export type IRequestConfig = {
    allow_create_new_request_after_exceed_users_request_fund?: boolean;
};

/********************************* Models *********************************/
export type ISchedulePlanApplicationSettingModel =
    IApplicationSettingsDataModel<ISchedulePlanApplicationSettingItem> & {
        application_key: ApplicationSettingsItemTypesEnum.schedulePlan;
    };

export type IRequestApplicationSettingModel = IApplicationSettingsDataModel<IRequestApplicationSettingItem> & {
    application_key: ApplicationSettingsItemTypesEnum.request;
};

export type IApplicationsSettingsModel = ISchedulePlanApplicationSettingModel | IRequestApplicationSettingModel;

/********************************* Items *********************************/
export type ISchedulePlanApplicationSettingItem = IApplicationSettingItem<
    ApplicationSettingsItemTypesEnum.schedulePlan,
    keyof ISchedulePlanConfig
>;

export type IRequestApplicationSettingItem = IApplicationSettingItem<
    ApplicationSettingsItemTypesEnum.request,
    keyof IRequestConfig
>;

export type IApplicationSettingItems = ISchedulePlanApplicationSettingItem | IRequestApplicationSettingItem;

/********************************* BE Models *********************************/
export type ISchedulePlanSettingsFromBeModel =
    IApplicationSettingsFromBeAbstractModel<ISchedulePlanApplicationSettingItem> & {
        application_key: ApplicationSettingsItemTypesEnum.schedulePlan;
    };

export type IRequestSettingsFromBeModel = IApplicationSettingsFromBeAbstractModel<IRequestApplicationSettingItem> & {
    application_key: ApplicationSettingsItemTypesEnum.request;
};

export type IApplicationsSettingsFromBeModel = ISchedulePlanSettingsFromBeModel | IRequestSettingsFromBeModel;
export const zodLimitationOfAgentsAndEmptyShifts = z.enum(['default', 'before_date', 'from_date']).default('default');
