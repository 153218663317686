import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import HideSourceOutlinedIcon from '@mui/icons-material/HideSourceOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import theme from '@/assets/theme';
import { getRequestApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { isPlanClosed } from '@/data/SchedulePlans/SchedulePlanSlice';
import { isSignedUserAdmin, signedUser } from '@/data/System/SystemReducer';
import { fetchUserById } from '@/data/Users/UserActions';
import { userById } from '@/data/Users/UserSlice';
import { fetchRecalculateFunds, patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import { ISchedulerUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import { getUsersFundForRequest } from '@/data/UserToVacationFunds/UserToVacationFundActions';
import {
    exceededFundByRequestTypeIdAndDateTimeStartAndEnd,
    isSomeVacationFundLoading
} from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import { getSettingsItemBooleanValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import SchedulerRequestForm from '@/modules/Scheduler/SchedulerRequestForm';
import ApplicationSettingsRequestItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsRequestItemEnum';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeUser } from '@/utils/UserHelper';
import Box from '@/wrappers/Box';
import ConfirmDialog from '@/wrappers/ConfirmDialog';
import LinearProgress from '@/wrappers/LinearProgress';
import Tooltip from '@/wrappers/Tooltip';

type IProps = {
    request: ISchedulerUserToRequestModel;
    onOpen: () => void;
    onClose: () => void;
    onLoadingStart?: () => void;
    onLoadingFinished?: () => void;
    schedulePlanId: number;
    timeZone: string;
};

const StyledMenu = styled(Menu)(() => ({
    [`.${menuItemClasses.root}`]: {
        [`.${listItemIconClasses.root}`]: {
            paddingRight: 15,
            height: '40px',
            width: '40px',
            svg: {
                height: '100%',
                width: '100%'
            }
        },
        [`.${listItemTextClasses.root}`]: {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const StyledGreenMenuItem = styled(MenuItem)(() => ({
    color: theme.palette.success.main,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.success.main
    }
}));

const StyledRedMenuItem = styled(MenuItem)(() => ({
    color: theme.palette.error.dark,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.error.dark
    }
}));

const StyledMenuItem = styled(MenuItem)(() => ({
    color: theme.palette.text.primary,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.text.secondary
    }
}));

const StyledRequestInnerBox = styled(Box)(() => ({
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'stretch'
}));

const StyledRequestBox = styled(Box, {
    shouldForwardProp: (propName) => !['request'].includes(propName as string)
})<{ request: ISchedulerUserToRequestModel }>(({ request }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: request.request_type.color,
    border: '1px solid #000000',
    ...(request.state === UserToRequestsStateEnum.CALCULATING_FUNDS && {
        cursor: 'default'
    }),
    ...(request.request_type.type === RequestTypeEnum.available || request.state === UserToRequestsStateEnum.CREATED
        ? { background: request.request_type.background }
        : request.state === UserToRequestsStateEnum.ERROR ||
          request.state === UserToRequestsStateEnum.CALCULATING_FUNDS_ERROR
        ? {
              background: theme.palette.error.dark,
              color: theme.palette.error.contrastText
          }
        : {
              background: theme.palette.grey['400'],
              color: theme.palette.text.primary
          }),
    ...(request.request_type.type !== RequestTypeEnum.available &&
        request.state === UserToRequestsStateEnum.ACCEPTED && {
            boxShadow: `inset 0 0 0 2px ${theme.palette.success.main}`
        }),
    ...(request.request_type.type !== RequestTypeEnum.available &&
        request.state === UserToRequestsStateEnum.REJECTED && {
            boxShadow: `inset 0 0 0 2px ${theme.palette.error.dark}`
        }),
    ...((request.request_type.type === RequestTypeEnum.available ||
        request.state === UserToRequestsStateEnum.CREATED) && {
        '&:hover': {
            boxShadow: `inset 0 0 5px 4px ${theme.boxShadow.hover.createdRequest}`
        }
    }),
    ...(request.request_type.type !== RequestTypeEnum.available &&
        request.state !== UserToRequestsStateEnum.CREATED &&
        request.state !== UserToRequestsStateEnum.CALCULATING_FUNDS && {
            '&:hover': {
                boxShadow: `inset 0 0 5px 4px ${theme.boxShadow.hover.notCreatedRequest}`
            }
        })
}));

const SchedulerRequestMenu = ({
    request,
    timeZone,
    schedulePlanId,
    onClose,
    onOpen,
    onLoadingStart,
    onLoadingFinished
}: IProps) => {
    const { t } = useAppTranslation();
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone: timeZone });
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => userById(state, request.user_id));
    const isClosed = useAppSelector((state) => isPlanClosed(state, schedulePlanId));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openRecalculateFundDialog, setOpenRecalculateFundDialog] = useState<boolean>(false);
    const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false);
    const requestSettingsData = useAppSelector(getRequestApplicationsSettings);
    const signedUserId = useAppSelector(signedUser)?.id;
    const subordinateUserIds = useAppSelector((state) => userById(state, signedUserId))?.subordinate;
    const isAbleToExceedFund = getSettingsItemBooleanValueByKey(
        requestSettingsData,
        ApplicationSettingsRequestItemEnum.allowCreateNewVacationAfterExceedUsersRequestFund
    );
    const signedUserIsAdmin = useAppSelector(isSignedUserAdmin);

    const usersExceededFundList = useAppSelector((state) =>
        exceededFundByRequestTypeIdAndDateTimeStartAndEnd(
            state,
            request.user_id ?? null,
            request?.request_type_id ?? null,
            request?.datetime_from ?? null,
            request?.datetime_to ?? null
        )
    );
    const isFundsLoading = useAppSelector((state) =>
        isSomeVacationFundLoading(state, request?.datetime_from ?? undefined, request?.datetime_to ?? undefined)
    );

    const isValid = useMemo(
        () =>
            !isFundsLoading &&
            !((usersExceededFundList?.length ?? 0) > 0 && !isAbleToExceedFund) &&
            (signedUserIsAdmin || subordinateUserIds?.includes(request.user_id)),
        [usersExceededFundList, isAbleToExceedFund, isFundsLoading]
    );

    const handleOpenMenu = useCallback(
        (anchor: HTMLElement) => {
            if (signedUserId) {
                dispatch(fetchUserById(signedUserId));
            }

            setAnchorEl(anchor);

            if (request.state === UserToRequestsStateEnum.CREATED) {
                dispatch(
                    getUsersFundForRequest({
                        request: request
                    })
                );
            }

            onOpen();
        },
        [request, onOpen, signedUserId]
    );

    const handleClick = useCallback(
        (anchor: MouseEvent<HTMLElement>) => {
            handleOpenMenu(anchor.currentTarget);
        },
        [onOpen, signedUserId, request]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setOpenConfirmDialog(false);
        setOpenRecalculateFundDialog(false);
        setOpenRejectDialog(false);
    }, []);

    const handleFormClose = useCallback(() => {
        setFormOpen(false);
        handleClose();
    }, []);

    const handleOpenForm = useCallback(() => {
        setFormOpen(true);
        setAnchorEl(null);

        return () => {
            setFormOpen(false);
            setAnchorEl(null);
        };
    }, []);

    const handleApprove = useCallback(
        (doneHandle: () => void) => {
            if (isValid) {
                if (onLoadingStart) {
                    onLoadingStart();
                }

                dispatch(
                    patchStateToRequest({
                        id: request.id,
                        schedulePlanId: schedulePlanId,
                        value: UserToRequestsStateEnum.ACCEPTED,
                        onLoadingFinished: onLoadingFinished
                    })
                );
            }

            handleClose();
            doneHandle();
        },
        [request, isValid]
    );

    const handleRecalculate = useCallback(
        (doneHandle: () => void) => {
            dispatch(fetchRecalculateFunds(request.id));

            handleClose();
            doneHandle();
        },
        [request]
    );

    const handleReject = useCallback(
        (doneHandle: () => void) => {
            if (onLoadingStart) {
                onLoadingStart();
            }

            dispatch(
                patchStateToRequest({
                    id: request.id,
                    schedulePlanId: schedulePlanId,
                    value: UserToRequestsStateEnum.REJECTED,
                    onLoadingFinished: onLoadingFinished
                })
            );

            handleClose();
            doneHandle();
        },
        [request]
    );

    const getApproveMenuItem = useCallback(
        () => (
            <Box>
                <StyledGreenMenuItem
                    disabled={!isValid}
                    onClick={() => {
                        handleClose();
                        setOpenConfirmDialog(true);
                    }}
                >
                    <ListItemIcon>
                        <TaskAltOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('label.approve', 'Approve')} />
                </StyledGreenMenuItem>
                <LinearProgress hidden={!isFundsLoading} sx={{ width: '100%' }} color="success" />
            </Box>
        ),
        [isValid, handleClose, isFundsLoading]
    );

    useEffect(() => {
        if (!open && !formOpen && !openConfirmDialog && !openRecalculateFundDialog && !openRejectDialog) {
            onClose();
        }
    }, [onClose, open, formOpen, openConfirmDialog, openRecalculateFundDialog, openRejectDialog]);

    const isFundCalculating = useMemo(
        () => request.state === UserToRequestsStateEnum.CALCULATING_FUNDS,
        [request.state]
    );

    return (
        <>
            <StyledRequestBox
                request={request}
                key="openRequestMenu"
                onClick={(anchor: MouseEvent<HTMLElement>) => {
                    if (!isFundCalculating) {
                        handleClick(anchor);
                    }
                }}
            >
                <StyledRequestInnerBox>
                    <Box>
                        {request.request_type.name}
                        <LinearProgress
                            hidden={!isFundCalculating}
                            sx={{
                                width: '100%'
                            }}
                            color="primary"
                        />
                    </Box>
                </StyledRequestInnerBox>
            </StyledRequestBox>

            <StyledMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                data-testid={'schedulerRequestMenu'}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        'overflow': 'visible',
                        'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        'mt': 1.5,
                        '& div': {
                            minWidth: 200,
                            maxWidth: 300
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {!isClosed &&
                    request.request_type.type !== RequestTypeEnum.available &&
                    request.state === UserToRequestsStateEnum.CREATED &&
                    (!isValid ? (
                        <Tooltip
                            color="primary"
                            title={
                                usersExceededFundList
                                    ?.map((fund) =>
                                        t(
                                            'message.error.vacationFundForYearWillBeExceeded',
                                            'For {{year}} vacation fund will be exhausted by: {{exceeded}}',
                                            {
                                                year: fund.year,
                                                exceeded: DateHelper.getMinutesInHumanFormat(
                                                    ((fund.exhausted ?? 0) - (fund.fund ?? 0)) * 60
                                                )
                                            }
                                        )
                                    )
                                    .toString() ?? ''
                            }
                        >
                            {getApproveMenuItem()}
                        </Tooltip>
                    ) : (
                        getApproveMenuItem()
                    ))}
                {!isClosed &&
                    (signedUserIsAdmin || subordinateUserIds?.includes(request.user_id)) &&
                    (request.state === UserToRequestsStateEnum.CREATED ||
                        (request.state === UserToRequestsStateEnum.ACCEPTED &&
                            [RequestTypeEnum.times_off, RequestTypeEnum.available].some(
                                (item) => item == request.request_type.type
                            ))) && (
                        <StyledRedMenuItem
                            onClick={() => {
                                handleClose();
                                setOpenRejectDialog(true);
                            }}
                        >
                            <ListItemIcon>
                                <HideSourceOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('label.reject', 'Reject')} />
                        </StyledRedMenuItem>
                    )}
                <StyledMenuItem onClick={handleOpenForm}>
                    <ListItemIcon>
                        <InfoOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('label.detail', 'Detail')} />
                </StyledMenuItem>
                {request.state === UserToRequestsStateEnum.CALCULATING_FUNDS_ERROR && (
                    <StyledRedMenuItem
                        onClick={() => {
                            handleClose();
                            setOpenRecalculateFundDialog(true);
                        }}
                    >
                        <ListItemIcon>
                            <CalculateOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('label.recalculate', 'Recalculate')} />
                    </StyledRedMenuItem>
                )}
            </StyledMenu>
            <ConfirmDialog
                title={t(
                    'message.question.doYouReallyWantToApproveRequestFromDateToDateToUser',
                    'Do you want to approve {{request}} {{from}} - {{to}} to {{user}}',
                    {
                        request: request.request_type.name,
                        user: serializeUser(user),
                        from: dateTimeFormatter.format(request.datetime_from.toDate()),
                        to: dateTimeFormatter.format(request.datetime_to.toDate())
                    }
                )}
                onAgree={handleApprove}
                onClose={() => setOpenConfirmDialog(false)}
                onReject={() => setOpenConfirmDialog(false)}
                open={openConfirmDialog}
            />
            <ConfirmDialog
                title={t(
                    'message.question.doYouReallyWantToRecalculateFundExhaustionRequest',
                    'Do you want to recalculate fund exhaustion for {{request}} to {{user}}',
                    {
                        request: request.request_type.name,
                        user: serializeUser(user)
                    }
                )}
                onAgree={handleRecalculate}
                onClose={() => setOpenRecalculateFundDialog(false)}
                onReject={() => setOpenRecalculateFundDialog(false)}
                open={openRecalculateFundDialog}
            />
            <ConfirmDialog
                title={t(
                    'message.question.doYouReallyWantToRejectRequestFromDateToDateToUser',
                    'Do you want to reject {{request}} {{from}} - {{to}} to {{user}}',
                    {
                        request: request.request_type.name,
                        user: serializeUser(user),
                        from: dateTimeFormatter.format(request.datetime_from.toDate()),
                        to: dateTimeFormatter.format(request.datetime_to.toDate())
                    }
                )}
                onAgree={handleReject}
                onClose={() => setOpenRejectDialog(false)}
                onReject={() => setOpenRejectDialog(false)}
                open={openRejectDialog}
            />
            <SchedulerRequestForm
                timeZone={timeZone}
                schedulePlanId={schedulePlanId}
                request={request}
                formOpen={formOpen ?? false}
                onFormClose={handleFormClose}
            />
        </>
    );
};

export default SchedulerRequestMenu;
