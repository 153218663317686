import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { defaultPaging, IPaging } from '@/data/Paging';
import {
    createSetting,
    fetchAzureAuthenticateUrl,
    fetchAzureSettings,
    fetchGooglesSettings,
    fetchSettingsItems,
    removeSetting,
    updateSetting
} from '@/data/SettingsItems/SettingsItemActions';
import {
    IAzureSettingItem,
    IDaktelaToolsSettingItem,
    IEvolutionAlgorithmBlocksParametersSettingItem,
    IGoogleSettingItem,
    IMegalaxExportMapSettingItem,
    IMicrosoftSettingItem,
    INeznamSettingItem,
    IPinyaSettingItem,
    ISendGridSettingItem,
    ISettingItems
} from '@/data/SettingsItems/SettingsItemModels';
import { IRootState } from '@/data/store';
import unique from '@/helpers/array/unique';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';

type IState = {
    paging: IPaging;
    loadingListStatus: IRequestState;
    loadingGoogleStatus: IRequestState;
    loadingAzureStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
    loadingAzureUrlStatus: IRequestState;
    azureAuthenticateUrl?: string;
};

const initialState: IState = {
    paging: defaultPaging('application_key'),
    loadingListStatus: 'idle',
    loadingGoogleStatus: 'idle',
    loadingAzureStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle',
    loadingAzureUrlStatus: 'idle',
    azureAuthenticateUrl: undefined
};

const getKey = <T extends Pick<ISettingItems, 'application_key' | 'key'>>(entity: T) =>
    `${entity.application_key}_${entity.key}`;

const adapter = createEntityAdapter<ISettingItems>({
    selectId: getKey,
    sortComparer: (a, b) =>
        a.application_key === b.application_key
            ? a.key.localeCompare(b.key)
            : a.application_key.localeCompare(b.application_key)
});

const settingsItemSlice = createSlice({
    name: 'settingItems',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSettingsItems.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchSettingsItems.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                // state.paging = action.payload.collection;
                adapter.addMany(state, action.payload.data.map(({ value }) => value).flat());
            })
            .addCase(fetchSettingsItems.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(createSetting.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createSetting.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addMany(state, action.payload.value);
            })
            .addCase(createSetting.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(fetchGooglesSettings.pending, (state) => {
                state.loadingGoogleStatus = 'loading';
            })
            .addCase(fetchGooglesSettings.fulfilled, (state, action) => {
                state.loadingGoogleStatus = 'idle';
                if (action.payload.value !== null) {
                    adapter.addMany(state, action.payload.value);
                }
            })
            .addCase(fetchGooglesSettings.rejected, (state) => {
                state.loadingGoogleStatus = 'failed';
            })
            .addCase(fetchAzureSettings.pending, (state) => {
                state.loadingAzureStatus = 'loading';
            })
            .addCase(fetchAzureSettings.fulfilled, (state, action) => {
                state.loadingAzureStatus = 'idle';
                if (action.payload.value !== null) {
                    adapter.addMany(state, action.payload.value);
                }
            })
            .addCase(fetchAzureSettings.rejected, (state) => {
                state.loadingAzureStatus = 'failed';
            })
            .addCase(fetchAzureAuthenticateUrl.pending, (state) => {
                state.loadingAzureUrlStatus = 'loading';
            })
            .addCase(fetchAzureAuthenticateUrl.fulfilled, (state, action) => {
                state.loadingAzureUrlStatus = 'idle';
                state.azureAuthenticateUrl = action.payload.url;
            })
            .addCase(fetchAzureAuthenticateUrl.rejected, (state) => {
                state.loadingAzureUrlStatus = 'failed';
            })
            .addCase(removeSetting.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeSetting.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.application_key === action.meta.arg.id
                        ) as ISettingItems[]
                    ).map((item) => getKey(item))
                );
            })
            .addCase(removeSetting.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateSetting.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateMany(
                    state,
                    action.payload.value.map((item) => ({
                        id: getKey(item),
                        changes: item
                    }))
                );
            })
            .addCase(updateSetting.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[settingsItemSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const settingsItemPaging = (state: IRootState) => getState(state).paging;
export const getSettingsItemsByApplicationKey = (state: IRootState, applicationKey: SettingsTypesEnum) => {
    return adapterSelectors.selectAll(state).filter((item) => item.application_key === applicationKey);
};
export const settingsItemList = adapterSelectors.selectAll;
export const getApplicationKes = (state: IRootState) =>
    unique(adapterSelectors.selectAll(state), (item) => item.application_key).map((item) => ({
        id: item.application_key,
        key: item.application_key
    }));

export const getCreatingOfSettingsItemStatus = (state: IRootState) => getState(state).creatingStatus;
export const getUpdatingOfSettingsItemStatus = (state: IRootState) => getState(state).updatingStatus;
export const getRemovingOfSettingsItemStatus = (state: IRootState) => getState(state).removingStatus;
export const isSettingsItemListLoaded = (state: IRootState) => getState(state).loadingListStatus === 'idle';
export const getGoogleSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.Google) as IGoogleSettingItem[];
export const getGoogleClientId = (state: IRootState) =>
    adapterSelectors.selectById(
        state,
        getKey({
            application_key: SettingsTypesEnum.Google,
            key: 'client_id'
        })
    )?.value;
export const getAzureClientId = (state: IRootState) =>
    adapterSelectors.selectById(
        state,
        getKey({
            application_key: SettingsTypesEnum.AzureActiveDirectoryLogin,
            key: 'client_id'
        })
    )?.value;
export const getDaktelaToolsSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.DaktelaTools) as IDaktelaToolsSettingItem[];
export const getEvolutionAlgorithmBlocksParametersSettingItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(
        state,
        SettingsTypesEnum.EvolutionAlgorithmBlocksParameters
    ) as IEvolutionAlgorithmBlocksParametersSettingItem[];
export const getPinyaSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.Pinya) as IPinyaSettingItem[];
export const getSendGridSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.SendGrid) as ISendGridSettingItem[];
export const getMicrosoftSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.MicrosoftActiveDirectoryLogin) as IMicrosoftSettingItem[];
export const getNeznamSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.Neznam) as INeznamSettingItem[];
export const getMegalaxExportMapSettingsItems = (state: IRootState) =>
    getSettingsItemsByApplicationKey(state, SettingsTypesEnum.MegalaxExportMap) as IMegalaxExportMapSettingItem[];
export const getAzureSettingsItems = (state: IRootState) => {
    const settingsList = getSettingsItemsByApplicationKey(
        state,
        SettingsTypesEnum.AzureActiveDirectoryLogin
    ) as IAzureSettingItem[];

    return settingsList.length > 0 ? settingsList : null;
};
export const getAzureAuthenticateUrl = (state: IRootState) => getState(state).azureAuthenticateUrl;
export const isAzureEnabled = (state: IRootState) => getAzureAuthenticateUrl(state) !== undefined;
export const isGoogleEnabled = (state: IRootState) => getGoogleClientId(state) !== undefined;
export default settingsItemSlice;
