import LanguageEnum from '@/data/System/LanguageEnum';

export enum NotificationEmailType {
    RESET_PASSWORD = 'RESET_PASSWORD',
    SCHEDULE_PLAN_PUBLISHED = 'SCHEDULE_PLAN_PUBLISHED',
    SCHEDULE_PLAN_NEW_TRADE = 'SCHEDULE_PLAN_NEW_TRADE',
    SCHEDULE_PLAN_TRADE_ACCEPTED = 'SCHEDULE_PLAN_TRADE_ACCEPTED',
    SCHEDULE_PLAN_TRADE_REJECTED = 'SCHEDULE_PLAN_TRADE_REJECTED',
    SCHEDULE_PLAN_NEXT_DAY_NOTIFICATION = 'SCHEDULE_PLAN_NEXT_DAY_NOTIFICATION',
    SCHEDULE_PLAN_SHIFT_COLLIDE_WITH_VACATION = 'SCHEDULE_PLAN_SHIFT_COLLIDE_WITH_VACATION'
}

export type INotificationEmailTranslationCUModel = {
    body: string;
    locale: string;
    subject: string;
};
export type INotificationEmailTranslationModel = INotificationEmailTranslationCUModel & {
    id: number;
};

export type INotificationEmailCUModel = Pick<INotificationEmailTranslationModel, 'body' | 'subject'> & {
    type: NotificationEmailType;
    _translations: Record<LanguageEnum, INotificationEmailTranslationCUModel | undefined>;
};
export type INotificationEmailModel = INotificationEmailCUModel & {
    id: number;
    _translations: Record<LanguageEnum, INotificationEmailTranslationModel | undefined>;
};
