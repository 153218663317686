import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import ActivitiesListPage from '@/pages/ActivitiesListPage';
import AgentGroupsListPage from '@/pages/AgentGroupsListPage';
import BreaksListPage from '@/pages/BreaksListPage';
import CallCentersListPage from '@/pages/CallCentersListPage';
import ContractsListPage from '@/pages/ContractsListPage';
import DashboardPage from '@/pages/DashboardPage';
import IntegratedApplicationListPage from '@/pages/IntegratedApplicationListPage';
import NeedsListPage from '@/pages/NeedsListPage';
import PeriodsListPage from '@/pages/PeriodsListPage';
import QueuesListPage from '@/pages/QueuesListPage';
import CoverageToRequirePage from '@/pages/Reports/CoverageToRequirePage';
import PredictedOperatorsCountToRealOperatorsCountPage from '@/pages/Reports/PredictedOperatorsCountToRealOperatorsCountPage';
import PredictedTimeToRealTimePage from '@/pages/Reports/PredictedTimeToRealTimePage';
import RealAverageDurationToRealDurationPage from '@/pages/Reports/RealAverageDurationToRealDurationPage';
import RequestTypesListPage from '@/pages/RequestTypesListPage';
import SchedulePlansDetailPage from '@/pages/SchedulePlansDetailPage';
import SchedulePlansListPage from '@/pages/SchedulePlansListPage';
import SettingsPage from '@/pages/settings/SettingsPage';
import ShiftsListPage from '@/pages/ShiftsListPage';
import SkillsListPage from '@/pages/SkillsListPage';
import TimeoffsListPage from '@/pages/TimeoffsListPage';
import UsersClonePage from '@/pages/UsersClonePage';
import UsersCreatePage from '@/pages/UsersCreatePage';
import UsersDetailPage from '@/pages/UsersDetailPage';
import UsersListPage from '@/pages/UsersListPage';
import UsersToRequestListPage from '@/pages/UsersToRequestListPage';
import WorkplacesListPage from '@/pages/WorkplacesListPage';
import * as routes from '@/utils/routes';

const StyledMain = styled('main')`
    display: flex;
    grid-area: content;
    flex-direction: column;
`;

const Content = () => (
    <StyledMain>
        <Routes>
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainActivities())}
                element={<ActivitiesListPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainAgentGroups())}
                element={<AgentGroupsListPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainContracts())}
                element={<ContractsListPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainDashboard())} element={<DashboardPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainIntegratedApplication())}
                element={<IntegratedApplicationListPage />}
            />
            {/* <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainRoles())} element={<RolesListPage />} /> */}
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainBreaks())} element={<BreaksListPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainCallCenters())}
                element={<CallCentersListPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainNeeds())} element={<NeedsListPage />} />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainPeriods())} element={<PeriodsListPage />} />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainQueues())} element={<QueuesListPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainCoverageToRequire())}
                element={<CoverageToRequirePage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainPredictedTimeToRealTime())}
                element={<PredictedTimeToRealTimePage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainPredictedOperatorsCountToRealOperatorsCount())}
                element={<PredictedOperatorsCountToRealOperatorsCountPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainRealAverageDurationToRealDuration())}
                element={<RealAverageDurationToRealDurationPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainUsersRequest())}
                element={<UsersToRequestListPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainRequestTypes())}
                element={<RequestTypesListPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainShifts())} element={<ShiftsListPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainSchedulePlans())}
                element={<SchedulePlansListPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainSchedulePlansDetail({ isNew: ':isNew' }))}
                element={<SchedulePlansDetailPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainSkills())} element={<SkillsListPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainTimeoffs())}
                element={<TimeoffsListPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainUsers())} element={<UsersListPage />} />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainUsersCreate())}
                element={<UsersCreatePage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainUsersDetail({ id: ':id' }))}
                element={<UsersDetailPage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainUsersClone({ id: ':id' }))}
                element={<UsersClonePage />}
            />
            <Route
                path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainWorkplaces())}
                element={<WorkplacesListPage />}
            />
            <Route path={routes.removeUrlPrefix(routes.MAIN_URL, routes.mainSettings())} element={<SettingsPage />} />
        </Routes>
    </StyledMain>
);

export default memo(Content);
