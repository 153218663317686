import { IColors } from '@/assets/theme';
import { IValueType } from '@/base/FormGenerator';
import { ISchedulerProps } from '@/components/modules/Scheduler/Scheduler';
import { IPeriodSelectModel } from '@/data/Periods/PeriodModels';
import { ISchedulePlanDayOptModel } from '@/data/SchedulePlanDays/SchedulePlanDayModels';
import { ISchedulePlanDayShiftDelayModel } from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelayModels';
import {
    ISchedulePlanDayShiftExtendedModel,
    ISchedulePlanDayShiftWithoutDefinitionExtendedModel
} from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import { ISchedulePlanModel } from '@/data/SchedulePlans/SchedulePlanModels';
import { ISchedulePlanValidationModel } from '@/data/SchedulePlanValidations/SchedulePlanValidationModels';
import { IShiftSelectModel } from '@/data/Shifts/ShiftModels';
import { IUserModel } from '@/data/Users/UserModels';
import { uniqueArrayOfSimpleValues } from '@/helpers/array/unique';
import { dayTest, getDayBitBooleanArrayFromValue } from '@/helpers/bit';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { ISchedulerCalendarHeaderProps } from '@/modules/Scheduler/components/SchedulerCalendarHeader/SchedulerCalendarHeader';
import SchedulePlanDayShiftDelayTypeEnum from '@/utils/enums/SchedulePlanDayShifts/SchedulePlanDayShiftDelayTypeEnum';
import SchedulePlanWidthTypeEnum from '@/utils/enums/SchedulePlanWidthTypeEnum';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';

export type ICellDataType<T = DateTimeType, EXT = {}> = {
    from: T;
    shift_joins: (EXT & {
        id: number;
        shift_id: number | null;
        shift_name: string | null;
        user_id: number | null;
        extra_padding_right: boolean;
        from: T;
        to: T;
        assigned_skills: number[];
    } & Pick<IUserModel, 'first_name' | 'middle_name' | 'last_name'>)[];
    vacations: (EXT & {
        id: number;
        from: T;
        to: T;
    })[];
    requests: (EXT & {
        id: number;
        from: T;
        to: T;
    })[];
};

export type ICell<DATE extends Date | DateTimeType> = {
    id: number;
    from: DATE;
    to: DATE;
};
export type IFilterChanged = {
    name: 'id' | 'isNew' | 'workplaceId' | 'periodId' | 'displayMode' | 'from' | 'to' | 'type';
    value: string;
}[];
export type ICellHeaderData<DATE extends Date | DateTimeType> = {
    id: string;
    covered: number | null;
    date: DATE;
    planDayId?: number;
    required: number | null;
    schedulePlanDayShiftIds: number[];
    userIds: number[];
};
type IDates<T = DateTimeType> = { from: T; to: T };

const getValidFromToDates = <T extends { period_start: string; period_end: string }>(
    displayMode: ISchedulerProps['displayMode'],
    from: ISchedulerProps['fromDate'],
    to: ISchedulerProps['toDate'],
    period: T | null,
    timeZone: string = 'UTC'
): [DateTimeType | null, DateTimeType | null] => {
    const strFrom = (from === '' ? null : from) ?? period?.period_start ?? null;
    const strTo = (to === '' ? null : to) ?? period?.period_end ?? null;
    let fromDate = strFrom !== null ? DateHelper.fromDateString(strFrom, timeZone) : null;
    let toDate = strTo !== null ? DateHelper.fromDateString(strTo, timeZone) : null;

    if (fromDate === null || toDate === null) {
        return [null, null];
    }

    if (
        [SchedulePlanWidthTypeEnum.Week].some((item) => item === displayMode) &&
        period &&
        from === null &&
        DateHelper.isBetween(
            DateHelper.now(),
            DateHelper.fromDateString(period.period_start, timeZone),
            DateHelper.fromDateString(period.period_end, timeZone)
        )
    ) {
        fromDate = DateHelper.getFirstDayOfWeekday();
    }

    switch (displayMode) {
        case SchedulePlanWidthTypeEnum.Day:
            toDate = DateHelper.addDays(fromDate, 1);
            break;
        case SchedulePlanWidthTypeEnum.Week:
            if (to === null) {
                toDate = DateHelper.addDays(fromDate, 6);
            }

            break;
        case SchedulePlanWidthTypeEnum.Period:
        case SchedulePlanWidthTypeEnum.Custom:
        default:
            break;
    }

    return [fromDate, toDate] as [DateTimeType, DateTimeType];
};

const scheduleStateToColor = (state: ISchedulePlanModel['state'], optimal: ISchedulePlanModel['optimal']): IColors => {
    switch (state) {
        case SchedulePlanStateEnum.Calculated:
            if (optimal) {
                return 'success';
            } else {
                return 'warning';
            }
        case SchedulePlanStateEnum.Closed:
        case SchedulePlanStateEnum.Locked:
            return 'secondary';
        case SchedulePlanStateEnum.Draft:
            return 'warning';
        case SchedulePlanStateEnum.Error:
            return 'error';
        case SchedulePlanStateEnum.Prepared:
            return 'info';
        default:
            return 'default';
    }
};

export const getErrorMessageForSchedule = (
    data: ISchedulePlanDayShiftDelayModel & {
        assigned_shift: ISchedulePlanDayShiftExtendedModel | ISchedulePlanDayShiftWithoutDefinitionExtendedModel;
    }
) => {
    const dateStart = data.start ? DateHelper.fromDateTimeString(data.start) : null;
    const dateEnd = data.end ? DateHelper.fromDateTimeString(data.end) : null;

    if (data.type === SchedulePlanDayShiftDelayTypeEnum.break) {
        if (data.schedule_plan_day_shift_break_id) {
            const shiftBreak =
                data.assigned_shift.schedule_plan_day_shift_breaks.find(
                    (item) => item.id === data.schedule_plan_day_shift_break_id
                ) ?? null;

            const breakStart = DateHelper.fromDateTimeString(shiftBreak?.start ?? '');
            const breakEnd = DateHelper.addMinutes(
                DateHelper.fromDateTimeString(shiftBreak?.start ?? ''),
                shiftBreak?.break.duration
            );

            const params = {
                break: shiftBreak?.break.name ?? '',
                shift: '',
                skill: '',
                from: dateStart ? DateHelper.formatDateTimeToISO(dateStart) : '',
                to: dateEnd ? DateHelper.formatDateTimeToISO(dateEnd) : ''
            };

            if (dateStart && dateEnd && dateStart < breakStart && dateEnd < breakEnd) {
                return {
                    key: 'message.error.theUserStartedBreakEarlier',
                    default: 'The User started their break {{break}} by {{delay}}',
                    params: params
                };
            } else if (dateStart && dateEnd && dateStart < breakStart && dateEnd > breakEnd) {
                return {
                    key: 'message.error.theUserStartedAndEndedBreakEarlierAndLater',
                    default: 'The User was earlier and late on their break {{break}} by {{delay}}',
                    params: params
                };
            } else if (dateStart && !dateEnd) {
                return {
                    key: 'message.error.theUserStartedTheirBreakFromAndIsDelayedBy',
                    default: 'The User is on break {{break}} from {{from}} and is delayed by {{delay}}',
                    params: params
                };
            } else {
                return {
                    key: 'message.error.theUserEndedBreakLater',
                    default: 'The User ended their break {{break}} by {{delay}}',
                    params: params
                };
            }
        } else if (dateStart && dateEnd) {
            return {
                key: 'message.error.theUserTakeBreakForDelayFromToBy',
                default: 'The user take break for {{delay}} from {{from}} - {{to}}',
                params: {
                    from: DateHelper.formatDateTimeToISO(dateStart),
                    to: DateHelper.formatDateTimeToISO(dateEnd),
                    skill: '',
                    shift: ''
                }
            };
        } else {
            return {
                key: 'message.error.theUserStartedTheirBreakFromAndIsDelayedBy',
                default: 'The User is on break {{break}} from {{from}} and is delayed by {{delay}}',
                params: {
                    from: DateHelper.formatDateTimeToISO(dateStart),
                    to: '',
                    shift: '',
                    skill: '',
                    break: ''
                }
            };
        }
    } else {
        if (data.schedule_plan_day_shift_skill_id) {
            if (dateStart && dateEnd) {
                return {
                    key: 'message.error.theUserWasOnTheirShiftInSkillFromToAndWasDelaysBy',
                    default:
                        'The User was on their shift {{shift}} in skill {{skill}} at {{from}} - {{to}} and was delayed by {{delay}}',
                    params: {
                        skill:
                            data.assigned_shift.schedule_plan_day_shift_skills.find(
                                (item) => item.id == data.schedule_plan_day_shift_skill_id
                            )?.skill.name ?? '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: DateHelper.formatDateTimeToISO(dateStart),
                        to: DateHelper.formatDateTimeToISO(dateEnd)
                    }
                };
            } else if (dateStart && !dateEnd) {
                return {
                    key: 'message.error.theUserIsOnTheirShiftInSkillFromToAndIsDelaysBy',
                    default:
                        'The User is on their shift {{shift}} in skill {{skill}} from {{from}} and is delayed by {{delay}}',
                    params: {
                        skill:
                            data.assigned_shift.schedule_plan_day_shift_skills.find(
                                (item) => item.id == data.schedule_plan_day_shift_skill_id
                            )?.skill.name ?? '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: DateHelper.formatDateTimeToISO(dateStart),
                        to: ''
                    }
                };
            } else {
                return {
                    key: 'message.error.theUserIsNotOnTheirShiftInSkillAndWasDelaysBy',
                    default: 'The User is not on their shift {{shift}} in skill {{skill}} and is delayed by {{delay}}',
                    params: {
                        skill:
                            data.assigned_shift.schedule_plan_day_shift_skills.find(
                                (item) => item.id == data.schedule_plan_day_shift_skill_id
                            )?.skill.name ?? '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: '',
                        to: ''
                    }
                };
            }
        } else {
            if (dateStart && dateEnd) {
                return {
                    key: 'message.error.theUserWasOnTheirShiftFromToAndWasDelaysBy',
                    default: 'The User was on their shift {{shift}} in {{from}} - {{to}} and was delayed by {{delay}}',
                    params: {
                        skill:
                            data.assigned_shift.schedule_plan_day_shift_skills.find(
                                (item) => item.id == data.schedule_plan_day_shift_skill_id
                            )?.skill.name ?? '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: DateHelper.formatDateTimeToISO(dateStart),
                        to: DateHelper.formatDateTimeToISO(dateEnd)
                    }
                };
            } else if (dateStart && !dateEnd) {
                return {
                    key: 'message.error.theUserIsOnTheirShiftFromToAndIsDelaysBy',
                    default: 'The User is on their shift {{shift}} from {{from}} and was delayed by {{delay}}',
                    params: {
                        skill: '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: DateHelper.formatDateTimeToISO(dateStart),
                        to: ''
                    }
                };
            } else {
                return {
                    key: 'message.error.theUserIsNotOnTheirShiftAndWasDelaysBy',
                    default: 'The User is not on their shift {{shift}} and is delayed by {{delay}}',
                    params: {
                        skill: '',
                        shift: data.assigned_shift.shift?.name ?? '-',
                        from: '',
                        to: ''
                    }
                };
            }
        }
    }
};

const defaultParams = {
    from: '',
    fromDateTime: '',
    to: '',
    toDateTime: '',
    code: '',
    day: '',
    days: [],
    limit: '',
    limits: ''
};

export const getMessageByValidationCode = (data: ISchedulePlanValidationModel) => {
    switch (data.validation_code) {
        case 'CONTINUOUS_BREAK_ERROR':
            return {
                key: 'message.error.theUserDoesntHaveContinuousBreak',
                default:
                    "The User doesn't have Continuous Break of minimal length {{limits}} (only {{value}}) in week {{from}} - {{to}}",
                params: {
                    ...defaultParams,
                    from: data.date_start ? data.date_start : '',
                    limits: DateHelper.getMinutesInHumanFormat((data.min_continuous_rest_per_week ?? 0) * 60),
                    to: data.date_end ? data.date_end : '',
                    value: DateHelper.getMinutesInHumanFormat((data.longest_continuous_rest ?? 0) * 60)
                }
            };
        case 'DAY_NOT_IN_CONTRACT_ERROR':
            return {
                key: 'message.error.theDay_1_isNotInContract_permittedDays_2',
                default: 'The Day {{day}} is not in Contract (permitted day {{days}})',
                params: {
                    ...defaultParams,
                    day: data.date_start ? DateHelper.getDayOfWeek(DateHelper.fromDateTimeString(data.date_start)) : -1,
                    days: data.days ? getDayBitBooleanArrayFromValue(data.days) : []
                }
            };
        case 'EMPLOYEE_MISSING_ERROR':
            return {
                key: 'message.error.theUserWasNotFoundInWorkplace',
                default: 'The User was not found in Workplace',
                params: {
                    ...defaultParams
                }
            };
        case 'INACTIVE_USER_HAS_SHIFT_ERROR':
            return {
                key: 'message.error.inactiveUserHasShift',
                default: 'The Inactive User has Shift',
                params: {
                    ...defaultParams
                }
            };
        case 'NIGHT_SHIFT_ERROR':
            return {
                key: 'message.error.theUserHasNightShift',
                default: 'The User has Night Shift (Night Shift is from {{fromDateTime}} to {{toDateTime}})',
                params: {
                    ...defaultParams,
                    fromDateTime: data.night_start ? data.night_start : '',
                    toDateTime: data.night_end ? data.night_end : ''
                }
            };
        case 'REST_IN_SHIFT_ERROR':
            return {
                key: 'message.error.restInShift',
                default: 'User has no Break in Shift (limits {{limits}})',
                params: {
                    ...defaultParams,
                    limits: DateHelper.getMinutesInHumanFormat((data.break_at_least_after_hours ?? 0) * 60)
                }
            };
        case 'REST_IN_SHIFT_DISABLED_WARNING':
            return {
                key: 'message.error.userHasSetMandatoryRestPerShiftToMoreThanStandard',
                default: 'User has set Mandatory Rest per Shift to more than {{limit}}',
                params: {
                    ...defaultParams,
                    limit: DateHelper.getMinutesInHumanFormat((data.default_break_after_hours ?? 0) * 60)
                }
            };
        case 'SHIFT_DISTANCE_ERROR':
            return {
                key: 'message.error.distanceBetweenShifts',
                default: 'Distance between shifts ({{fromDateTime}} - {{toDateTime}}) is lower than {{limit}}',
                params: {
                    ...defaultParams,
                    fromDateTime: data.date_start ? data.date_start : '',
                    toDateTime: data.date_end ? data.date_end : '',
                    limit: DateHelper.getMinutesInHumanFormat((data.min_distance_hours ?? 0) * 60)
                }
            };
        case 'SHIFT_IN_VACATION_ERROR':
            return {
                key: 'message.error.theUserHasShiftInVacation',
                default: 'The User has Shift in Vacation (Vacation from {{fromDateTime}} to {{toDateTime}})',
                params: {
                    ...defaultParams,
                    fromDateTime: data.vacation_start ? data.vacation_start : '',
                    toDateTime: data.vacation_end ? data.vacation_end : ''
                }
            };
        case 'SHIFT_NOT_FOUND_ERROR':
            return {
                key: 'message.error.theShiftWasNotFound',
                default: 'The Shift was not found',
                params: {
                    ...defaultParams
                }
            };
        case 'SHIFT_OUTSIDE_CONTRACT_INTERVAL_ERROR':
            return {
                key: 'message.error.theUserHasShiftOutsideContract',
                default: 'The User has Shift outside Contract (Contract from {{from}} to {{to}})',
                params: {
                    ...defaultParams,
                    from: data.contract_start ? data.contract_start : '',
                    to: data.contract_end ? data.contract_end : ''
                }
            };
        case 'SHIFT_OUTSIDE_VALID_INTERVAL_ERROR':
            return {
                key: 'message.error.theUserHasShiftOutsideValidInterval',
                default: 'The User has Shift outside Valid Interval (Valid Interval from {{from}} to {{to}})',
                params: {
                    ...defaultParams,
                    from: data.shift_valid_start ? data.shift_valid_start : '',
                    to: data.shift_valid_end ? data.shift_valid_end : ''
                }
            };
        case 'SUBSIDY_ERROR_DAY':
            return {
                key: 'message.error.theUserExceededDaySubsidyLimit',
                default: 'The User exceeded Day Subsidy Limit ({{limit}}) at {{from}} with {{value}}',
                params: {
                    ...defaultParams,
                    from: data.date_start ? data.date_start : '',
                    limit: DateHelper.getMinutesInHumanFormat((data.subsidy_limit ?? 0) * 60),
                    value: DateHelper.getMinutesInHumanFormat((data.subsidy_value ?? 0) * 60)
                }
            };
        case 'SUBSIDY_ERROR_WEEK':
            return {
                key: 'message.error.theUserExceededWeekSubsidyLimit',
                default: 'The User exceeded Week Subsidy Limit ({{limit}}) at {{from}} - {{to}} with {{value}}',
                params: {
                    ...defaultParams,
                    from: data.date_start ? data.date_start : '',
                    to: data.date_start
                        ? DateHelper.formatISO(DateHelper.addDays(DateHelper.fromDateTimeString(data.date_start), 7))
                        : '',
                    limit: DateHelper.getMinutesInHumanFormat((data.subsidy_limit ?? 0) * 60),
                    value: DateHelper.getMinutesInHumanFormat((data.subsidy_value ?? 0) * 60)
                }
            };
        case 'SUBSIDY_ERROR_MONTH':
            return {
                key: 'message.error.theUserExceededMonthSubsidyLimit',
                default: 'The User exceeded Month Subsidy Limit ({{limit}}) at {{from}} - {{to}} with {{value}}',
                params: {
                    ...defaultParams,
                    from: data.date_start ? data.date_start : '',
                    to: data.date_start
                        ? DateHelper.formatDate(DateHelper.addMonths(DateHelper.fromDateTimeString(data.date_start), 1))
                        : '',
                    limit: DateHelper.getMinutesInHumanFormat((data.subsidy_limit ?? 0) * 60),
                    value: DateHelper.getMinutesInHumanFormat((data.subsidy_value ?? 0) * 60)
                }
            };
        case 'WORKING_ON_HOLIDAY_ERROR':
            return {
                key: 'message.error.workingOnHolidayError',
                default: 'Working on Holiday',
                params: {
                    ...defaultParams
                }
            };
        default:
            return {
                key: 'message.error.unknownErrorCode',
                default: 'Unknown Error Code {{code}}',
                params: {
                    ...defaultParams,
                    code: data.validation_code
                }
            };
    }
};

export const getTranslateOfValidationType = (type: ISchedulePlanValidationModel['type']) => {
    switch (type) {
        case 'INFO':
            return {
                key: 'label.info',
                default: 'Info',
                params: {}
            };
        case 'WARNING':
            return {
                key: 'label.warning',
                default: 'Warning',
                params: {}
            };
        case 'ERROR':
        default:
            return {
                key: 'label.error',
                default: 'Error',
                params: {}
            };
    }
};

export const isCorrectValidationErrorOnShift = (
    item: Pick<
        ISchedulePlanValidationModel,
        'date_start' | 'date_end' | 'schedule_plan_day_shift_id' | 'user_id' | 'validation_code'
    >,
    schedulePlanDayShiftId: number,
    userId: number,
    shiftStart: DateTimeType | null,
    shiftEnd: DateTimeType | null
) => {
    const dateStart = item.date_start ? DateHelper.fromDateTimeString(item.date_start) : null;

    switch (item.validation_code) {
        case 'CONTINUOUS_BREAK_ERROR':
            return (
                item.user_id === userId &&
                item.date_start &&
                item.date_end &&
                shiftStart &&
                shiftEnd &&
                (DateHelper.isBetween(
                    shiftEnd,
                    DateHelper.fromDateTimeString(item.date_start),
                    DateHelper.fromDateTimeString(item.date_end),
                    '()'
                ) ||
                    DateHelper.isBetween(
                        shiftStart,
                        DateHelper.fromDateTimeString(item.date_start),
                        DateHelper.fromDateTimeString(item.date_end),
                        '[)'
                    ))
            );
        case 'DAY_NOT_IN_CONTRACT_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'EMPLOYEE_MISSING_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'INACTIVE_USER_HAS_SHIFT_ERROR':
            return item.user_id === userId && item.date_start && DateHelper.isEqual(dateStart, shiftStart);
        case 'NIGHT_SHIFT_ERROR':
            return (
                item.user_id === userId &&
                shiftStart &&
                shiftEnd &&
                dateStart &&
                DateHelper.isBetween(dateStart, shiftStart, shiftEnd, '[)')
            );
        case 'SHIFT_DISTANCE_ERROR':
            return (
                (item.date_start &&
                    shiftEnd &&
                    DateHelper.isEqual(shiftEnd, DateHelper.fromDateTimeString(item.date_start))) ||
                (item.date_end &&
                    shiftStart &&
                    DateHelper.isEqual(shiftStart, DateHelper.fromDateTimeString(item.date_end)))
            );
        case 'REST_IN_SHIFT_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'SHIFT_IN_VACATION_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'SHIFT_NOT_FOUND_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'SHIFT_OUTSIDE_CONTRACT_INTERVAL_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'SHIFT_OUTSIDE_VALID_INTERVAL_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        case 'SUBSIDY_ERROR_DAY':
            return (
                item.user_id === userId &&
                shiftStart &&
                dateStart &&
                (DateHelper.isBetween(shiftStart, dateStart, DateHelper.addDays(dateStart, 1)) ||
                    (shiftEnd && DateHelper.isBetween(shiftEnd, dateStart, DateHelper.addDays(dateStart, 1))))
            );
        case 'SUBSIDY_ERROR_WEEK':
            return (
                item.user_id === userId &&
                shiftStart &&
                dateStart &&
                (DateHelper.isBetween(shiftStart, dateStart, DateHelper.addDays(dateStart, 7)) ||
                    (shiftEnd && DateHelper.isBetween(shiftEnd, dateStart, DateHelper.addDays(dateStart, 7))))
            );
        case 'SUBSIDY_ERROR_MONTH':
            return (
                item.user_id === userId &&
                shiftStart &&
                dateStart &&
                (DateHelper.isBetween(shiftStart, dateStart, DateHelper.addMonths(dateStart, 1)) ||
                    (shiftEnd && DateHelper.isBetween(shiftEnd, dateStart, DateHelper.addMonths(dateStart, 1))))
            );
        case 'WORKING_ON_HOLIDAY_ERROR':
            return item.schedule_plan_day_shift_id === schedulePlanDayShiftId;
        default:
            return false;
    }
};

const isDateInValidDateOfShift = (shift: IShiftSelectModel, day: DateTimeType): boolean => {
    const startValue = DateHelper.fromOptionalDateString(shift.valid_start);
    const endValue = DateHelper.fromOptionalDateString(shift.valid_end);

    return (
        dayTest(shift.days, DateHelper.weekDay(day)) &&
        (!startValue || !DateHelper.isBefore(day, startValue)) &&
        (!endValue || !DateHelper.isAfter(day, endValue))
    );
};
const filterChanged = (
    width: ISchedulerCalendarHeaderProps['mode'],
    fieldId: string,
    value: IValueType,
    periods: IPeriodSelectModel[],
    validDates?: IDates
): IFilterChanged | undefined => {
    switch (fieldId) {
        case 'workplaceId':
        case 'displayMode':
            return [{ name: fieldId, value: value as string }];
        case 'date':
            return [{ name: 'from', value: DateHelper.formatDate(value as DateTimeType) }];
        case 'periodId': {
            const period = periods.find(({ id }) => `${id}` === `${value}`);
            let start = '',
                end = '';

            if (period?.period_start && period.period_end) {
                const [from, to] = getValidFromToDates(width, null, null, {
                    period_start: period.period_start,
                    period_end: period.period_end
                });

                start = DateHelper.formatDate(from);
                end = DateHelper.formatDate(to);
            }

            return [
                { name: 'periodId', value: value as string },
                { name: 'from', value: start },
                { name: 'to', value: end }
            ];
        }
        case 'date_range': {
            const { start: from, end: to } = (value as IDateRangePickerProps['value']) ?? {
                start: null,
                end: null
            };
            const sourceTo = to ?? validDates?.to;

            return [
                { name: 'from', value: DateHelper.formatDate(from || validDates?.from) },
                {
                    name: 'to',
                    value: sourceTo ? DateHelper.formatDate(DateHelper.getFirstMomentOfDay(sourceTo)) : ''
                }
            ];
        }
        default:
            console.error(`Unexpected fieldId ${fieldId}`);
    }
};

const filterGoLeftOrRight = (
    mode: ISchedulerCalendarHeaderProps['mode'],
    isRight: boolean,
    validDate: IDates,
    periodDates: IDates<DateTimeType | null>
): IFilterChanged => {
    let newTo: DateTimeType, newFrom: DateTimeType;

    const add = mode === SchedulePlanWidthTypeEnum.Day ? 0 : 1;
    const duration =
        mode === SchedulePlanWidthTypeEnum.Day ? 1 : DateHelper.getDifferenceAsDays(validDate.from, validDate.to);

    if (isRight) {
        newFrom = DateHelper.addDays(validDate.to, add);
    } else {
        newFrom = DateHelper.addDays(validDate.from, -(add + duration));
    }

    if (periodDates.from && DateHelper.isBefore(newFrom, periodDates.from)) {
        newFrom = periodDates.from;
    }

    newTo = DateHelper.addDays(newFrom, duration);

    if (periodDates.to && DateHelper.isAfter(newTo, periodDates.to)) {
        newTo = periodDates.to;
    }

    newFrom = DateHelper.addDays(newTo, -duration);

    return [
        { name: 'from', value: DateHelper.formatDate(newFrom) },
        { name: 'to', value: mode === SchedulePlanWidthTypeEnum.Day ? '' : DateHelper.formatDate(newTo) }
    ];
};

const generateHeaderColumnsData = <DATE extends Date | DateTimeType>(
    isDayMode: boolean,
    id: number,
    isSkill: boolean,
    header: ICell<DATE>[],
    schedulePlanDays: (ISchedulePlanDayOptModel & { from: DATE })[] = []
): { userIds: number[]; columns: ICellHeaderData<DATE>[]; hasAnyRequirements: boolean } => {
    if (!header.length) {
        return { userIds: [], columns: [], hasAnyRequirements: false };
    }

    const columns: ICellHeaderData<DATE>[] = [];
    const filteredPlanDays = schedulePlanDays.filter((schedulePlanDay) =>
        schedulePlanDay.schedule_plan_day_skills.some((item) => (isSkill ? item.skill_id : item.role_id) === id)
    );

    // if (!filteredPlanDays.length) {
    //     return { userIds: [], columns: [], hasAnyRequirements: false };
    // }

    const userIdsInHeader: number[] = [];

    header.forEach((column) => {
        if (isDayMode) {
            const filteredPlanDaysByTime = filteredPlanDays.filter((item) =>
                DateHelper.isEqual(item.from, column.from)
            );

            const required = filteredPlanDaysByTime
                .flatMap((item) => item.schedule_plan_day_skills)
                .filter((item) => (isSkill ? item.skill_id === id : item.role_id === id))
                .reduce<number>((previousValue, currentValue) => previousValue + currentValue.required_min, 0);
            const data = filteredPlanDaysByTime.reduce(
                (prev, current) => {
                    const parseShift = current.schedule_plan_day_skills
                        .filter((spdsk) => (isSkill ? spdsk.skill_id : spdsk.role_id) === id)
                        .reduce(
                            (prevSpdsk, currentSpdsk) => ({
                                schedulePlanDayShiftIds: [
                                    ...prevSpdsk.schedulePlanDayShiftIds,
                                    ...currentSpdsk.schedule_plan_day_shift_ids
                                ],
                                userIds: [...prevSpdsk.userIds, ...currentSpdsk.user_ids]
                            }),
                            { schedulePlanDayShiftIds: [], userIds: [] } as {
                                schedulePlanDayShiftIds: number[];
                                userIds: number[];
                            }
                        );

                    return {
                        schedulePlanDayShiftIds: [
                            ...prev.schedulePlanDayShiftIds,
                            ...parseShift.schedulePlanDayShiftIds
                        ],
                        userIds: [...prev.userIds, ...parseShift.userIds]
                    };
                },
                { schedulePlanDayShiftIds: [], userIds: [] } as {
                    schedulePlanDayShiftIds: number[];
                    userIds: number[];
                }
            );

            const schedulePlanDayShiftIds = uniqueArrayOfSimpleValues(data.schedulePlanDayShiftIds);
            const userIds = uniqueArrayOfSimpleValues(data.userIds);

            userIdsInHeader.push(...userIds);

            columns.push({
                id: `required-${isSkill ? 's' : 'r'}${id}-${column.id}`,
                covered: filteredPlanDaysByTime.length > 0 ? schedulePlanDayShiftIds.length : null,
                date: column.from,
                required: filteredPlanDaysByTime.length > 0 ? required : null,
                schedulePlanDayShiftIds,
                userIds
            });
        } else {
            const lastMoment = DateHelper.addDays(column.from, 1);
            const filteredPlanDaysByTime = filteredPlanDays.filter((item) =>
                DateHelper.isBetween(item.from, column.from, lastMoment, '[)')
            );

            const {
                worst: worstMatch,
                best: bestMatch,
                ...restOfResult
            } = filteredPlanDaysByTime
                .map(({ schedule_plan_day_skills }) => {
                    const validSchdulePlanDaySkills = schedule_plan_day_skills.filter((item) =>
                        isSkill ? item.skill_id === id : item.role_id === id
                    );
                    const schedulePlanDayShiftIds = uniqueArrayOfSimpleValues(
                        validSchdulePlanDaySkills.flatMap((item) => item.schedule_plan_day_shift_ids)
                    );
                    const userIds = uniqueArrayOfSimpleValues(
                        validSchdulePlanDaySkills.flatMap((item) => item.user_ids)
                    );

                    return {
                        covered: schedulePlanDayShiftIds.length,
                        required: validSchdulePlanDaySkills
                            .filter((item) => (isSkill ? item.skill_id === id : item.role_id === id))
                            .reduce((prev, current) => current.required_min + prev, 0),
                        schedulePlanDayShiftIds,
                        userIds
                    };
                })
                .reduce(
                    ({ best, schedulePlanDayShiftIds, userIds, worst }, current) => {
                        const currentDiff = current.required - current.covered;
                        const prevBestDiff = best.required - best.covered;
                        const prevWorstDiff = worst.required - worst.covered;

                        return {
                            schedulePlanDayShiftIds: uniqueArrayOfSimpleValues([
                                ...schedulePlanDayShiftIds,
                                ...current.schedulePlanDayShiftIds
                            ]),
                            userIds: uniqueArrayOfSimpleValues([...userIds, ...current.userIds]),
                            best:
                                currentDiff === prevBestDiff && current.required > best.required
                                    ? current
                                    : currentDiff < prevBestDiff
                                    ? current
                                    : best,
                            worst: currentDiff >= prevWorstDiff && current.required >= worst.required ? current : worst
                        };
                    },
                    {
                        schedulePlanDayShiftIds: [],
                        userIds: [],
                        best: { required: 0, covered: 0 },
                        worst: { required: 0, covered: 0 }
                    } as {
                        schedulePlanDayShiftIds: number[];
                        userIds: number[];
                        best: { required: number; covered: number };
                        worst: { required: number; covered: number };
                    }
                );

            const displayWorst = worstMatch.required > worstMatch.covered;

            userIdsInHeader.push(...restOfResult.userIds);

            columns.push({
                ...restOfResult,
                id: `required-${isSkill ? 's' : 'r'}${id}-${column.id}`,
                covered:
                    filteredPlanDaysByTime.length > 0 ? (displayWorst ? worstMatch.covered : bestMatch.covered) : null,
                date: column.from,
                required:
                    filteredPlanDaysByTime.length > 0 ? (displayWorst ? worstMatch.required : bestMatch.required) : null
            });
        }
    });

    return { userIds: uniqueArrayOfSimpleValues(userIdsInHeader), columns, hasAnyRequirements: true };
};

export const getEmptyShiftFrom = (periodStart?: string, filledValue?: string, tz?: string) =>
    filledValue
        ? DateHelper.fromDateString(filledValue, tz)
        : periodStart
        ? DateHelper.addDays(DateHelper.fromDateString(periodStart, tz), -14)
        : null;

export {
    getValidFromToDates,
    generateHeaderColumnsData,
    isDateInValidDateOfShift,
    scheduleStateToColor,
    filterChanged,
    filterGoLeftOrRight
};
