import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ElevatorIcon from '@mui/icons-material/Elevator';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FoundationOutlinedIcon from '@mui/icons-material/FoundationOutlined';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon, { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import systemTheme from '@/assets/theme';
import { FieldWrapper } from '@/base/FormGenerator';
import UserPermission, { isUserAllowed, Mode } from '@/components/UserPermision';
import { syncGoogle } from '@/data/Google/GoogleActions';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import {
    calculateSchedulePlan,
    closeSchedulePlan,
    recountFunds,
    validatePlan
} from '@/data/SchedulePlans/SchedulePlanActions';
import { schedulePlanById } from '@/data/SchedulePlans/SchedulePlanSlice';
import {
    scheduleGridSettings,
    scheduleGridToggleDisplaySkills,
    scheduleGridToggleDisplayUsedHoursOfUser
} from '@/data/Settings/SettingSlice';
import { fetchGooglesSettings } from '@/data/SettingsItems/SettingsItemActions';
import { isGoogleEnabled } from '@/data/SettingsItems/SettingsItemSlice';
import { getPermissionsList, isSignedUserAdmin, isSignedUserSystemAdmin } from '@/data/System/SystemReducer';
import { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import { ReactComponent as GoogleIcon } from '@/images/google.svg';
import { ClearShiftsDialog } from '@/modules/Scheduler/HeaderMenu/components/ClearShiftsDialog';
import { CloseSchedulePlanDialog } from '@/modules/Scheduler/HeaderMenu/components/CloseSchedulePlanDialog';
import ModifyNeedsDialog from '@/modules/Scheduler/HeaderMenu/components/ModifyNeedsDialog';
import PermissionsEnum, { PermissionsControllerEnum } from '@/utils/enums/PermissionsEnum';
import SchedulerModeEnum from '@/utils/enums/SchedulerModeEnum';
import ConfirmDialog from '@/wrappers/ConfirmDialog';
import Select from '@/wrappers/Select';
import SummarizationDialog from '@/wrappers/SummarizationDialog';
import Switch from '@/wrappers/Switch';
import UploadSchedulePlanDaySkillsCsvDialog from '@/wrappers/UploadSchedulePlanDaySkillsCsvDialog';
import { AvailableScheduleTypes } from '../Scheduler';
import { RecalculateDialog } from './components/RecalculateDialog';
import { RecalculateWithShiftsDialog } from './components/RecalculateWithShiftsDialog';

const StyledCardHeaderActionMenu = styled(Box)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        align-items: center;
        text-align: center;
    `
);

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.text.secondary
    }
}));

const StyledInfoMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.info.main,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.info.main
    }
}));

const StyledRedMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.error.dark,
    [`.${listItemIconClasses.root}`]: {
        color: theme.palette.error.dark
    }
}));

const StyledMenu = styled(Menu)(() => ({
    [`.${menuItemClasses.root}`]: {
        [`.${listItemIconClasses.root}`]: {
            paddingRight: 15,
            height: '40px',
            width: '40px',
            svg: {
                height: '100%',
                width: '100%'
            }
        },
        [`.${listItemTextClasses.root}`]: {
            whiteSpace: 'pre-wrap'
        }
    }
}));

type ISchedulerHeaderMenuProps = {
    from: DateTimeType | null;
    isPlanLocked: boolean;
    isSchedulePlanClosed?: boolean;
    isSchedulePlanDraft?: boolean;
    scheduleName: string | null;
    schedulePlanId: number | null;
    type: AvailableScheduleTypes;
    to: DateTimeType | null;
    onChangeType: (type: AvailableScheduleTypes) => void;
};

const SchedulerHeaderMenu = ({
    from,
    isPlanLocked,
    isSchedulePlanClosed = false,
    isSchedulePlanDraft = false,
    scheduleName,
    schedulePlanId,
    to,
    type,
    onChangeType
}: ISchedulerHeaderMenuProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const googleEnabled = useAppSelector(isGoogleEnabled);
    const userIsAmin = useAppSelector(isSignedUserAdmin);
    const isSystemAdmin = useAppSelector(isSignedUserSystemAdmin);
    const permissionsList = useAppSelector(getPermissionsList);
    const gridSettings = useAppSelector(scheduleGridSettings);
    const schedulePlanData = useAppSelector((state) => schedulePlanById(state, schedulePlanId));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [openUploadCsv, setOpenUploadCsv] = useState(false);
    const [openSummarizationDialog, setOpenSummarizationDialog] = useState(false);
    const [openCloseScheduleDialog, setOpenCloseScheduleDialog] = useState(false);
    const [openModifyNeedsDialog, setOpenModifyNeedsDialog] = useState(false);
    const [openRecalculateScheduleDialog, setOpenRecalculateScheduleDialog] = useState(false);
    const [openRecalculateWithShiftsScheduleDialog, setOpenRecalculateWithShiftsScheduleDialog] = useState(false);
    const [openAIGeneratedDialog, setOpenAIGeneratedDialog] = useState(false);
    const [openClearShiftsDialog, setOpenClearShiftsDialog] = useState(false);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCalculatePlan = useCallback(() => {
        if (schedulePlanId && !isPlanLocked) {
            dispatch(calculateSchedulePlan(schedulePlanId));
        }
    }, [isPlanLocked, schedulePlanId]);
    const handleCloseConfirmDialogForRecalculatePlan = useCallback(() => {
        setOpenRecalculateScheduleDialog(false);
    }, []);
    const handleCloseConfirmDialogForAIGenerated = useCallback(() => {
        setOpenAIGeneratedDialog(false);
    }, []);
    const handleCloseConfirmDialogForRecalculatePlanWithShifts = useCallback(() => {
        setOpenRecalculateWithShiftsScheduleDialog(false);
    }, []);
    const handleCloseConfirmDialogForClosePlan = useCallback(() => {
        setOpenCloseScheduleDialog(false);
    }, []);
    const handleOpenConfirmDialogForRecalculatePlan = useCallback(() => {
        setOpenRecalculateScheduleDialog(true);
    }, []);
    const handleCloseSummarization = useCallback(() => {
        setOpenSummarizationDialog(false);
    }, []);
    const handleOpenSummarization = useCallback(() => {
        if (schedulePlanId && schedulePlanData) {
            setOpenSummarizationDialog(true);
        }
    }, [schedulePlanId, schedulePlanData]);
    const handleOpenUploadCsv = useCallback(() => {
        if (schedulePlanId && schedulePlanData) {
            setOpenUploadCsv(true);
        }
    }, [schedulePlanId, schedulePlanData]);
    const handleOpenModifyNeedsDialog = useCallback(() => {
        if (schedulePlanId && schedulePlanData) {
            setOpenModifyNeedsDialog(true);
        }
    }, [schedulePlanId, schedulePlanData]);
    const handleCloseModifyNeedsDialog = useCallback(() => {
        if (schedulePlanId && schedulePlanData) {
            setOpenModifyNeedsDialog(false);
        }
    }, [schedulePlanId, schedulePlanData]);
    const handleValidatePlan = useCallback(() => {
        if (schedulePlanId) {
            dispatch(validatePlan(schedulePlanId));
        }
    }, [schedulePlanId]);
    const handleRecountFonds = useCallback(() => {
        if (schedulePlanId) {
            dispatch(recountFunds(schedulePlanId));
        }
    }, [schedulePlanId]);
    const handleClosePlan = useCallback(() => {
        if (schedulePlanId && !isSchedulePlanClosed) {
            dispatch(closeSchedulePlan(schedulePlanId));
        }
    }, [schedulePlanId, isSchedulePlanClosed]);
    const handleToggleDisplaySkills = useCallback(() => {
        dispatch(scheduleGridToggleDisplaySkills());
    }, []);
    const handleToggleDisplayUsedHoursOfUser = useCallback(() => {
        dispatch(scheduleGridToggleDisplayUsedHoursOfUser());
    }, []);

    const handleOpenConfirmDialogForClearShifts = useCallback(() => {
        setOpenClearShiftsDialog(true);
    }, []);
    const handleOpenConfirmDialogForRecalculatePlanWithShifts = useCallback(() => {
        setOpenRecalculateWithShiftsScheduleDialog(true);
    }, []);
    const handleOpenConfirmDialogForAIGenerated = useCallback(() => {
        setOpenAIGeneratedDialog(true);
    }, []);

    const handleSyncWithGoogle = useCallback(() => dispatch(syncGoogle(schedulePlanId ?? null)), [schedulePlanId]);

    useEffect(() => {
        if (
            isUserAllowed(
                {
                    id: PermissionsEnum.SettingsItems_Google,
                    mode: [Mode.CREATE, Mode.UPDATE],
                    operator: 'OR'
                },
                permissionsList
            )
        ) {
            dispatch(fetchGooglesSettings());
        }

        return () => setAnchorEl(null);
    }, []);

    return schedulePlanId && !isPlanLocked ? (
        <>
            <StyledCardHeaderActionMenu>
                <FieldWrapper type="html" fullWidth={false} isDisplayed sx={{ p: 0, pb: 0, minWidth: 175 }}>
                    <Select
                        options={[
                            { id: 'shifts', label: t('label.shifts', SchedulerModeEnum.Shifts) },
                            ...(userIsAmin
                                ? [
                                      { id: 'requests', label: t('label.requests', SchedulerModeEnum.Requests) },
                                      {
                                          id: 'shiftTrades',
                                          label: t('scheduler.shiftTrades', SchedulerModeEnum.ShiftTrades)
                                      }
                                  ]
                                : []),
                            { id: 'table', label: t('scheduler.table', SchedulerModeEnum.Table) }
                        ]}
                        name="mode"
                        data-testid="selectViewMode"
                        value={type}
                        onChange={(value) => onChangeType((value ?? 'shifts') as AvailableScheduleTypes)}
                    />
                </FieldWrapper>
                <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                    <IconButton
                        title={t('label.menu', 'Menu')}
                        name="schedulePlanMenu"
                        onClick={handleClick}
                        data-testid="schedulerMenu_openButton"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </UserPermission>
            </StyledCardHeaderActionMenu>
            <StyledMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        'overflow': 'visible',
                        'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        'mt': 1.5,
                        '& div': {
                            minWidth: 200,
                            maxWidth: 300
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                data-testid="scheduleMenu_content"
            >
                {schedulePlanId && (
                    <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                        <StyledMenuItem onClick={handleOpenSummarization} data-testid="openSummarization">
                            <ListItemIcon>
                                <FunctionsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('label.summary', 'Summary')}
                                secondary={t('message.info.showUsersSummarization', 'Shows Users Summarization')}
                                secondaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </StyledMenuItem>
                    </UserPermission>
                )}
                {(googleEnabled || (schedulePlanId && !isSchedulePlanClosed && isSystemAdmin)) && <Divider />}
                {googleEnabled && (
                    <UserPermission id={PermissionsEnum.Google} mode={[Mode.CREATE, Mode.UPDATE]}>
                        <StyledMenuItem
                            disabled={!googleEnabled}
                            onClick={handleSyncWithGoogle}
                            data-testid="googleSync"
                        >
                            <ListItemIcon>
                                <GoogleIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('label.googleSync', 'Google Sync')}
                                secondary={t(
                                    'message.info.synchronizeWithCalendar',
                                    'Synchronize with google calendar'
                                )}
                                secondaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </StyledMenuItem>
                    </UserPermission>
                )}
                {schedulePlanId && !isSchedulePlanClosed && (
                    <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.UPDATE]}>
                        <StyledMenuItem onClick={handleOpenModifyNeedsDialog} data-testid="modifyNeeds">
                            <ListItemIcon>
                                <ElevatorIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('label.modifyNeeds', 'Modify Needs')}
                                secondary={t('message.info.increaseOrDecreaseNeeds', 'Increase or Decrease Needs')}
                                secondaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </StyledMenuItem>
                    </UserPermission>
                )}
                {schedulePlanId && !isSchedulePlanClosed && (
                    <UserPermission id={PermissionsEnum.SchedulePlans} mode={Mode.UPDATE}>
                        <StyledMenuItem onClick={handleValidatePlan} data-testid="validate">
                            <ListItemIcon>
                                <PolicyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('label.validate', 'Validate')}
                                secondary={t('message.info.showValidationResults', 'Shows Validation Result')}
                                secondaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </StyledMenuItem>
                    </UserPermission>
                )}
                {isSystemAdmin && schedulePlanId && !isSchedulePlanClosed && (
                    <UserPermission id={PermissionsEnum.SchedulePlans} mode={Mode.UPDATE}>
                        <StyledMenuItem onClick={handleRecountFonds} data-testid="recountFunds">
                            <ListItemIcon>
                                <FoundationOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('label.recountFunds', 'Recount Funds')}
                                secondary={t('message.info.recountFund', 'Recount Funds')}
                                secondaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </StyledMenuItem>
                    </UserPermission>
                )}
                {!isSchedulePlanClosed && (
                    <Box>
                        <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                            <StyledInfoMenuItem
                                onClick={handleOpenConfirmDialogForAIGenerated}
                                data-testid="aiGenerated"
                            >
                                <ListItemIcon>
                                    <AutoFixHighIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.aiGenerated', 'AI-generated')}
                                    secondary={t(
                                        'message.info.employablesGeneratedByAI',
                                        'Employee shifts generated by AI'
                                    )}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledInfoMenuItem>
                        </UserPermission>
                        <Divider color={systemTheme.palette.grey['50']} />
                        <UserPermission id={PermissionsEnum.SchedulePlans} mode={Mode.UPDATE}>
                            <StyledRedMenuItem
                                onClick={handleOpenConfirmDialogForClearShifts}
                                data-testid="clearShifts"
                            >
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.clearShifts', 'Clear Shifts')}
                                    secondary={t('message.info.clearShifts', 'Shifts will be removed')}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledRedMenuItem>
                        </UserPermission>
                        <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                            <StyledRedMenuItem
                                onClick={handleOpenConfirmDialogForRecalculatePlan}
                                data-testid="recalculate"
                            >
                                <ListItemIcon>
                                    <CachedOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.recalculateNeeds', 'Recalculate Needs')}
                                    secondary={t('message.info.recalculateNeeds', 'Shifts will be removed')}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledRedMenuItem>
                        </UserPermission>
                        <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                            <StyledRedMenuItem
                                onClick={handleOpenConfirmDialogForRecalculatePlanWithShifts}
                                data-testid="recalculateWithShifts"
                            >
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.recalculateWithShifts', 'Recalculate with Shifts')}
                                    secondary={t('message.info.recalculateWithShifts', 'Shifts will be reassigned')}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledRedMenuItem>
                        </UserPermission>
                    </Box>
                )}
                {!isSchedulePlanClosed && !isSchedulePlanDraft && (
                    <Box>
                        <Divider color="#000" />
                        <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.UPDATE]}>
                            <StyledMenuItem onClick={handleClosePlan} data-testid="close">
                                <ListItemIcon>
                                    <AutoFixHighIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.closeSchedule', 'Close Schedule')}
                                    secondary={t(
                                        'message.info.closeSchedulePlan',
                                        'The Schedule plan will be set as closed'
                                    )}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledMenuItem>
                        </UserPermission>
                    </Box>
                )}
                {!isSchedulePlanClosed && (
                    <Box>
                        <Divider color="#000000" />
                        <UserPermission
                            id={PermissionsControllerEnum.SchedulePlans}
                            mode={[Mode.CREATE, Mode.UPDATE]}
                            operator="AND"
                        >
                            <StyledMenuItem onClick={handleOpenUploadCsv} data-testid="uploadCsv">
                                <ListItemIcon>
                                    <FileUploadIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={t('label.uploadCsv', 'Upload CSV')}
                                    secondary={t(
                                        'message.info.uploadSchedulePlanDaySkillCsvFile',
                                        'Creates schedule plan day skills from csv'
                                    )}
                                    secondaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                />
                            </StyledMenuItem>
                        </UserPermission>
                    </Box>
                )}
                <Divider color="#000000" />
                <StyledMenuItem data-testid="display-skills">
                    <ListItemIcon>
                        <Switch
                            name="display_skills"
                            label={t('label.displaySkills', 'Display Skills')}
                            value={gridSettings.displaySkills}
                            onChange={handleToggleDisplaySkills}
                        />
                    </ListItemIcon>
                </StyledMenuItem>
                <StyledMenuItem data-testid="displayUsedHoursOfUser">
                    <ListItemIcon>
                        <Switch
                            name="displayUsedHoursOfUser"
                            label={t('label.displayUsedHoursOfUser', 'Display Used Hours of User')}
                            value={gridSettings.displayUsedHours}
                            onChange={handleToggleDisplayUsedHoursOfUser}
                        />
                    </ListItemIcon>
                </StyledMenuItem>
            </StyledMenu>
            <UserPermission id={PermissionsEnum.SchedulePlans} mode={[Mode.CREATE, Mode.UPDATE]}>
                <CloseSchedulePlanDialog
                    open={openCloseScheduleDialog}
                    setOpen={setOpenCloseScheduleDialog}
                    schedulePlanId={schedulePlanId}
                    scheduleName={scheduleName}
                    isPlanLocked={isPlanLocked}
                    onClose={handleCloseConfirmDialogForClosePlan}
                />
                <ModifyNeedsDialog
                    from={from}
                    isPlanLocked={isPlanLocked}
                    open={openModifyNeedsDialog}
                    setOpen={handleOpenModifyNeedsDialog}
                    schedulePlanId={schedulePlanId}
                    to={to}
                    onClose={handleCloseModifyNeedsDialog}
                />
                <RecalculateDialog
                    open={openRecalculateScheduleDialog}
                    setOpen={setOpenRecalculateScheduleDialog}
                    schedulePlanId={schedulePlanId}
                    scheduleName={scheduleName}
                    isPlanLocked={isPlanLocked}
                    onClose={handleCloseConfirmDialogForRecalculatePlan}
                />
                <RecalculateWithShiftsDialog
                    open={openRecalculateWithShiftsScheduleDialog}
                    setOpen={setOpenRecalculateWithShiftsScheduleDialog}
                    schedulePlanId={schedulePlanId}
                    scheduleName={scheduleName}
                    isPlanLocked={isPlanLocked}
                    onClose={handleCloseConfirmDialogForRecalculatePlanWithShifts}
                />
                <ClearShiftsDialog
                    open={openClearShiftsDialog}
                    setOpen={setOpenClearShiftsDialog}
                    schedulePlanId={schedulePlanId}
                    scheduleName={scheduleName}
                    isPlanLocked={isPlanLocked}
                />
                <ConfirmDialog
                    title={t(
                        'message.question.doYouReallyWantToGenerateSchedulePlanByAI',
                        'Do you really want to generate Schedule Plan {{entity}} by AI?',
                        { entity: scheduleName ?? '' }
                    )}
                    onAgree={handleCalculatePlan}
                    onClose={handleCloseConfirmDialogForAIGenerated}
                    onReject={handleCloseConfirmDialogForAIGenerated}
                    open={openAIGeneratedDialog}
                />
                <SummarizationDialog
                    open={openSummarizationDialog}
                    onClose={handleCloseSummarization}
                    scheduleName={scheduleName}
                    hasFilter={false}
                    workplaceId={schedulePlanData ? schedulePlanData.workplace_id : undefined}
                    periodId={schedulePlanData ? schedulePlanData?.period_id : undefined}
                />
                <UploadSchedulePlanDaySkillsCsvDialog
                    open={openUploadCsv}
                    setOpen={setOpenUploadCsv}
                    scheduleName={scheduleName}
                    schedulePlanId={schedulePlanId}
                    isPlanLocked={isPlanLocked}
                />
            </UserPermission>
        </>
    ) : (
        <></>
    );
};

export default SchedulerHeaderMenu;
