import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById, fetchSchedulePlans } from '@/data/SchedulePlans/SchedulePlanActions';
import { IShiftModel } from '@/data/Shifts/ShiftModels';
import { shiftEntities } from '@/data/Shifts/ShiftSlice';
import { IShiftToWorkplaceModel } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceModels';
import { shiftToWorkplaceAll } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceSlice';
import { fetchUserById, fetchUsers } from '@/data/Users/UserActions';
import { IUserExtendedModel } from '@/data/Users/UserModels';
import { userEntities, usersForSelect } from '@/data/Users/UserSlice';
import { selectUserToContracts } from '@/data/UserToContracts/UserToContractSlice';
import { requestEntities } from '@/data/UserToRequests/UserToRequestSlice';
import { userToVacationAll } from '@/data/UserToVacations/UserToVacationSlice';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import { userToWorkplaceAll } from '@/data/UserToWorkplaces/UserToWorkplaceSlice';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createWorkplace,
    fetchWorkplaceById,
    fetchWorkplaces,
    fetchWorkplacesForSelect,
    fetchWorkplacesRecordList,
    removeWorkplace,
    updateWorkplace
} from './WorkplaceActions';
import { IWorkplaceModel, IWorkplaceRecordModel, IWorkplaceSelectModel } from './WorkplaceModels';

type IState = {
    recordsList: IWorkplaceRecordModel[];
    selectItems: IWorkplaceSelectModel[];
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    recordsListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IState = {
    recordsList: [],
    selectItems: [],
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    recordsListStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<IWorkplaceModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
});
const workplaceSlice = createSlice({
    name: 'workplaces',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const { user_to_workplaces, shift_to_workplaces, workplace_working_hours, ...workplace } =
                    action.payload.workplace;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.upsertOne(state, workplace);
            })
            .addCase(fetchSchedulePlans.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data.map(({ workplace }) => workplace)
                );
            });
        builder
            .addCase(fetchWorkplaces.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchWorkplaces.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(
                    state,
                    action.payload.data.map(({ user_to_workplaces, shift_to_workplaces, ...item }) => item)
                );
                state.paging = action.payload.collection;
            })
            .addCase(fetchWorkplaces.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchWorkplacesRecordList.pending, (state) => {
                state.recordsListStatus = 'loading';
            })
            .addCase(fetchWorkplacesRecordList.fulfilled, (state, action) => {
                state.recordsListStatus = 'idle';
                state.recordsList = action.payload.data;
            })
            .addCase(fetchWorkplacesRecordList.rejected, (state) => {
                state.recordsListStatus = 'failed';
            })
            .addCase(fetchWorkplacesForSelect.pending, (state) => {
                state.loadingForSelectStatus = 'loading';
            })
            .addCase(fetchWorkplacesForSelect.fulfilled, (state, action) => {
                state.loadingForSelectStatus = 'idle';
                state.selectItems = action.payload.data;
            })
            .addCase(fetchWorkplacesForSelect.rejected, (state) => {
                state.loadingForSelectStatus = 'failed';
            })
            .addCase(fetchWorkplaceById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchWorkplaceById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchWorkplaceById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createWorkplace.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createWorkplace.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
                if (state.selectItems.length) {
                    state.selectItems.push({
                        id: action.payload.id,
                        name: action.payload.name
                    } as IWorkplaceSelectModel);
                }
            })
            .addCase(createWorkplace.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeWorkplace.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeWorkplace.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.filter((item) => item.id !== action.meta.arg.id);
                }
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.user_to_workplaces.map(({ workplace }) => workplace)
                );
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const workplacesList: IWorkplaceModel[] = [];

                action.payload.data.forEach((user) => {
                    user.user_to_workplaces?.forEach(({ workplace }) => {
                        if (!workplacesList.some((workplaceListItem) => workplaceListItem.id === workplace.id)) {
                            workplacesList.push(workplace);
                        }
                    });
                });

                adapter.upsertMany(state, workplacesList);
            })
            .addCase(removeWorkplace.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateWorkplace.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateWorkplace.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
                if (state.selectItems.length) {
                    state.selectItems = state.selectItems.map((item) =>
                        item.id === action.payload.id
                            ? {
                                  id: action.payload.id,
                                  name: action.payload.name
                              }
                            : item
                    );
                }
            })
            .addCase(updateWorkplace.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[workplaceSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default workplaceSlice;
export const workplaceEntities = adapterSelectors.selectEntities;

export const isWorkplaceListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const isWorkplaceByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const isWorkplacesForSelectInProgress = (state: IRootState) =>
    getState(state).loadingForSelectStatus === 'loading';
export const workplacePaging = (state: IRootState) => getState(state).paging;
export const workplaceList = adapterSelectors.selectAll;
export const workplaceRecordList = (state: IRootState) => {
    const result = getState(state).recordsList;

    return result.length > 0 ? result : null;
};

export const workplaceById = (state: IRootState, id: number | null) =>
    id ? adapterSelectors.selectById(state, id) ?? null : null;
export const workplacesForSelect = (state: IRootState) => getState(state).selectItems;
export const workplaceSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const workplaceCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const workplaceRecordListStatus = (state: IRootState) => getState(state).recordsListStatus;
export const workplaceUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const workplaceRemovingStatus = (state: IRootState) => getState(state).removingStatus;

export const { updatePaging } = workplaceSlice.actions;

export const selectWorkplaces = createSelector(
    adapterSelectors.selectAll,
    (state: IRootState) => selectUserToContracts(state),
    (state: IRootState) => userToVacationAll(state),
    (state: IRootState) => userToWorkplaceAll(state),
    (state: IRootState) => shiftToWorkplaceAll(state),
    (state: IRootState) => userEntities(state),
    (state: IRootState) => shiftEntities(state),
    (state: IRootState) => requestEntities(state),
    (
        workplaces,
        userToContracts,
        userToVacations,
        userToWorkplaces,
        shiftToWorkplaces,
        users,
        shifts,
        userToRequestList
    ) => {
        const userToWorkplacesExtended: (IUserToWorkplaceModel & {
            user: Omit<IUserExtendedModel, 'user_to_workplaces'>;
        })[] = [];
        const shiftToWorkplacesExtended: (IShiftToWorkplaceModel & { shift: IShiftModel })[] = [];
        const result: Record<number, IWorkplaceRecordModel> = {};

        userToWorkplaces.forEach((item) => {
            const user = users[item.user_id];

            if (user) {
                userToWorkplacesExtended.push({
                    ...item,
                    user: {
                        ...user,
                        user_to_contracts: userToContracts.filter(
                            (userToContract) => userToContract.user_id === user.id
                        ),
                        user_to_vacations: userToVacations.filter(
                            (userToVacation) => userToVacation.user_id === user.id
                        ),
                        user_to_requests: userToRequestList.filter((userToRequest) => userToRequest.user_id === user.id)
                    }
                });
            }
        });
        shiftToWorkplaces.forEach((item) => {
            const shift = shifts[item.shift_id];

            if (shift) {
                shiftToWorkplacesExtended.push({
                    ...item,
                    shift
                });
            }
        });

        workplaces.forEach((workplace) => {
            result[workplace.id] = {
                ...workplace,
                shift_to_workplaces: shiftToWorkplacesExtended.filter((item) => item.workplace_id === workplace.id),
                user_to_workplaces: userToWorkplacesExtended.filter((item) => item.workplace_id === workplace.id)
            };
        });

        return result;
    }
);
export const selectWorkplaceList = createSelector(
    (state: IRootState) => adapterSelectors.selectAll(state),
    (state: IRootState) => userToWorkplaceAll(state),
    (state: IRootState) => usersForSelect(state),
    (entities, userToWorkplaces, users) => {
        const admins = userToWorkplaces.filter((item) => item.type === 'admin');

        return entities.map((workplace) => {
            const administrators = admins.filter((item) => item.workplace_id === workplace.id);

            return {
                ...workplace,
                administrators: users.filter((item) =>
                    administrators.some((administrator) => administrator.user_id === item.id)
                ),
                user_to_workplaces: userToWorkplaces.filter((item) => item.workplace_id === workplace.id)
            };
        });
    }
);

export const selectWorkplaceById = (state: IRootState, id: number | null) =>
    id ? selectWorkplaces(state)[id] ?? null : null;
