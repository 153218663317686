import { breakList } from '@/data/Breaks/BreakSlice';
import { useAppSelector } from '@/data/hooks';
import { isSignedUserUser } from '@/data/System/SystemReducer';
import { IUserCUBreaks } from '@/data/Users/UserModels';
import useAppTranslation from '@/hooks/useAppTranslation';
import PeriodViewEnum from '@/utils/enums/PeriodViewEnum';
import CrudInlineOperationDatatable from '@/wrappers/CrudInlineDatatable';

type IProps = {
    data: IUserCUBreaks[];
    handleData: (value: IUserCUBreaks[]) => void;
};

const BreaksTab = ({ data, handleData }: IProps) => {
    const { t } = useAppTranslation();
    const breaks = useAppSelector(breakList);
    const isSystemUser = useAppSelector(isSignedUserUser);

    return (
        <CrudInlineOperationDatatable<IUserCUBreaks>
            labelAddButton={t('label.addBreakSubsidy', 'Add Break Subsidy')}
            header={[
                {
                    field: 'break_id',
                    title: t('header.breakName', 'Break Name'),
                    options: breaks,
                    validate: (rowData) => (rowData.break_id ? true : { isValid: false })
                },
                {
                    field: 'period',
                    title: t('header.period', 'Period'),
                    options: [
                        { id: PeriodViewEnum.week, name: t(`label.${PeriodViewEnum.week.toLowerCase()}`, 'Week') },
                        { id: PeriodViewEnum.month, name: t(`label.${PeriodViewEnum.month.toLowerCase()}`, 'Month') },
                        { id: PeriodViewEnum.day, name: t(`label.${PeriodViewEnum.day.toLowerCase()}`, 'Day') }
                    ],
                    settings: {
                        unfiltered: true
                    }
                },
                {
                    field: 'subsidy',
                    title: t('header.subsidy', 'Subsidy /h'),
                    type: 'numeric',
                    inputProps: {
                        inputProps: {
                            step: 0.25
                        },
                        endAdornment: t('label.hourShort', 'h')
                    },
                    validate: (rowData) =>
                        rowData.subsidy
                            ? true
                            : { isValid: false, helperText: t('message.info.itCannotBeEmpty', 'It cannot be empty') },
                    render: (rowData) => `${rowData.subsidy}h`
                }
            ]}
            data={data}
            onRowAdd={
                isSystemUser
                    ? undefined
                    : async (newData) => {
                          handleData([...data, newData]);
                      }
            }
            onRowRemove={
                isSystemUser
                    ? undefined
                    : async (oldData) => handleData(data.filter((row) => row.break_id !== oldData?.break_id))
            }
            onRowUpdate={
                isSystemUser
                    ? undefined
                    : async (newData, oldData) =>
                          handleData(data.map((item) => (item.break_id === oldData?.break_id ? newData : item)))
            }
        />
    );
};

export default BreaksTab;
