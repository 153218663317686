import Divider from '@mui/material/Divider';
import { lighten, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { IInnerValuesType } from '@/base/FormGenerator/utils';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { fetchBreaksForSelect } from '@/data/Breaks/BreakActions';
import { breaksForSelect } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { createShift, updateShift } from '@/data/Shifts/ShiftActions';
import { IShiftItemCUModel, IShiftModel } from '@/data/Shifts/ShiftModels';
import {
    shiftById,
    shiftCreatingStatus,
    shiftPaging,
    shiftUpdatingStatus,
    updatePaging
} from '@/data/Shifts/ShiftSlice';
import handleSort from '@/helpers/array/sort';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';

type IProps = Omit<ICuProps<IShiftModel>, 'resource'>;
type IBreakItem = {
    break_id: number;
    break_time: {
        date: {
            start: Date;
            duration: number;
        };
        isRange: boolean;
    };
};

const StyledGroupHeader = styled('div')(
    ({ theme }) => `
        position: sticky;
        top: -8px;
        padding: 4px 10px;
        color: ${theme.palette.primary.main};
        background-color: ${lighten(theme.palette.primary.light, 0.75)}
    `
);
const StyledGroupItems = styled('ul')`
    padding: 0;
`;

const ShiftForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => shiftById(state, id));
    const breaks = useAppSelector(breaksForSelect);
    const paging = useAppSelector(shiftPaging);
    const handleData = (values: IOutputValueType) => ({
        abbreviation: (values.abbreviation as IAbbreviationValue).text as string,
        active: values.active as boolean,
        allow_overlapping_shifts: values.allow_overlapping_shifts as boolean,
        background: (values.abbreviation as IAbbreviationValue).background as string,
        color: (values.abbreviation as IAbbreviationValue).color as string,
        days: values.days as number,
        description: values.description as string,
        duration: (values?.time as ITimeRangeOrTimeAndLengthValueType)?.duration ?? 0,
        holiday: values.holiday as boolean,
        name: values.name as string,
        number_of_system_break_5: values.number_of_system_break_5 as number,
        number_of_system_break_10: values.number_of_system_break_10 as number,
        on_call: values.on_call as boolean,
        range_enabled: !!(values?.time as ITimeRangeOrTimeAndLengthValueType)?.isRange,
        shift_items: (values.shift_items as IInnerValuesType[])
            .filter((item) => Number.isInteger(parseInt(`${item.break_id}`)))
            .map(({ id: shiftItemId, break_time, break_id }) => ({
                id: shiftItemId,
                break_id,
                start: DateHelper.formatTime((break_time as ITimeRangeOrTimeAndLengthValueType)?.start),
                duration: (break_time as ITimeRangeOrTimeAndLengthValueType)?.duration ?? 0
            })) as IShiftItemCUModel[],
        time_start: DateHelper.formatTime((values.time as ITimeRangeOrTimeAndLengthValueType)?.start),
        valid_start: DateHelper.formatDate((values.valid as IDateRangePickerProps['value'])?.start),
        valid_end: DateHelper.formatDate((values.valid as IDateRangePickerProps['value'])?.end)
    });
    const handleCreate = useCallback((values: IOutputValueType) => createShift(handleData(values)), []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => {
            return updateShift({ id: id!, data: handleData(values) });
        },
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);
    const handleOpen = useCallback(() => {
        dispatch(fetchBreaksForSelect({ search: '' }));
    }, []);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const sortCallback = useCallback((inputs: any[]) => {
        (inputs as IBreakItem[]).sort((prev, next) => {
            if (prev.break_time.date.start > next.break_time.date.start) {
                return 1;
            } else {
                return -1;
            }
        });
    }, []);

    return (
        <CuForm
            {...props}
            id={id}
            name="shift"
            resource={PermissionsEnum.Shifts}
            maxWidth="lg"
            creatingStatus={useAppSelector(shiftCreatingStatus)}
            updatingStatus={useAppSelector(shiftUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'active',
                        label: t('label.active', 'Active'),
                        value: loadedData?.active ?? true,
                        width: 3
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'holiday',
                        label: t('label.holiday', 'Holiday'),
                        value: loadedData?.holiday,
                        width: 3
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'on_call',
                        label: t('label.onCall', 'On Call'),
                        value: loadedData?.on_call,
                        width: 3
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'allow_overlapping_shifts',
                        label: t('label.allowOverlappingShifts', 'Allow shifts to overlap'),
                        value: loadedData?.allow_overlapping_shifts,
                        width: 3
                    }
                },
                {
                    type: 'weekdays',
                    props: {
                        name: 'days',
                        multiple: true,
                        value: loadedData?.days,
                        width: 12,
                        sx: { alignItems: 'center' }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.shiftTypeName', 'Shift Type Name'),
                        value: loadedData?.name,
                        width: 12,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'abbreviation',
                    props: {
                        required: true,
                        name: 'abbreviation',
                        label: {
                            text: t('label.abbreviation', 'Abbreviation'),
                            color: t('label.color', 'Color'),
                            background: t('label.background', 'Background')
                        },
                        value: loadedData
                            ? {
                                  text: loadedData.abbreviation,
                                  color: loadedData.color,
                                  background: loadedData.background
                              }
                            : undefined,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'description',
                        label: t('label.description', 'Description'),
                        value: loadedData?.description,
                        width: 12,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'timeRangeOrTimeAndLength',
                    props: {
                        required: true,
                        name: 'time',
                        label: {
                            start: t('label.shiftStart', 'Shift Start'),
                            end: t('label.shiftEnd', 'Shift End'),
                            length: t('label.length', 'Length'),
                            range: t('label.range', 'Range')
                        },
                        value: {
                            start: DateHelper.fromOptionalTime(loadedData?.time_start),
                            duration: loadedData?.duration ?? null,
                            isRange: true
                        },
                        minutesStep: 15,
                        width: 12
                    }
                },
                {
                    type: 'dateRange',
                    props: {
                        name: 'valid',
                        label: {
                            start: t('label.validFrom', 'Valid From'),
                            end: t('label.validTo', 'Valid To')
                        },
                        value: {
                            start: DateHelper.fromOptionalDateString(loadedData?.valid_start ?? null),
                            end: DateHelper.fromOptionalDateString(loadedData?.valid_end ?? null)
                        },
                        width: 12
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'systemBreaksTitle',
                        render: () => (
                            <>
                                <Typography gutterBottom variant="body1">
                                    <strong>{t('title.systemBreaks', 'System Breaks')}</strong>
                                    {' - '}
                                    <Typography color="text.secondary" component="span">
                                        {t('message.info.setCountOfTheSystemBreaks', 'Set count of The System Breaks')}
                                    </Typography>
                                </Typography>
                                <Divider />
                            </>
                        )
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'number_of_system_break_5',
                        label: t('label.countOf5MinuteBreaks', 'Count of 5 minute Breaks'),
                        type: 'number',
                        value: loadedData?.number_of_system_break_5 ?? '0',
                        width: 6,
                        InputProps: {
                            inputProps: {
                                min: 0
                            }
                        },
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'number_of_system_break_10',
                        label: t('label.countOf10MinuteBreaks', 'Count of 10 minute Breaks'),
                        type: 'number',
                        value: loadedData?.number_of_system_break_10 ?? '0',
                        width: 6,
                        InputProps: {
                            inputProps: {
                                min: 0
                            }
                        },
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'breaks-title',
                        render: () => (
                            <>
                                <Typography gutterBottom variant="body1">
                                    <strong>{t('title.breaks', 'Breaks')}</strong>
                                    {' - '}
                                    <Typography color="text.secondary" component="span">
                                        {t(
                                            'message.info.selectTheTypeOfBreakAndTheTimeForWhichItIsAvailableInSchedule',
                                            'Select the type of break and the time for which it is available in schedule'
                                        )}
                                    </Typography>
                                </Typography>
                                <Divider />
                            </>
                        )
                    }
                },
                {
                    type: 'multiRowInputs',
                    sort: sortCallback,
                    props: {
                        name: 'shift_items',
                        value: loadedData?.shift_items?.map((item) => ({
                            break_id: `${item.break_id}`,
                            id: item.id,
                            break_time: {
                                start: DateHelper.fromOptionalTime(item.start),
                                duration: item.duration ?? 0,
                                isRange: true
                            }
                        })),
                        inputs: [
                            {
                                type: 'select',
                                props: {
                                    name: 'break_id',
                                    label: t('label.breakType', 'Break Type'),
                                    options: [...breaks]
                                        .sort((prev, next) => handleSort(next.required, prev.required))
                                        .map((item) => ({
                                            id: `${item.id}`,
                                            label: item.name,
                                            custom: { required: item.required }
                                        })),
                                    groupBy: (option) => (option.custom?.required ? 'Mandatory' : 'Optional'),
                                    renderGroup: (params) => (
                                        <li key={params.key}>
                                            <StyledGroupHeader>{params.group}</StyledGroupHeader>
                                            <StyledGroupItems>{params.children}</StyledGroupItems>
                                        </li>
                                    ),
                                    width: 4
                                }
                            },
                            {
                                type: 'timeRangeOrTimeAndLength',
                                props: {
                                    name: 'break_time',
                                    label: {
                                        start: t('label.start', 'Start'),
                                        end: t('label.end', 'End'),
                                        length: t('label.length', 'Length'),
                                        range: t('label.range', 'Range')
                                    },
                                    minStart: (values) =>
                                        (values.time as ITimeRangeOrTimeAndLengthValueType | undefined)?.start,
                                    maxDuration: (values) =>
                                        (values.time as ITimeRangeOrTimeAndLengthValueType | undefined)?.duration ?? 0,
                                    minutesStep: 15,
                                    width: 6,
                                    validation: {
                                        deps: ['start', 'duration']
                                    }
                                }
                            }
                        ]
                    }
                }
            ]}
            onOpen={handleOpen}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default ShiftForm;
