import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSchedulePlanById } from '@/data/SchedulePlans/SchedulePlanActions';
import { fetchUserById } from '@/data/Users/UserActions';
import unique from '@/helpers/array/unique';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import {
    createUserToRequest,
    fetchRecalculateFunds,
    fetchUserToRequestById,
    fetchUserToRequests,
    fetchUserToRequestsForScheduler,
    fetchUserToRequestsForSelect,
    patchStateToRequest,
    removeUserToRequest,
    updateUserToRequest
} from './UserToRequestActions';
import { ISchedulerUserToRequestFromBeModel, IUserToRequestModel } from './UserToRequestModels';

type IState = {
    paging: IPaging;
    loadingForSelectStatus: IRequestState;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: IState = {
    paging: defaultPaging('name'),
    loadingForSelectStatus: 'idle',
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<ISchedulerUserToRequestFromBeModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created_at.localeCompare(b.created_at)
});

const userToRequestSlice = createSlice({
    name: 'userToRequests',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        },
        changeStateToRequest(
            state,
            action: PayloadAction<{ requestId: number; state: UserToRequestsStateEnum; message?: string }>
        ) {
            adapter.updateOne(state, {
                id: action.payload.requestId,
                changes: { state: action.payload.state, message: action.payload.message }
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserToRequests.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchUserToRequests.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchUserToRequests.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchUserToRequestsForSelect.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchUserToRequestsForSelect.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
            })
            .addCase(fetchUserToRequestsForSelect.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchUserToRequestsForScheduler.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchUserToRequestsForScheduler.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.upsertMany(state, action.payload.data);
            })
            .addCase(fetchUserToRequestsForScheduler.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(patchStateToRequest.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                if (action.payload.request && action.payload.request.schedule_plan_day_shift) {
                    adapter.removeMany(
                        state,
                        (
                            Object.values(state.entities).filter(
                                (item) =>
                                    item?.schedule_plan_day_shift?.id ===
                                        action.payload.request?.schedule_plan_day_shift?.id &&
                                    !action.payload.request?.schedule_plan_day_shift?.schedule_plan_day_shift_requests?.some(
                                        (shiftRequests: IUserToRequestModel) => shiftRequests.id === item?.id
                                    )
                            ) as ISchedulerUserToRequestFromBeModel[]
                        ).map(({ id }) => id)
                    );
                } else if (!action.payload.request && action.meta.arg.value === UserToRequestsStateEnum.REJECTED) {
                    adapter.removeOne(state, action.meta.arg.id);
                }

                if (action.payload.request) {
                    adapter.upsertOne(state, action.payload.request);
                }
            })
            .addCase(patchStateToRequest.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchUserToRequestById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchUserToRequestById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchUserToRequestById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createUserToRequest.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createUserToRequest.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createUserToRequest.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(fetchRecalculateFunds.fulfilled, (state, action) => {
                adapter.upsertOne(state, action.payload);
            })
            .addCase(removeUserToRequest.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeUserToRequest.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    unique(
                        action.payload.users.flatMap((user) => user.user_to_requests),
                        (item) => item.id
                    )
                );
            })
            .addCase(removeUserToRequest.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateUserToRequest.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateUserToRequest.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateUserToRequest.rejected, (state) => {
                state.updatingStatus = 'failed';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                adapter.upsertMany(state, action.payload.user_to_requests);
            });
    }
});

const getState = (state: IRootState) => state[userToRequestSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const requestEntities = adapterSelectors.selectAll;

export default userToRequestSlice;
export const isUserToRequestsListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const requestPaging = (state: IRootState) => getState(state).paging;
export const isUserToRequestsByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const userToRequestAll = adapterSelectors.selectAll;
export const requestForSchedulerList = (state: IRootState) => adapterSelectors.selectAll(state);
export const requestForSchedulerListByUserId = (state: IRootState, userId: number | null) =>
    userId ? requestForSchedulerList(state).filter((request) => request.user_id === userId) : null;
export const requestById = (state: IRootState, id: number | null) =>
    id ? adapterSelectors.selectById(state, id) ?? null : null;
export const requestSelectValuesStatus = (state: IRootState) => getState(state).loadingForSelectStatus;
export const userToRequestCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const userToRequestUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const requestRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging, changeStateToRequest } = userToRequestSlice.actions;
