import { FileUpload, ICallable } from '@connect-soft/react-file-upload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import { lighten, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useRef, useState } from 'react';
import FormGenerator from '@/base/FormGenerator';
import { useAppDispatch } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { fetchSchedulePlansForSelect } from '@/data/SchedulePlans/SchedulePlanActions';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import SchedulePlanUploadTypeEnum from '@/utils/enums/SchedulePlanUploadTypeEnum';
import Dialog from '@/wrappers/Dialog';

const StyledTitle = styled(Box)`
    text-align: center;
`;

const StyledBottomNavigation = styled(BottomNavigation)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

type IProps = {
    open: boolean;
    onDialogClose: () => void;
};

const StyledInputField = styled(TextField)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: '5px',
    ['input::file-selector-button']: {
        border: 'none',
        background: theme.palette.primary.main,
        marginRight: '20px',
        marginTop: '-10px',
        borderRadius: '10px',
        color: lighten(theme.palette.primary.main, 0.75),
        padding: '10px 20px',
        cursor: 'pointer'
    }
}));

const SchedulePlanUploadDialog = ({ open, onDialogClose }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const [type, setType] = useState<SchedulePlanUploadTypeEnum | null>(null);
    const [inProgress, setInProgress] = useState(false);
    const token = localStorage.getItem('token');
    const fileRef = useRef<ICallable>(null);

    const handleClose = useCallback(() => {
        setType(null);
        onDialogClose();
    }, [onDialogClose]);

    useEffect(() => {
        if (open) {
            dispatch(fetchWorkplacesForSelect({ search: '' }));
            dispatch(fetchSchedulePlansForSelect({ search: '' }));
        }
    }, [open]);

    const handleSubmit = useCallback(() => {
        fileRef?.current?.sendFiles();
        setInProgress(true);
    }, [fileRef.current]);

    const handleOnUploaded = useCallback((status: 'success' | 'error') => {
        if (status === 'success') {
            dispatch(
                addNotification({
                    variant: 'success',
                    context: 'message.success.fileUploaded',
                    defaultMessage: 'The file was successfully uploaded'
                })
            );
        } else {
            dispatch(
                addNotification({
                    variant: 'error',
                    context: 'message.error.somethingWentWrong',
                    defaultMessage: 'Something went wrong'
                })
            );
        }

        setInProgress(false);
        handleClose();
    }, []);

    return (
        <Dialog
            name="schedulePlanDownloadDialog"
            openButtonValue={undefined}
            openButton={undefined}
            title={<StyledTitle>{t('label.downloadSchedulePlan', 'Download Schedule Plan')}</StyledTitle>}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <StyledBottomNavigation
                showLabels
                value={type}
                onChange={(event, value) => {
                    setType(value);
                }}
                data-testid="summarizationDialogTypeNavigation"
            >
                <BottomNavigationAction
                    label={t('label.megalax', 'Megalax')}
                    value={SchedulePlanUploadTypeEnum.megalax}
                    icon={<FileDownloadOutlinedIcon />}
                />
            </StyledBottomNavigation>

            <FormGenerator
                name="userDownloadForm"
                displayAsModal={false}
                displayAsSidebar={false}
                onSubmit={handleSubmit}
                fields={[
                    {
                        type: 'custom',
                        display: () => type !== null,
                        props: {
                            name: 'file',
                            values: {},
                            render: (args) => {
                                const data = args.getValues();
                                const yearAndMonthDateTime = data.yearAndMonth as DateTimeType | undefined;
                                const month =
                                    typeof yearAndMonthDateTime?.month() === 'number'
                                        ? `${yearAndMonthDateTime.month() + 1}`
                                        : '';
                                const year = `${yearAndMonthDateTime?.year() ?? ''}`;

                                return (
                                    <FileUpload
                                        apiPath={'/api/reports/upload/funds-file'}
                                        slots={{
                                            input: (
                                                <StyledInputField
                                                    type="file"
                                                    inputProps={{
                                                        multiple: true
                                                    }}
                                                />
                                            )
                                        }}
                                        requestHeaders={{
                                            JWT: `Bearer ${token}`
                                        }}
                                        additionalData={{
                                            month: month,
                                            year: year
                                        }}
                                        withButton={false}
                                        innerRef={fileRef}
                                        onFinished={handleOnUploaded}
                                    />
                                );
                            }
                        }
                    },
                    // {
                    //     type: 'select',
                    //     display: () => type === SchedulePlanUploadTypeEnum.megalax,
                    //     props: {
                    //         name: 'month',
                    //         required: true,
                    //         label: t('label.month', 'Month'),
                    //         options: getMonthNames(i18n.language ?? defaultLng).map((month, index) => {
                    //             const monthId = index + 1;
                    //
                    //             return {
                    //                 id: `${monthId}`,
                    //                 label: month
                    //             };
                    //         }),
                    //         validation: {
                    //             deps: ['month']
                    //         },
                    //         width: 6
                    //     }
                    // },
                    // {
                    //     type: 'date',
                    //     display: () => type === SchedulePlanUploadTypeEnum.megalax,
                    //     props: {
                    //         name: 'yearAndMonth',
                    //         label: t('label.year', 'Year'),
                    //         required: true,
                    //         value: DateHelper.now(),
                    //         openTo: 'year',
                    //         views: ['year'],
                    //         minDate: DateHelper.now().subtract(5, 'year'),
                    //         maxDate: DateHelper.now(),
                    //         width: 6
                    //     }
                    // },
                    {
                        type: 'date',
                        display: () => type === SchedulePlanUploadTypeEnum.megalax,
                        props: {
                            name: 'yearAndMonth',
                            label: t('label.monthAndYear', 'Month And Year'),
                            required: true,
                            value: DateHelper.now().subtract(1, 'month'),
                            openTo: 'month',
                            views: ['year', 'month'],
                            minDate: DateHelper.now().subtract(5, 'year'),
                            maxDate: DateHelper.now().subtract(1, 'month')
                        }
                    }
                ]}
                actions={[
                    {
                        type: 'button',
                        props: {
                            type: 'button',
                            name: 'cancel',
                            variant: 'text',
                            onClick: handleClose,
                            children: t('label.cancel', 'Cancel'),
                            width: 6
                        }
                    },
                    {
                        type: 'loadingButton',
                        display: ({ yearAndMonth }) => {
                            return !!yearAndMonth;
                        },
                        props: {
                            type: 'submit',
                            name: 'upload',
                            variant: 'contained',
                            children: t('label.upload', 'Upload'),
                            width: 6,
                            loading: inProgress,
                            validation: {
                                deps: ['yearAndMonth']
                            }
                        }
                    }
                ]}
            />
        </Dialog>
    );
};

export default SchedulePlanUploadDialog;
