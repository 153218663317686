// TODO napojit na prepinani jazyku
import { defaultLng } from '@/utils/i18next';

const getMonthNames = (
    locale = defaultLng,
    format: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined = 'long'
) => {
    const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' });
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
        const mm = month < 10 ? `0${month}` : month;

        return new Date(`2017-${mm}-01T00:00:00+00:00`);
    });

    return months.map((date) => formatter.format(date));
};

export default getMonthNames;
