import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { WithRequiredProp } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { useCallback, useEffect, useRef } from 'react';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { ICallableRef, ISupportedValueType } from '@/base/FormGenerator';
import CuForm, { ICuProps } from '@/components/CuForm';
import { contractList } from '@/data/Contracts/ContractSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { IUserCUContract } from '@/data/Users/UserModels';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';

export type IUserToContractFormDataType = IUserCUContract & { isNew: boolean };

type IProps = {
    props: Omit<ICuProps<IUserToContractFormDataType, number | string>, 'resource'>;
    data?: IUserToContractFormDataType;
    onDataChanged: (value: WithRequiredProp<IUserToContractFormDataType, 'id'>) => void;
    onRemove: (id: number | string) => void;
};

const UserToContractForm = ({
    data,
    props: { id, justIcon, displayAsModal, displayAsSidebar, ...props },
    onDataChanged,
    onRemove
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const contractsListData = useAppSelector(contractList);
    const shifts = useAppSelector(shiftsForSelect);
    const formRef = useRef<ICallableRef | null>(null);

    const handleCreate = useCallback(
        (values: ISupportedValueType) => {
            const period = values.period as IDateRangePickerProps['value'];

            onDataChanged({
                ...values,
                id: generateUniqueID(),
                contract_id: values.contract_id ? parseInt(values.contract_id.toString()) : undefined,
                start_at: period?.start ? DateHelper.formatDate(period.start) : null,
                end_at: period?.end ? DateHelper.formatDate(period.end) : null,
                isNew: true
            } as WithRequiredProp<IUserToContractFormDataType, 'id'>);
        },
        [id, onDataChanged]
    );

    const handleUpdate = useCallback(
        (values: ISupportedValueType) => {
            const period = values.period as IDateRangePickerProps['value'];

            onDataChanged({
                ...values,
                id,
                contract_id: values.contract_id ? parseInt(values.contract_id.toString()) : undefined,
                start_at: period?.start ? DateHelper.formatDate(period.start) : null,
                end_at: period?.end ? DateHelper.formatDate(period.end) : null,
                isNew: data?.isNew ?? false
            } as WithRequiredProp<IUserToContractFormDataType, 'id'>);
        },
        [id, onDataChanged]
    );
    const handleRemove = useCallback(() => id && onRemove(id), [id, onRemove]);

    const handleCopyFromContract = useCallback(() => {
        const contractId = formRef.current?.getFieldValue('contract_id');
        const contractData = contractsListData.find((item) => item.id == contractId);

        if (contractData) {
            formRef.current?.setFieldValue('week_hours_limit', contractData?.week_hours_limit);
            formRef.current?.setFieldValue('number_of_hours_per_day', contractData?.number_of_hours_per_day);
            formRef.current?.setFieldValue('min_continuous_rest_per_week', contractData?.min_continuous_rest_per_week);
            formRef.current?.setFieldValue('min_break_hours_per_day', contractData?.min_break_hours_per_day);
            formRef.current?.setFieldValue(
                'min_break_hours_per_day_splitted',
                contractData?.min_break_hours_per_day_splitted
            );
            formRef.current?.setFieldValue('break_at_least_after_hours', contractData?.break_at_least_after_hours);
            formRef.current?.setFieldValue('day_hours_limit', contractData?.day_hours_limit);
            formRef.current?.setFieldValue('month_hours_limit', contractData?.month_hours_limit);
            formRef.current?.setFieldValue(
                'groups',
                (contractData?.contract_groups ?? []).length > 0
                    ? contractData.contract_groups
                    : [{ min: '', max: '', shift_ids: [] }]
            );
        }
    }, [formRef.current, contractsListData]);

    useEffect(() => {
        dispatch(fetchShiftsForSelect({ search: '' }));
    }, []);

    return (
        <CuForm<
            WithRequiredProp<IUserToContractFormDataType, 'id'>,
            IUserToContractFormDataType,
            number | string,
            false
        >
            {...props}
            id={id}
            isAsync={false}
            innerRef={formRef}
            displayAsSidebar={true}
            displayAsModal={false}
            justIcon={justIcon || false}
            name="userToContractForm"
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onRemove={handleRemove}
            items={[
                {
                    type: 'switch',
                    props: {
                        name: 'holidays_allowed',
                        label: t('label.holidaysAllowed', 'Hours Limit'),
                        value: data?.holidays_allowed,
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'night_shifts_allowed',
                        label: t('label.nightShiftsAllowed', 'Hours Limit'),
                        value: data?.night_shifts_allowed,
                        width: 6
                    }
                },
                {
                    type: 'weekdays',
                    props: {
                        name: 'days',
                        value: data?.days ?? 62,
                        multiple: true,
                        width: 12,
                        validation: {
                            deps: 'days_type'
                        },
                        sx: { alignItems: 'center' }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'contract_id',
                        label: t('header.contractName', 'Contract Name'),
                        value: data?.contract_id?.toString(),
                        options: contractsListData.map((contract) => ({
                            id: contract.id.toString(),
                            label: contract.name
                        })),
                        width: 10,
                        validation: {
                            deps: 'contract_id'
                        }
                    }
                },
                {
                    type: 'button',
                    display: ({ contract_id }) => !!contract_id,
                    props: {
                        name: 'copyFromParent',
                        width: 2,
                        children: t('label.copy', 'Copy'),
                        onClick: handleCopyFromContract,
                        validation: {
                            deps: 'contract_id'
                        }
                    }
                },
                {
                    type: 'dateRange',
                    props: {
                        required: true,
                        optionalEnd: true,
                        name: 'period',
                        label: {
                            start: t('header.contractStart', 'Contract Start'),
                            end: t('header.contractEnd', 'Contract End')
                        },
                        value: {
                            start: DateHelper.fromOptionalDateString(data?.start_at ?? null, 'UTC'),
                            end: DateHelper.fromOptionalDateString(data?.end_at ?? null, 'UTC')
                        }
                    }
                },
                {
                    type: 'html',
                    props: {
                        name: 'divider',
                        render: () => (
                            <>
                                <Divider />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        {t(
                                            'message.info.ifNextValuesAreNullThanDefaultValueWillBeTakenFromContract',
                                            'If Next Values Are Null Than Default Value Will Be Taken From Contract'
                                        )}
                                    </Typography>
                                </Box>
                            </>
                        )
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'day_hours_limit',
                        label: t('label.dayHoursLimit', 'Day Hours Limit'),
                        value: data?.day_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'week_hours_limit',
                        label: t('label.weekHoursLimit', 'Week Hours Limit'),
                        value: data?.week_hours_limit,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'number_of_hours_per_day',
                        label: t('label.numberOfHoursPerDay', 'Number Of Hours Per Day'),
                        value: data?.number_of_hours_per_day,
                        disabled: (currentValues) =>
                            currentValues.month_hours_limit !== '' &&
                            currentValues.month_hours_limit !== '0' &&
                            currentValues.month_hours_limit != null,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['month_hours_limit', 'number_of_hours_per_day'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_continuous_rest_per_week',
                        label: t('label.minContinuousRestPerWeek', 'Min Continuous Rest Per Week'),
                        value: data?.min_continuous_rest_per_week,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_break_hours_per_day',
                        label: t('label.minBreakHoursPerDay', 'Min Break Hours Per Day'),
                        value: data?.min_break_hours_per_day,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'min_break_hours_per_day_splitted',
                        label: t('label.minBreakHoursPerDaySplitted', 'Min Break Hours Per Day Splitted'),
                        value: data?.min_break_hours_per_day_splitted,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'break_at_least_after_hours',
                        label: t('label.breakAtLeastAfterHours', 'Break At Least After Hours'),
                        value: data?.break_at_least_after_hours,
                        width: 6,
                        type: 'number',
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'month_hours_limit',
                        label: t('label.monthHoursLimit', 'Month Hours Limit'),
                        value: data?.month_hours_limit,
                        width: 6,
                        type: 'number',
                        disabled: (currentValues) =>
                            currentValues.number_of_hours_per_day !== '' &&
                            currentValues.number_of_hours_per_day !== '0' &&
                            currentValues.number_of_hours_per_day != null,
                        InputProps: {
                            inputProps: {
                                step: 0.01,
                                min: 0
                            }
                        },
                        validation: {
                            deps: ['number_of_hours_per_day', 'month_hours_limit'],
                            min: {
                                value: 0,
                                message: 'Minimal value is 0.'
                            }
                        }
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'groups',
                        value: data?.contract_groups,
                        inputs: [
                            {
                                type: 'textField',
                                props: {
                                    required: (currentValues, rowIndex) =>
                                        Array.isArray(currentValues.groups) &&
                                        rowIndex !== null &&
                                        currentValues.groups.length > rowIndex &&
                                        (currentValues.groups[rowIndex].shift_ids ?? []).length !== 0,
                                    name: 'min',
                                    label: t('label.min', 'Min'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2,
                                    validation: {
                                        deps: ['shift_ids', 'min'],
                                        min: {
                                            value: 1,
                                            message: t('message.info.minimalValueIs1', 'Minimal value is {{value}}.', {
                                                value: 1
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'max',
                                    label: t('label.max', 'Max'),
                                    type: 'number',
                                    InputProps: {
                                        inputProps: {
                                            min: 1
                                        }
                                    },
                                    width: 2
                                }
                            },
                            {
                                type: 'multiSelect',
                                props: {
                                    name: 'shift_ids',
                                    required: (currentValues, rowIndex) =>
                                        Array.isArray(currentValues.groups) &&
                                        rowIndex !== null &&
                                        currentValues.groups.length > rowIndex &&
                                        currentValues.groups[rowIndex].min !== '' &&
                                        parseInt(`${currentValues.groups[rowIndex].min}`) !== 0,
                                    label: t('label.shifts', 'Shifts'),
                                    options:
                                        shifts?.map((item) => ({
                                            id: `${item.id}`,
                                            label: `${item.name}`
                                        })) ?? [],
                                    width: 6,
                                    validation: {
                                        deps: ['shift_ids', 'min']
                                    }
                                }
                            }
                        ]
                    }
                }
            ]}
        />
    );
};

export default UserToContractForm;
