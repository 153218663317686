import CrudDatatable from '@/components/CrudDatatable';
import { useAppSelector } from '@/data/hooks';
import { fetchNeeds, removeNeed } from '@/data/Needs/NeedActions';
import { INeedModel } from '@/data/Needs/NeedModels';
import { isNeedsListInProgress, needList, needPaging, needRemovingStatus } from '@/data/Needs/NeedReducer';
import NeedsForm from '@/forms/NeedsForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import NeedTypesOfDaysEnum from '@/utils/enums/NeedTypesOfDaysEnum';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Chip from '@/wrappers/Chip';
import DateRange from '@/wrappers/Datatable/CellViews/DateRange';
import Icon from '@/wrappers/Datatable/CellViews/Icon';
import Weekdays from '@/wrappers/Datatable/CellViews/Weekdays';

export default function NeedsListPage() {
    const { t } = useAppTranslation();
    const inProgress = useAppSelector(isNeedsListInProgress);
    const data = useAppSelector(needList);

    return (
        <LayoutOfPage title={t('title.needsList', 'Needs List')}>
            <CrudDatatable<INeedModel>
                name="need"
                resource={PermissionsEnum.Needs}
                nameOfEntity={(item) => `Need with id ${item.id}`}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <NeedsForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    // {
                    //     title: t('header.roleName', 'Role Name'),
                    //     field: 'role_id',
                    //     render: (rowData: INeedModel) =>
                    //         rowData.role && t(`roles.${rowData.role.name.toLowerCase()}`, rowData.role.name)
                    // },
                    {
                        title: t('header.skillName', 'Skill Name'),
                        field: 'skill_id',
                        sortField: 'Skills.name',
                        customSort: (data1, data2) =>
                            data1.skill?.name ? data1.skill.name.localeCompare(data2.skill?.name ?? '') : 0,
                        render: (rowData: INeedModel) => (rowData.skill?.icon ? <Icon {...rowData.skill} /> : undefined)
                    },
                    {
                        title: t('header.daysOrNumberOfDays', 'Days/Number Of Days'),
                        field: 'daysOrNumberOfDays',
                        render: (rowData: INeedModel) => {
                            if (
                                [NeedTypesOfDaysEnum.DaysPicker, NeedTypesOfDaysEnum.ContractFilling].some(
                                    (item) => rowData.days_type === item
                                )
                            ) {
                                return <Weekdays days={rowData.days!} holidays={rowData.holiday} />;
                            }

                            if (rowData.days_type === NeedTypesOfDaysEnum.NumberOfDaysPerWeek) {
                                return (
                                    <>
                                        {rowData.number_of_days} {t('label.days/Week', 'Days/Week')}
                                    </>
                                );
                            }

                            return <></>;
                        }
                    },
                    {
                        title: t('header.workplace', 'Workplace'),
                        field: 'workplace',
                        render: (rowData) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                name={`workplace-${rowData.workplace_id}`}
                                label={rowData.workplace.name}
                            />
                        )
                    },
                    {
                        title: t('header.valid', 'Valid'),
                        field: 'valid',
                        type: 'date',
                        render: (rowData: INeedModel) =>
                            rowData.period && (
                                <DateRange start={rowData.period.period_start} end={rowData.period.period_end} />
                            )
                    },
                    {
                        title: t('header.createdAt', 'Created At'),
                        field: 'created',
                        hidden: true
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(needPaging)}
                removingStatus={useAppSelector(needRemovingStatus)}
                onFetchList={fetchNeeds}
                onRemove={removeNeed}
            />
        </LayoutOfPage>
    );
}
