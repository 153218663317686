import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaging } from '../Paging';
import { IRootState } from '../store';
import { INotificationEmailCUModel } from './EmailNotificationModels';
import { create, fetchById, fetchList, remove, update } from './NotificationEmailApi';
import { notificationEmailPaging } from './NotificationEmailSlice';

export const fetchNotificationEmails = createAsyncThunk(
    'notificationEmail/list',
    async (args: Partial<IPaging>, thunkAPI) => {
        const currentPaging = notificationEmailPaging(thunkAPI.getState() as IRootState);

        return await fetchList({ ...currentPaging, ...args });
    }
);

export const fetchNotificationEmailById = createAsyncThunk('notificationEmail/byId', async (args: number) => {
    return await fetchById(args);
});

export const createNotificationEmail = createAsyncThunk(
    'notificationEmail/create',
    async (args: INotificationEmailCUModel) => {
        return await create(args);
    }
);

export const updateNotificationEmail = createAsyncThunk(
    'notificationEmail/update',
    async (args: { id: number; data: INotificationEmailCUModel }) => {
        return await update(args.id, args.data);
    }
);

export const removeNotificationEmail = createAsyncThunk(
    'notificationEmail/remove',
    async (args: { id: number; onSuccess?: () => void }) => {
        await remove(args.id).then(() => args.onSuccess && args.onSuccess());

        return args.id;
    }
);
