import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { queuesForSelect } from '@/data/Queues/QueueSlice';
import { fetchSkills, removeSkill } from '@/data/Skills/SkillActions';
import { ISkillModel } from '@/data/Skills/SkillModels';
import {
    isSkillListInProgress,
    selectFilteredSkills,
    skillPaging,
    skillRemovingStatus
} from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import SkillForm from '@/forms/SkillForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ChipStack from '@/wrappers/ChipStack';
import Icon from '@/wrappers/Datatable/CellViews/Icon';

type ISkillPage = ISkillModel & {
    queueSelect: { id: string; name: string; label: string; color: string }[];
};

const listPageSelector = createSelector(
    (state: IRootState) => selectFilteredSkills(state),
    (state: IRootState) => queuesForSelect(state),
    (skills, queuesList) =>
        skills.map(
            (skill) =>
                ({
                    ...skill,
                    queueSelect:
                        skill?.queue_items
                            ?.map((queueItem) => {
                                const queueData = queuesList?.find((item) => item.id == queueItem.queue_id);

                                return {
                                    id: `${queueData?.id ?? queueItem.queue_id}`,
                                    name: `queue_${queueItem.queue_id}`,
                                    label: queueData?.name ?? `${queueItem.queue_id}`,
                                    color: 'primary'
                                };
                            })
                            .sort((prev, next) => prev.label.localeCompare(next.label)) || []
                }) as ISkillPage
        )
);

export default function SkillsListPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const inProgress = useAppSelector(isSkillListInProgress);
    const data = useAppSelector(listPageSelector);

    useEffect(() => {
        dispatch(fetchQueuesForSelect({ search: '' }));
    }, []);

    return (
        <LayoutOfPage title={t('title.skillsList', 'Skills List')}>
            <CrudDatatable<ISkillPage>
                name="skill"
                resource={PermissionsEnum.Skills}
                searchPlaceHolder={t('label.searchSkill', 'Search Skill')}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <SkillForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    {
                        title: t('header.skillName', 'Skill Name'),
                        field: 'name',
                        render: (rowData: ISkillModel) => (rowData.icon ? <Icon {...rowData} /> : undefined)
                    },
                    {
                        title: t('header.queues', 'queues'),
                        field: 'queue_ids',
                        render: (rowData: ISkillPage) => (
                            <ChipStack
                                name="queues"
                                direction="row"
                                overflowHeight="5em"
                                values={rowData.queueSelect}
                            />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(skillPaging)}
                removingStatus={useAppSelector(skillRemovingStatus)}
                onFetchList={fetchSkills}
                onRemove={removeSkill}
            />
        </LayoutOfPage>
    );
}
