import { blue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import DateHelper from '@/helpers/date/DateHelper';
import Box from '@/wrappers/Box';

export const compactHeightOfRow = 35;
export const defaultHeightOfRow = 50;

export const Wrapper = styled(Box)`
    overflow: auto;
    display: grid;
`;

export const StyledWrapper = styled(Wrapper, {
    shouldForwardProp: (propName) =>
        ![
            'countOfRows',
            'countOfSkills',
            'isTableMode',
            'hasUnassignedRow',
            'isClosed',
            'isCompact',
            'isRequestMode'
        ].includes(propName as string)
})<{
    countOfRows: number;
    hasUnassignedRow?: boolean;
    countOfSkills?: number;
    isTableMode?: boolean;
    isRequestMode?: boolean;
    isClosed?: boolean;
    isCompact: boolean;
}>(
    ({
        countOfRows,
        hasUnassignedRow,
        countOfSkills: countOfSkills = 0,
        isTableMode,
        isRequestMode,
        isClosed,
        isCompact
    }) => `
    grid-template-areas:
        'empty           top'
        ${isTableMode || isRequestMode ? '' : "'coverageSideBar coverageData'"}
        ${new Array(countOfSkills)
            .fill('')
            .map((_, index) => `'skillSideBar${index} skillData${index}'`)
            .join(' ')}
        ${new Array(countOfRows)
            .fill('')
            .map((_, index) => `'scheduleSideBar${index} data${index}'`)
            .join(' ')}
        ${hasUnassignedRow ? "'unassignedScheduleSideBar unassignedData'" : ''}
        ${isClosed || isRequestMode ? '' : "'footerSideBar   footerData '"};
    
    grid-template-rows: ${isCompact ? compactHeightOfRow : defaultHeightOfRow}px ${
        isRequestMode ? '' : `${isCompact ? compactHeightOfRow : defaultHeightOfRow}px`
    } ${
        countOfSkills > 0 ? `${isCompact ? compactHeightOfRow : defaultHeightOfRow}px `.repeat(countOfSkills) : ''
    } ${'minmax(min-content, auto) '.repeat(countOfRows)}
     ${hasUnassignedRow ? 'minmax(min-content, auto)' : ''}
     ${isRequestMode || isClosed ? '' : `${isCompact ? compactHeightOfRow : defaultHeightOfRow}px`};
      ${isCompact ? compactHeightOfRow : defaultHeightOfRow}px;
    grid-template-columns: max-content minmax(max-content, auto);
    > * {
        min-height: ${isCompact ? compactHeightOfRow : defaultHeightOfRow}px;
    }
    `
);

const BaseSideBar = styled(Box)`
    background-color: white;
    border-bottom: 1px gray solid;
    border-right: 2px black solid;
    display: inline-flex;
    left: 0;
    position: sticky;
    white-space: nowrap;
    z-index: 1;
`;
const BaseHeaderSideBar = styled(BaseSideBar)`
    align-items: center;
    border-bottom-color: black;
    border-bottom-style: solid;
    z-index: 2;
`;
const BaseHeaderDataWrapper = styled(Box)`
    background-color: white;
    border-bottom-color: black;
    border-bottom-style: solid;
    display: inline-grid;
    position: sticky;
    z-index: 1;
`;
const BaseHeaderData = styled(Box)`
    align-items: center;
    background-color: white;
    border-right: 1px black solid;
    display: inline-flex;
    justify-content: center;

    :last-child {
        border-right: none;
    }
`;

export const BaseData = styled(Box)(
    ({ theme }) => `
    display: grid;
    align-items: center;
    background-color: ${theme.palette.grey.A100};
    
    > * {
        border-right: 1px black solid;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
    }
`
);

export const StyledEmptyCollapseButton = styled(BaseHeaderSideBar)`
    border-bottom-width: 2px;
    grid-area: empty;
    top: 0;

    > * {
        margin: 0 auto !important;
    }
`;
export const StyledTopDataWrapper = styled(BaseHeaderDataWrapper, {
    shouldForwardProp: (propName) => 'countOfColumns' !== propName && 'isCompact' !== propName
})<{ countOfColumns: number; isCompact: boolean }>(
    ({ countOfColumns, isCompact }) => `
    border-bottom-width: 2px;
    display: inline-grid;
    grid-area: top;
    grid-template-columns: repeat(${countOfColumns}, 1fr);
    top: 0;
    
    > * {
        display: flex;
        padding-left: ${isCompact ? 5 : 20}px;
        padding-right: ${isCompact ? 5 : 20}px;
        text-align: center;
    }
`
);
export const StyledTopDataItem = styled(BaseHeaderData, {
    shouldForwardProp: (propName) => 'columnIndex' !== propName && 'isDayView' !== propName
})<{ columnIndex: number; isDayView: boolean }>(
    ({ columnIndex }) => `
    grid-column: ${columnIndex + 1}/span 1;
    box-sizing: content-box;
`
);
export const StyledCoverageSideBar = styled(BaseHeaderSideBar, {
    shouldForwardProp: (propName) => 'isCompact' !== propName
})<{ isCompact: boolean }>(
    ({ isCompact }) => `
    border-bottom-width: 2px;
    grid-area: coverageSideBar;
    top: ${isCompact ? compactHeightOfRow : defaultHeightOfRow}px;
`
);
export const StyledCoverageDataWrapper = styled(BaseHeaderDataWrapper, {
    shouldForwardProp: (propName) => 'countOfColumns' !== propName && 'isCompact' !== propName
})<{ countOfColumns: number; isCompact: boolean }>(
    ({ countOfColumns, isCompact }) => `
    border-bottom-width: 2px;
    display: inline-grid;
    grid-area: coverageData;
    grid-template-columns: repeat(${countOfColumns}, 1fr);
    top: ${isCompact ? compactHeightOfRow : defaultHeightOfRow}px;

    > * {
        padding-left: 20px;
        padding-right: 20px;
    }
`
);
export const StyledCoverageDataItem = styled(BaseHeaderData, {
    shouldForwardProp: (propName) => propName !== 'columnIndex'
})<{ columnIndex: number }>(
    ({ columnIndex }) => `
    grid-column: ${columnIndex + 1}/span 1;
`
);
export const GridRootData = styled(BaseData, {
    shouldForwardProp: (propName) => !['countOfColumns', 'isLast', 'rowIndex'].includes(propName as string)
})<{ countOfColumns: number; isLast: boolean; rowIndex: number }>(
    ({ countOfColumns, isLast, rowIndex }) => `
    display: grid;
    grid-area: data${rowIndex};
    grid-template-columns: repeat(${countOfColumns}, 1fr);
    background-color: white;

    > * {
        text-align: center;
        border-bottom: ${isLast ? '2' : '1'}px black solid;
    }
`
);
export const StyledSkillSideBar = styled(BaseHeaderSideBar, {
    shouldForwardProp: (propName) => !['isCompact', 'isLast', 'rowIndex'].includes(propName as string)
})<{
    isCompact: boolean;
    isLast: boolean;
    rowIndex: number;
}>(
    ({ theme, isCompact, isLast, rowIndex }) => `
    background-color: ${theme.palette.grey.A100};
    border-bottom-width: ${isLast ? 2 : 1}px;
    display: grid;
    grid-area: skillSideBar${rowIndex};
    top: ${(isCompact ? compactHeightOfRow : defaultHeightOfRow) * (rowIndex + 2)}px;

    > * {
        align-items: center;
        justify-content: space-between;
    }
`
);
export const StyledSkillSideBarItem = styled(Box, {
    shouldForwardProp: (propName) =>
        !['hidden', 'isLast', 'selected', 'collapsed', 'rowIndex'].includes(propName as string)
})<{
    hidden?: boolean;
    selected?: boolean;
    collapsed?: boolean;
}>(
    ({ theme, hidden, selected, collapsed }) => `
    background-color: ${selected ? blue[100] : theme.palette.grey.A100};
    display: ${hidden ? 'none' : 'flex'};

    > * {
        ${collapsed ? 'margin-right: 0;' : ''}
    }
`
);
export const StyledSkillDataWrapper = styled(BaseHeaderDataWrapper, {
    shouldForwardProp: (propName) => !['countOfColumns', 'isCompact', 'isLast', 'rowIndex'].includes(propName as string)
})<{
    countOfColumns: number;
    isLast: boolean;
    isCompact: boolean;
    rowIndex: number;
}>(
    ({ countOfColumns, isCompact, isLast, rowIndex }) => `
    display: inline-grid;
    grid-area: skillData${rowIndex};
    grid-template-columns: repeat(${countOfColumns}, 1fr);
    border-bottom-width: ${isLast ? 2 : 1}px;
    top: ${(isCompact ? compactHeightOfRow : defaultHeightOfRow) * (rowIndex + 2)}px;
`
);
export const StyledSkillDataItem = styled(BaseHeaderData, {
    shouldForwardProp: (propName) =>
        !['columnIndex', 'covered', 'hidden', 'noRequirements', 'outOfPeriod', 'selected'].includes(propName as string)
})<{
    covered?: boolean;
    columnIndex: number;
    hidden?: boolean;
    noRequirements?: boolean;
    outOfPeriod?: boolean;
    selected?: boolean;
}>(({ theme, columnIndex, covered, hidden, noRequirements, outOfPeriod, selected }) => ({
    'opacity': 1,
    'alignItems': 'center',
    'display': 'flex',
    'justifyContent': 'center',
    'visibility': 'visible',
    'gridColumn': `${columnIndex + 1}/span 1`,
    'transition': 'all 200ms linear 0s, opacity 300ms',
    ...(!outOfPeriod && {
        backgroundColor: selected ? blue[400] : theme.palette.warning.main,
        cursor: 'pointer'
    }),
    ...(!outOfPeriod && covered && { backgroundColor: selected ? blue[200] : theme.palette.success.main }),
    ...(!outOfPeriod && noRequirements && { backgroundColor: selected ? blue[100] : theme.palette.grey['50'] }),
    ...(hidden && { opacity: 1, visibility: 'hidden' }),
    '.icon': { verticalAlign: 'middle' },
    '> *': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    }
}));
export const StyledUserSideBar = styled(BaseSideBar, {
    shouldForwardProp: (propName) => !['isCurrentUser', 'isLast', 'rowIndex'].includes(propName as string)
})<{ isCurrentUser: boolean; isLast: boolean; rowIndex: number }>(
    ({ theme, isCurrentUser, isLast, rowIndex }) => `
    display: grid;
    grid-area: scheduleSideBar${rowIndex};
    ${isLast ? 'border-bottom-width: 2px;' : ''}
    ${isLast ? 'border-bottom-color: black;' : ''}
    ${
        isCurrentUser
            ? `
            background-color: ${theme.palette.primary.light};
            color: ${theme.palette.primary.contrastText};
            `
            : ''
    }

    > * {
        align-items: center;
        display: flex;
        text-align: left;
        vertical-align: middle;
    }
`
);

export const GridUnassignedData = styled(BaseData)`
    background-color: white;
    display: grid;
    grid-area: unassignedData;
    grid-auto-rows: minmax(50px, auto);

    > * {
        text-align: center;
        border-bottom: 0 black solid;

        &:last-child {
            border-bottom-width: 0;
        }
    }

    > * > *,
    > * > * > * {
        border-bottom: 1px solid black;
    }

    > * > * > * {
        cursor: pointer;
    }
`;

export const StyledUnassignedSideBar = styled(BaseSideBar)`
    grid-area: unassignedScheduleSideBar;
    display: grid;
    border-bottom: 2px black solid;

    > * {
        align-items: center;
        display: flex;
        text-align: left;
        vertical-align: middle;
    }
`;

export const StyledFooterSideBar = styled(BaseSideBar)`
    grid-area: footerSideBar;
    border-bottom-width: 0;
`;
export const EmptyCellWrapper = styled(Box)`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
`;
export const EmptyCell = styled(Box, {
    shouldForwardProp: (propName) =>
        ![
            'bodyFrom',
            'columnWidth',
            'columnFrom',
            'columnTo',
            'columnStart',
            'currentDate',
            'isCurrentUser',
            'isDayMode',
            'rowStart',
            'scale',
            'isClosed'
        ].includes(propName as string)
})<{
    bodyFrom: Date;
    columnWidth: number;
    columnStart: number;
    currentDate: Date;
    columnFrom: Date;
    columnTo: Date;
    isCurrentUser: boolean;
    isDayMode: boolean;
    isClosed?: boolean;
    rowStart?: number;
    scale?: number;
}>(
    ({
        theme,
        bodyFrom,
        columnFrom,
        columnTo,
        columnWidth,
        columnStart,
        currentDate,
        isCurrentUser,
        isDayMode,
        rowStart,
        scale = 1.5,
        isClosed = false
    }) => `
    align-items: center;
    ${isClosed ? '' : 'cursor: pointer'};
    display: flex;
    grid-column-start: ${1 + columnStart};
    grid-column-end: span ${columnWidth};
    ${rowStart ? `grid-row-start: ${rowStart};` : ''}
    min-height: 20px;
    position: relative;
    transition: transform 0.2s;
    transition-property: background-color;
    :last-child {
        border-right: none;
    }
    ${
        isCurrentUser
            ? `
        background-color: ${
            DateHelper.isAfter(currentDate, columnFrom) ? theme.palette.primary.light : theme.palette.primary.main
        };
    `
            : DateHelper.isAfter(currentDate, columnFrom)
            ? `background-color: ${theme.palette.grey.A100};`
            : ''
    }
    ${DateHelper.isEqual(currentDate, columnFrom) ? 'border-left: 2px black solid;' : ''}
    ${
        (DateHelper.getDifferenceAsMinutes(bodyFrom, columnTo) / 15) % (isDayMode ? 1 : 96) !== 0 &&
        DateHelper.getHour(columnTo) !== 0
            ? 'border-right: none !important;'
            : ''
    }
    &:hover,
    &:focus {
        background-color: ${blue['50']};

        .icon {
            background-color: ${blue['50']};
            display: block;
            transform: scale(${scale});
            transform-origin: center;
            z-index: 10;
        }
    }
`
);
export const NotEmpty = styled(Box, {
    shouldForwardProp: (propName) =>
        !['bodyFrom', 'columnEnd', 'columnStart', 'currentDate', 'isDayMode', 'isTableView', 'rowStart'].includes(
            propName as string
        )
})<{
    bodyFrom: Date;
    columnEnd: number;
    columnStart: number;
    currentDate: Date;
    rowStart?: number;
    isDayMode: boolean;
    isTableView?: boolean;
}>(
    ({ bodyFrom, columnEnd, columnStart, currentDate, isDayMode, isTableView = false, rowStart, onClick }) => `
    ${DateHelper.isEqual(currentDate, bodyFrom) ? 'border-left: 2px black solid;' : ''}
    cursor: ${typeof onClick === 'undefined' ? '' : 'pointer'};
    display: inline-grid;
    grid-column-start: ${columnStart + 1};
    ${rowStart ? `grid-row-start: ${rowStart};` : ''}
    grid-column-end: span ${columnEnd};
    ${!isDayMode && (columnStart + columnEnd) % 96 !== 0 ? 'border-right: 0 !important;' : ''}
    ${isTableView ? 'border-left: 1px solid black;' : ''}
`
);

export const VacationWrapper = styled(Box, {
    shouldForwardProp: (propName) => !['columnEnd', 'columnStart'].includes(propName as string)
})<{ columnEnd: number; columnStart: number }>(
    ({ columnEnd, columnStart }) => `
    grid-column-start: ${columnStart + 1};
    grid-column-end: span ${columnEnd};
`
);

export const RequestWrapper = styled(Box, {
    shouldForwardProp: (propName) => !['columnEnd', 'columnStart'].includes(propName as string)
})<{ columnEnd: number; columnStart: number }>(
    ({ columnEnd, columnStart }) => `
    grid-column-start: ${columnStart + 1};
    grid-column-end: span ${columnEnd};
`
);

export const UserDataRowWrapper = styled(Box, {
    shouldForwardProp: (propName) => !['end', 'start'].includes(propName as string)
})<{ end: Date; start: Date }>(
    ({ end, start }) => `
    display: inline-grid;
    grid-template-columns: repeat(${DateHelper.getDifferenceAsMinutes(start, end) / 15}, 1fr);
`
);
export const TableFooterWrapper = styled(Box)`
    display: grid;
    grid-area: footerData;
`;
export const TableEmptyCell = styled(EmptyCell)`
    background-color: unset;
`;

export const RequestEmptyCell = styled(Box, {
    shouldForwardProp: (propName) => !['columnStartFromDate', 'columnStartToDate', 'width'].includes(propName as string)
})<{
    columnStartFromDate: Date;
    columnStartToDate: Date;
    width: number;
}>(({ columnStartFromDate, columnStartToDate, width }) => ({
    gridColumnStart: 1 + DateHelper.getDifferenceAsMinutes(columnStartFromDate, columnStartToDate) / 15,
    gridColumnEnd: `span ${width}`,
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    cursor: 'default'
}));

export const RequestNotEmptyCell = styled(Box, {
    shouldForwardProp: (propName) =>
        ![
            'columnStartFromDate',
            'columnStartToDate',
            'columnEndFromDate',
            'columnEndToDate',
            'width',
            'state'
        ].includes(propName as string)
})<{
    columnStartFromDate: Date;
    columnStartToDate: Date;
    columnEndFromDate?: Date;
    columnEndToDate?: Date;
    width?: number;
}>(({ columnStartFromDate, columnStartToDate, columnEndFromDate, columnEndToDate, width }) => ({
    cursor: 'pointer',
    display: 'inline-grid',
    gridColumnStart: 1 + DateHelper.getDifferenceAsMinutes(columnStartFromDate, columnStartToDate) / 15,
    ...(columnEndFromDate &&
        columnEndToDate && {
            gridColumnEnd: `span ${DateHelper.getDifferenceAsMinutes(columnEndFromDate, columnEndToDate) / 15}`
        }),
    ...(width && {
        gridColumnEnd: `span ${width}`
    })
}));

export const GridMergedData = styled(Box, {
    shouldForwardProp: (propName) => !['fromDate', 'toDate'].includes(propName as string)
})<{ fromDate: Date; toDate: Date }>(({ theme, fromDate, toDate }) => ({
    display: 'inline-grid',
    gridTemplateColumns: `repeat(${DateHelper.getDifferenceAsMinutes(fromDate, toDate) / 15}, 1fr)`,
    background: theme.palette.background.paper,
    ['> *']: {
        borderRight: '1px solid black',
        borderTop: '1px solid black'
    },
    ['> *:last-of-type']: {
        borderRight: 'none'
    }
}));
