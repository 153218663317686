import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import {
    fetchSchedulePlansForecastAccuracyReportCoverageRequire,
    fetchSchedulePlansForecastAccuracyReportPredictedTime,
    fetchUsersAttendanceReport,
    fetchUsersCategoryTypeOptions,
    getUsersAttendanceReportFile
} from '@/data/Reports/ReportActions';
import {
    ISchedulePlanPredictedTimeForecastAccuracyReportResponse,
    ISchedulePlanCoverageRequireForecastAccuracyReportResponse,
    IUserAttendanceReportResponse,
    ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport
} from '@/data/Reports/ReportModels';
import { IRootState } from '@/data/store';

export type IReportSlice = {
    reportSchedulePlanId: number | null;
    loadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId: number | null;
    loadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId: number | null;
    predictedTimeSliderValue: number;
    usersAttendancesFileReportStatus: IRequestState;
    usersAttendancesReportStatus: IRequestState;
    usersAttendancesReport: IUserAttendanceReportResponse[];
    schedulePlansCoverageRequireForecastAccuracyReportStatus: IRequestState;
    schedulePlansCoverageRequireForecastAccuracyReport: ISchedulePlanCoverageRequireForecastAccuracyReportResponse[];
    usersCategoryTypeOptionsStatus: IRequestState;
    usersCategoryTypeOptions: string[];
    schedulePlansPredictedTimeForecastAccuracyReportStatus: IRequestState;
    schedulePlansPredictedTimeForecastAccuracyReport: ISchedulePlanPredictedTimeForecastAccuracyReportResponse[];
    schedulePlansPredictedModifiedTimeForecastAccuracyReport: ISchedulePlanPredictedTimeAndOperatorsForecastAccuracyReport[];
};

const initialState: IReportSlice = {
    usersAttendancesFileReportStatus: 'idle',
    usersAttendancesReportStatus: 'idle',
    usersCategoryTypeOptionsStatus: 'idle',
    usersCategoryTypeOptions: [],
    usersAttendancesReport: [],
    schedulePlansCoverageRequireForecastAccuracyReportStatus: 'idle',
    schedulePlansCoverageRequireForecastAccuracyReport: [],
    schedulePlansPredictedTimeForecastAccuracyReportStatus: 'idle',
    schedulePlansPredictedTimeForecastAccuracyReport: [],
    schedulePlansPredictedModifiedTimeForecastAccuracyReport: [],
    reportSchedulePlanId: null,
    loadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId: null,
    loadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId: null,
    predictedTimeSliderValue: 0
};

const modifyPredictedTimeForecastAccuracy = (state: IReportSlice) => {
    state.schedulePlansPredictedModifiedTimeForecastAccuracyReport =
        state.schedulePlansPredictedTimeForecastAccuracyReport.map((predictedTimeItem) => ({
            ...predictedTimeItem,
            schedule_plan_day_id: parseInt(predictedTimeItem.schedule_plan_day_id),
            predicted_duration:
                parseFloat(predictedTimeItem.predicted_duration) * (1 + state.predictedTimeSliderValue / 100),
            real_operators_count: Math.ceil(parseFloat(predictedTimeItem.real_duration) / 60 / 15),
            predicted_operators_count: Math.ceil(
                (parseFloat(predictedTimeItem.predicted_duration) * (1 + state.predictedTimeSliderValue / 100)) /
                    60 /
                    15
            ),
            real_duration: parseFloat(predictedTimeItem.real_duration),
            real_avg_duration: parseFloat(predictedTimeItem.real_avg_duration)
        }));
};

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setReportSchedulePlanId(state, action: PayloadAction<{ schedulePlanId: number | null }>) {
            state.reportSchedulePlanId = action.payload.schedulePlanId;
        },
        setPredictedTimeSliderValue(state, action: PayloadAction<{ value: number }>) {
            state.predictedTimeSliderValue = action.payload.value;
            modifyPredictedTimeForecastAccuracy(state);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSchedulePlansForecastAccuracyReportCoverageRequire.pending, (state) => {
            state.schedulePlansCoverageRequireForecastAccuracyReportStatus = 'loading';
        });
        builder.addCase(fetchSchedulePlansForecastAccuracyReportCoverageRequire.fulfilled, (state, action) => {
            state.schedulePlansCoverageRequireForecastAccuracyReportStatus = 'idle';
            state.schedulePlansCoverageRequireForecastAccuracyReport = action.payload.data;
            state.loadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId = state.reportSchedulePlanId;
        });
        builder.addCase(fetchSchedulePlansForecastAccuracyReportCoverageRequire.rejected, (state) => {
            state.schedulePlansCoverageRequireForecastAccuracyReportStatus = 'failed';
        });
        builder.addCase(fetchSchedulePlansForecastAccuracyReportPredictedTime.pending, (state) => {
            state.schedulePlansPredictedTimeForecastAccuracyReportStatus = 'loading';
        });
        builder.addCase(fetchSchedulePlansForecastAccuracyReportPredictedTime.fulfilled, (state, action) => {
            state.schedulePlansPredictedTimeForecastAccuracyReportStatus = 'idle';
            state.schedulePlansPredictedTimeForecastAccuracyReport = action.payload.data;
            state.loadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId = state.reportSchedulePlanId;
            modifyPredictedTimeForecastAccuracy(state);
        });
        builder.addCase(fetchSchedulePlansForecastAccuracyReportPredictedTime.rejected, (state) => {
            state.schedulePlansPredictedTimeForecastAccuracyReportStatus = 'failed';
        });
        builder.addCase(fetchUsersAttendanceReport.pending, (state) => {
            state.usersAttendancesReportStatus = 'loading';
        });
        builder.addCase(fetchUsersAttendanceReport.fulfilled, (state, action) => {
            state.usersAttendancesReportStatus = 'idle';
            state.usersAttendancesReport = action.payload.data;
        });
        builder.addCase(fetchUsersAttendanceReport.rejected, (state) => {
            state.usersAttendancesReportStatus = 'failed';
        });
        builder.addCase(getUsersAttendanceReportFile.pending, (state) => {
            state.usersAttendancesFileReportStatus = 'loading';
        });
        builder.addCase(getUsersAttendanceReportFile.fulfilled, (state) => {
            state.usersAttendancesFileReportStatus = 'idle';
        });
        builder.addCase(getUsersAttendanceReportFile.rejected, (state) => {
            state.usersAttendancesFileReportStatus = 'failed';
        });
        builder.addCase(fetchUsersCategoryTypeOptions.pending, (state) => {
            state.usersCategoryTypeOptionsStatus = 'loading';
        });
        builder.addCase(fetchUsersCategoryTypeOptions.fulfilled, (state, action) => {
            state.usersCategoryTypeOptionsStatus = 'idle';
            state.usersCategoryTypeOptions = action.payload.data;
        });
        builder.addCase(fetchUsersCategoryTypeOptions.rejected, (state) => {
            state.usersCategoryTypeOptionsStatus = 'failed';
        });
    }
});

export default reportSlice;

const getState = (state: IRootState) => state[reportSlice.name];

export const getSchedulePlansCoverageRequiredForecastAccuracyReportStatus = (state: IRootState) =>
    getState(state).schedulePlansCoverageRequireForecastAccuracyReportStatus;
export const isSchedulePlansCoverageRequiredForecastAccuracyReportStatusLoading = (state: IRootState) =>
    getSchedulePlansCoverageRequiredForecastAccuracyReportStatus(state) === 'loading';
export const schedulePlansCoverageRequiredForecastAccuracyReport = (state: IRootState) =>
    getState(state).schedulePlansCoverageRequireForecastAccuracyReport;

export const usersAttendancesReport = (state: IRootState) => getState(state).usersAttendancesReport;
export const usersCategoryTypeOptions = (state: IRootState) => getState(state).usersCategoryTypeOptions;
export const getSchedulePlansPredictedTimeForecastAccuracyReportStatus = (state: IRootState) =>
    getState(state).schedulePlansPredictedTimeForecastAccuracyReportStatus;
export const isSchedulePlansPredictedTimeForecastAccuracyReportStatusLoading = (state: IRootState) =>
    getSchedulePlansPredictedTimeForecastAccuracyReportStatus(state) === 'loading';
export const getUsersAttendancesReportStatus = (state: IRootState) => getState(state).usersAttendancesReportStatus;
export const getUsersCategoryTypeOptionsStatus = (state: IRootState) => getState(state).usersCategoryTypeOptionsStatus;
export const isUsersCategoryTypeOptionsStatus = (state: IRootState) =>
    getUsersCategoryTypeOptionsStatus(state) === 'loading';
export const getUsersAttendancesFileReportStatus = (state: IRootState) =>
    getState(state).usersAttendancesFileReportStatus;
export const isUsersAttendancesReportStatusLoading = (state: IRootState) =>
    getUsersAttendancesReportStatus(state) === 'loading';
export const isUsersAttendancesFileReportStatusLoading = (state: IRootState) =>
    getUsersAttendancesFileReportStatus(state) === 'loading';
export const schedulePlansPredictedTimeForecastAccuracyReport = (state: IRootState) =>
    getState(state).schedulePlansPredictedTimeForecastAccuracyReport;
export const schedulePlansPredictedTimeModifiedForecastAccuracyReport = (state: IRootState) =>
    getState(state).schedulePlansPredictedModifiedTimeForecastAccuracyReport;

export const getReportSchedulePlanId = (state: IRootState) => getState(state).reportSchedulePlanId;
export const getLoadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId = (state: IRootState) =>
    getState(state).loadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId;
export const getLoadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId = (state: IRootState) =>
    getState(state).loadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId;
export const getPredictedTimeSliderValue = (state: IRootState) => getState(state).predictedTimeSliderValue;

export const { setReportSchedulePlanId, setPredictedTimeSliderValue } = reportSlice.actions;
