import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import WarningIcon from '@mui/icons-material/Warning';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { getDelaysCsvReportFile } from '@/data/SchedulePlanDayShiftDelays/schedulePlanDayShiftDelayActions';
import {
    isDelayCsvFileLoading,
    selectSchedulePlanDayShiftDelaysBySchedulePlanId
} from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelaySlice';
import { selectTimeZoneOfPlan } from '@/data/SchedulePlans/SchedulePlanSlice';
import { selectSchedulePlanValidationsBySchedulePlanId } from '@/data/SchedulePlanValidations/SchedulePlanValidationSlice';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import useLocalizeTimeFormatter from '@/hooks/useLocalizeTimeFormatter';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import ErrorsDialogTypeEnum from '@/utils/enums/ErrorsDialogTypeEnum';
import { serializeUser } from '@/utils/UserHelper';
import Datatable from '@/wrappers/Datatable';
import Dialog from '@/wrappers/Dialog';
import LoadingButton from '@/wrappers/LoadingButton';

type IProps = {
    schedulePlanId: number;
};

const StyledBottomNavigation = styled(BottomNavigation)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const ErrorsDialog = ({ schedulePlanId }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const translatedDays = useTranslatedDays('long');
    const timeZone = useAppSelector((state) => selectTimeZoneOfPlan(state, schedulePlanId));
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const timeFormatter = useLocalizeTimeFormatter(undefined, timeZone);
    const [type, setType] = useState<ErrorsDialogTypeEnum | null>(null);
    const isDownloadCsvLoading = useAppSelector(isDelayCsvFileLoading);

    const delays =
        useAppSelector((state) => selectSchedulePlanDayShiftDelaysBySchedulePlanId(state, schedulePlanId)) ?? [];
    const validations =
        useAppSelector((state) => selectSchedulePlanValidationsBySchedulePlanId(state, schedulePlanId)) ?? [];

    const handleChangeType = useCallback((value: string | null) => {
        setType(value ? (value as ErrorsDialogTypeEnum) : ErrorsDialogTypeEnum.validation);
    }, []);

    useEffect(() => {
        if (validations.length) {
            setType(ErrorsDialogTypeEnum.validation);
        } else if (delays.length) {
            setType(ErrorsDialogTypeEnum.delay);
        }
    }, [delays.length, validations.length]);

    const handleDownloadCsvFileOfDelays = useCallback(() => {
        if (type === ErrorsDialogTypeEnum.delay) {
            console.log('download', type);
            dispatch(getDelaysCsvReportFile({ schedulePlanId }));
        }
    }, [type]);

    if (!delays.length && !validations.length) {
        return <></>;
    }

    return (
        <Dialog
            maxWidth="xl"
            name="schedulePlanErrors"
            openButton={(onClick) => (
                <IconButton onClick={onClick} name="op">
                    <WarningIcon />
                </IconButton>
            )}
            openButtonValue={undefined}
            actions={() => (
                <>
                    <Collapse in={type === ErrorsDialogTypeEnum.delay}>
                        <LoadingButton
                            name="downloadCsv"
                            variant="contained"
                            color="primary"
                            loading={isDownloadCsvLoading}
                            onClick={handleDownloadCsvFileOfDelays}
                        >
                            {t('label.downloadReportCsvFile', 'Download Report Csv File')}
                        </LoadingButton>
                    </Collapse>
                </>
            )}
        >
            <StyledBottomNavigation
                showLabels
                value={type}
                onChange={(event, value) => {
                    handleChangeType(value);
                }}
                data-testid="errorDialogTypeNavigation"
            >
                {validations.length ? (
                    <BottomNavigationAction
                        label={t('label.validation', 'Validation')}
                        value={ErrorsDialogTypeEnum.validation}
                        icon={<NewReleasesOutlinedIcon />}
                    />
                ) : (
                    <></>
                )}
                {delays.length ? (
                    <BottomNavigationAction
                        label={t('label.delay', 'Delay')}
                        value={ErrorsDialogTypeEnum.delay}
                        icon={<RunningWithErrorsIcon />}
                    />
                ) : (
                    <></>
                )}
            </StyledBottomNavigation>
            <Collapse in={type === ErrorsDialogTypeEnum.delay}>
                <Typography>{t('subTitle.delayIssues', 'Delay Issues')}</Typography>
                <Datatable<ArrayElement<typeof delays>>
                    data={delays}
                    hasSearch={false}
                    hasSelection={false}
                    hasTitle={false}
                    header={[
                        {
                            title: t('header.user', 'User'),
                            field: 'user_name',
                            render: (rowData) => serializeUser(rowData.assigned_shift.user)
                        },
                        {
                            title: t('header.date', 'Date'),
                            field: 'date',
                            type: 'date',
                            render: (rowData) =>
                                `${dateFormatter.format(
                                    DateHelper.fromDateTimeString(rowData.assigned_shift.shift_start).toDate()
                                )} ${timeFormatter.format(
                                    DateHelper.fromDateTimeString(rowData.assigned_shift.shift_start).toDate()
                                )}`
                        },
                        {
                            field: 'message',
                            cellStyle: {
                                whiteSpace: 'nowrap'
                            },
                            render: (rowData) => {
                                return t(rowData.message.key, rowData.message.default, {
                                    ...rowData.message.params,
                                    ...(rowData.message.params.from
                                        ? {
                                              from: timeFormatter.format(
                                                  DateHelper.fromDateTimeString(rowData.message.params.from).toDate()
                                              )
                                          }
                                        : undefined),
                                    ...(rowData.message.params.to
                                        ? {
                                              to: timeFormatter.format(
                                                  DateHelper.fromDateTimeString(rowData.message.params.to).toDate()
                                              )
                                          }
                                        : undefined),
                                    delay: DateHelper.getMinutesInHumanFormat(rowData.delay_in_minutes)
                                });
                            }
                        }
                    ]}
                />
            </Collapse>
            <Collapse in={type === ErrorsDialogTypeEnum.validation}>
                <Typography>{t('subTitle.validationIssues', 'Validation Issues')}</Typography>

                <Datatable<ArrayElement<typeof validations>>
                    data={validations}
                    hasSearch={false}
                    hasSelection={false}
                    hasTitle={false}
                    header={[
                        {
                            title: t('header.user', 'User'),
                            field: 'user_name',
                            cellStyle: {
                                whiteSpace: 'nowrap'
                            },
                            render: (rowData) =>
                                rowData.user
                                    ? serializeUser(rowData.user)
                                    : rowData.assigned_shift
                                    ? serializeUser(rowData.assigned_shift.user)
                                    : '-'
                        },
                        {
                            title: t('header.date', 'Date'),
                            field: 'date',
                            cellStyle: {
                                whiteSpace: 'nowrap'
                            },
                            type: 'date',
                            render: (rowData) =>
                                rowData.date_start
                                    ? `${dateFormatter.format(
                                          DateHelper.fromDateTimeString(rowData.date_start).toDate()
                                      )} ${timeFormatter.format(
                                          DateHelper.fromDateTimeString(rowData.date_start).toDate()
                                      )}`
                                    : rowData.assigned_shift
                                    ? `${dateFormatter.format(
                                          DateHelper.fromDateTimeString(rowData.assigned_shift.shift_start).toDate()
                                      )} ${timeFormatter.format(
                                          DateHelper.fromDateTimeString(rowData.assigned_shift.shift_start).toDate()
                                      )}`
                                    : '-'
                        },
                        {
                            title: t('header.issueType', 'Issue Type'),
                            field: 'code',
                            cellStyle: {
                                whiteSpace: 'nowrap'
                            },
                            render: (rowData) => t(rowData.code.key, rowData.code.default)
                        },
                        {
                            field: 'message',
                            cellStyle: {
                                whiteSpace: 'nowrap'
                            },
                            render: (rowData) => {
                                return t(rowData.message.key, rowData.message.default, {
                                    ...rowData.message.params,
                                    day: rowData.message.params.day
                                        ? translatedDays[rowData.message.params.day as number]?.name
                                        : '-',
                                    days: rowData.message.params.days
                                        ? rowData.message.params.days
                                              .map((item, index) => (item ? translatedDays[index]?.name : ''))
                                              .filter(Boolean)
                                              .join(', ')
                                        : '-',
                                    from:
                                        rowData.message.params.from !== ''
                                            ? dateFormatter.format(
                                                  DateHelper.fromDateTimeString(rowData.message.params.from).toDate()
                                              )
                                            : '-',
                                    to:
                                        rowData.message.params.to !== ''
                                            ? dateFormatter.format(
                                                  DateHelper.fromDateTimeString(rowData.message.params.to).toDate()
                                              )
                                            : '-',
                                    fromDateTime:
                                        rowData.message.params.fromDateTime !== ''
                                            ? dateTimeFormatter.format(
                                                  DateHelper.fromDateTimeString(
                                                      rowData.message.params.fromDateTime
                                                  ).toDate()
                                              )
                                            : '-',
                                    toDateTime:
                                        rowData.message.params.toDateTime !== ''
                                            ? dateTimeFormatter.format(
                                                  DateHelper.fromDateTimeString(
                                                      rowData.message.params.toDateTime
                                                  ).toDate()
                                              )
                                            : '-'
                                });
                            }
                        }
                    ]}
                />
            </Collapse>
        </Dialog>
    );
};

export default ErrorsDialog;
