import { styled } from '@mui/material/styles';
import { throttle } from 'lodash';
import { cloneElement, FC, useMemo, useState } from 'react';
import { useElementSize } from '@/hooks/useElementSize';

type IVirtualizedContentProps = {
    rowHeight: number;
    children: Array<JSX.Element>;
    gap?: number;
};

const bufferedItems = 2;

const StyledContent = styled('ul')(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;

    background: ${theme.palette.background.default};

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 0 2px;
        background-color: ${theme.palette.primary.main};
        border-radius: ${theme.shape.borderRadius}px;
    }
`
);

export const VirtualizedContent: FC<IVirtualizedContentProps> = ({ rowHeight, children, gap = 0 }) => {
    const [containerRef, { height: containerHeight }] = useElementSize<HTMLUListElement>();
    const [scrollPosition, setScrollPosition] = useState(0);

    const visibleChildren = useMemo(() => {
        const startIndex = Math.max(Math.floor(scrollPosition / rowHeight) - bufferedItems, 0);
        const endIndex = Math.min(
            Math.ceil((scrollPosition + containerHeight) / rowHeight - 1) + bufferedItems,
            children.length - 1
        );

        return children.slice(startIndex, endIndex + 1).map((child, index) =>
            cloneElement(child, {
                style: {
                    position: 'absolute',
                    top: (startIndex + index) * rowHeight + index * gap,
                    height: rowHeight,
                    left: 0,
                    right: 0,
                    lineHeight: `${rowHeight}px`
                }
            })
        );
    }, [children, containerHeight, rowHeight, scrollPosition, gap]);

    const onScroll = useMemo(
        () =>
            throttle(
                function (e: any) {
                    setScrollPosition(e.target.scrollTop);
                },
                50,
                { leading: false }
            ),
        []
    );

    return (
        <StyledContent onScroll={onScroll} ref={containerRef}>
            <div style={{ minHeight: children.length * rowHeight + 'px' }}>{visibleChildren}</div>
        </StyledContent>
    );
};
