enum IntegrationsSettingsMegalaxExportMapItemEnum {
    replacementHolidayLeave = 'replacement_holiday_leave',
    holidayCompensation = 'holiday_compensation',
    holiday = 'holiday',
    sick = 'sick',
    doctor = 'doctor',
    donatingBlood = 'donating_blood',
    dayWithTheKids = 'day_with_the_kids',
    maternalLeave = 'maternal_leave',
    replacementLeave = 'replacement_leave',
    unexcusedAbsence = 'unexcused_absence',
    unpaidLeave = 'unpaid_leave',
    caringForAFamilyMember = 'caring_for_afamily_member',
    wedding = 'wedding',
    funeral = 'funeral',
    obstaclesEmployer = 'obstacles_employer',
    paidLeave = 'paid_leave'
}

export default IntegrationsSettingsMegalaxExportMapItemEnum;
