import { createAsyncThunk } from '@reduxjs/toolkit';
import { onDnDShiftBetweenDatesRemoveOriginalShift } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { getTimeZoneBySchedulePlanId, isPlanClosed } from '@/data/SchedulePlans/SchedulePlanSlice';
import { IRootState } from '@/data/store';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { serializeUser } from '@/utils/UserHelper';
import {
    assignShift,
    assignShiftToUser,
    declineShiftToUser,
    detachShift,
    updateShift
} from './SchedulePlanDayShiftApi';
import { ISchedulePlanDayShiftCUModel, ISchedulePlanDayShiftExtendedModel } from './SchedulePlanDayShiftModels';
import { schedulePlanDayShiftById, selectSchedulePlanDayShiftById } from './SchedulePlanDayShiftSlice';

export const assignShiftIntoDay = createAsyncThunk(
    'schedulePlanDayShifts/assignShift',
    async (args: Omit<ISchedulePlanDayShiftCUModel<DateTimeType>, 'id'>, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedule_plan_id);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return await assignShift({
            ...args,
            breaks: args.breaks?.map((item) => ({
                ...item,
                start: DateHelper.formatISO(item.start)
            })),
            skills: args.skills.map((item) => ({
                ...item,
                start: DateHelper.formatISO(item.start),
                end: DateHelper.formatISO(item.end)
            }))
        }).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theShiftWasSuccessfullyAssignedToUser',
                    defaultMessage: 'The Shift was successfully assigned to User',
                    variant: 'success'
                })
            );

            return ret;
        });
    }
);

export const assignShiftToSignedUser = createAsyncThunk(
    'schedulePlanDayShiftsAssignments/assignShiftToSignedUser',
    async (args: { schedulePlanDayShiftId: number; schedulePlanId: number }, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return await assignShiftToUser(args.schedulePlanDayShiftId).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theShiftWasSuccessfullyAssignedToUserName',
                    defaultMessage: 'The Shift was successfully assigned to {{user}}',
                    variant: 'success',
                    values: {
                        user: serializeUser(ret.user)
                    }
                })
            );

            return ret;
        });
    }
);

export const declineShiftToSignedUser = createAsyncThunk(
    'schedulePlanDayShiftsAssignments/unassignShiftToSignedUser',
    async (args: { schedulePlanDayShiftId: number; schedulePlanId: number }, { dispatch }) =>
        declineShiftToUser(args.schedulePlanDayShiftId).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theShiftWasSuccessfullyMovedToEmptyShifts',
                    defaultMessage: 'The Shift was successfully moved to empty shifts',
                    variant: 'success'
                })
            );

            return ret;
        })
);

export const moveShift = createAsyncThunk(
    'schedulePlanDayShifts/moveShift',
    async (args: { id: number; userId: number | null; date: Date }, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const shift = selectSchedulePlanDayShiftById(state, args.id) as ISchedulePlanDayShiftExtendedModel;
        const timeZone = getTimeZoneBySchedulePlanId(state, shift.schedule_plan_id);
        const removeOriginalShift = onDnDShiftBetweenDatesRemoveOriginalShift(state);

        if (!timeZone) {
            return Promise.reject('Missing shift');
        }

        const shiftStart = DateHelper.getFirstMomentOfDay(
            DateHelper.setTimeZone(DateHelper.fromDateTimeString(shift.shift_start), timeZone)
        );
        const targetDate = DateHelper.setTimeZone(args.date, timeZone);

        const diffDays = DateHelper.getDifferenceAsDays(shiftStart, targetDate);

        const getBaseData = (diff: number = 0) => ({
            shift_id: shift.shift_id,
            schedule_plan_id: shift.schedule_plan_id,
            date: DateHelper.formatISO(DateHelper.addDays(shiftStart, diffDays)),
            breaks:
                (shift.schedule_plan_day_shift_breaks ?? []).map((item) => ({
                    shift_item_id: item.shift_item_id,
                    break_id: item.break_id,
                    start: DateHelper.formatISO(DateHelper.addDays(DateHelper.fromDateTimeString(item.start), diff)),
                    used: item.used
                })) ?? [],
            skills: (shift.schedule_plan_day_shift_skills ?? []).map((item) => ({
                skill_id: item.skill_id,
                start: DateHelper.formatISO(DateHelper.addDays(DateHelper.fromDateTimeString(item.start), diff)),
                end: DateHelper.formatISO(DateHelper.addDays(DateHelper.fromDateTimeString(item.end), diff))
            }))
        });

        const newShift = await (diffDays !== 0 && !removeOriginalShift
            ? assignShift({
                  ...getBaseData(diffDays),
                  user_id: args.userId,
                  date: DateHelper.formatDate(args.date)
              }).then(
                  async (ret) =>
                      await updateShift(args.id, {
                          ...getBaseData(),
                          user_id: null
                      }).then(() => ret)
              )
            : updateShift(args.id, {
                  ...getBaseData(diffDays),
                  user_id: args.userId
              })
        ).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theAssignedShiftWasSuccessfullyUpdated',
                    defaultMessage: 'The Assigned Shift was successfully updated',
                    variant: 'success'
                })
            );

            return ret;
        });

        return {
            ...newShift,
            onlyUpdated: diffDays === 0,
            removeOriginal: removeOriginalShift
        };
    },
    {
        condition: (args, { getState }) => {
            const state = getState() as IRootState;
            const shift = selectSchedulePlanDayShiftById(state, args.id);
            const shiftStart = shift ? DateHelper.fromDateTimeString(shift.shift_start).toDate() : null;

            return Boolean(
                shift !== null &&
                    shiftStart !== null &&
                    shift.shift_id !== null &&
                    shift.shift_end !== null &&
                    DateHelper.isValid(args.date) &&
                    ((Number.isInteger(args.userId) &&
                        !(DateHelper.isEqual(shiftStart, args.date) && args.userId === shift.user_id)) ||
                        (args.userId === null && DateHelper.isEqual(args.date, shiftStart)))
            );
        }
    }
);

export const updateAssignedShift = createAsyncThunk(
    'schedulePlanDayShifts/updateShift',
    async (
        args: { id: number; schedulePlanId: number; data: ISchedulePlanDayShiftCUModel<DateTimeType> },
        { getState, dispatch }
    ) => {
        const state = getState() as IRootState;
        const prevShift = schedulePlanDayShiftById(state, args.id);
        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        const response = await updateShift(args.id, {
            ...args.data,
            breaks: args.data.breaks?.map((item) => ({
                ...item,
                start: DateHelper.formatDateTimeToISO(item.start)
            })),
            skills: args.data.skills.map((item) => ({
                ...item,
                start: DateHelper.formatDateTimeToISO(item.start),
                end: DateHelper.formatDateTimeToISO(item.end)
            }))
        }).then((ret) => {
            dispatch(
                addNotification({
                    context: 'message.success.theAssignedShiftWasSuccessfullyUpdated',
                    defaultMessage: 'The Assigned Shift was successfully updated',
                    variant: 'success'
                })
            );

            return ret;
        });

        return {
            old: prevShift,
            new: response
        };
    }
);

export const detachShiftFromDay = createAsyncThunk(
    'schedulePlanDayShifts/detachShift',
    async (args: { id: number; schedulePlanId: number }, { getState, dispatch }) => {
        const state = getState() as IRootState;
        const shift = schedulePlanDayShiftById(state, args.id);
        const response = await detachShift(args.schedulePlanId, args.id);

        const isClosed = isPlanClosed(state, args.schedulePlanId);

        if (isClosed) {
            dispatch(
                addNotification({
                    context: 'message.error.schedulePlanIsClosed',
                    defaultMessage: 'Schedule Plan is closed',
                    variant: 'error'
                })
            );

            return Promise.reject();
        }

        return {
            ...response,
            shift_start: shift?.shift_start,
            shift_end: shift?.shift_end
        };
    }
);
