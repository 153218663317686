import { IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPost, fetchPatch, urlBuilderWithParams } from '../request';
import { INotificationEmailCUModel, INotificationEmailModel } from './EmailNotificationModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/notification-emails', paging || {});

    return fetchGet<IListResponseWithPaging<INotificationEmailModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<INotificationEmailModel>(`/api/notification-emails/${id}`);
}
export function create(data: INotificationEmailCUModel) {
    return fetchPost<INotificationEmailModel>('/api/notification-emails/', data);
}
export function update(id: number, data: INotificationEmailCUModel) {
    return fetchPatch<INotificationEmailModel>(`/api/notification-emails/${id}`, data);
}
export function remove(id: number) {
    return fetchDelete(`/api/notification-emails/${id}`);
}
