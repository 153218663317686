import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ReactElement, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchShiftById } from '@/data/Shifts/ShiftActions';
import { selectShiftById, shiftById } from '@/data/Shifts/ShiftSlice';
import { IRootState } from '@/data/store';
import { fetchUserById } from '@/data/Users/UserActions';
import { userById } from '@/data/Users/UserSlice';
import { ISchedulerUserToRequestModel } from '@/data/UserToRequests/UserToRequestModels';
import DateHelper from '@/helpers/date/DateHelper';
import useAppTranslation from '@/hooks/useAppTranslation';
import useLocalizeDateFormatter from '@/hooks/useLocalizeDateFormatter';
import useLocalizeDateTimeFormatter from '@/hooks/useLocalizeDateTimeFormatter';
import ShiftAsTimelineView, { IShiftAsTimelineViewProps } from '@/modules/Scheduler/ShiftAsTimelineView';
import RequestTypeEnum from '@/utils/enums/RequestTypeEnum';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeUser } from '@/utils/UserHelper';
import Tooltip, { ITooltipProps } from '@/wrappers/Tooltip';

type IProps = Pick<ITooltipProps, 'wrapperStyles'> & {
    request: ISchedulerUserToRequestModel;
    children: ReactElement;
    disableHoverListener?: boolean;
    timeZone?: string;
};

const SchedulerRequestTooltip = ({
    request,
    children,
    disableHoverListener = false,
    wrapperStyles,
    timeZone
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => userById(state, request.user_id));
    const userName = serializeUser(user);
    const dateTimeFormatter = useLocalizeDateTimeFormatter({ timeZone });
    const dateFormatter = useLocalizeDateFormatter({ timeZone });
    const sourceShiftsData = useAppSelector((state: IRootState) =>
        request.schedule_plan_day_shift ? shiftById(state, request.schedule_plan_day_shift.shift_id) : undefined
    );
    const requestedShiftData = useAppSelector((state: IRootState) => selectShiftById(state, request.shift_id ?? null));
    const isShift = useMemo(() => request.request_type.type === RequestTypeEnum.shifts, [request.request_type.type]);
    const isTimeOff = useMemo(
        () => request.request_type.type === RequestTypeEnum.times_off,
        [request.request_type.type]
    );
    const isAvailable = useMemo(
        () => request.request_type.type === RequestTypeEnum.available,
        [request.request_type.type]
    );

    useEffect(() => {
        if (request.schedule_plan_day_shift) {
            dispatch(fetchShiftById(request.schedule_plan_day_shift.shift_id));
        }
    }, [request]);

    const renderShiftAsTimelineView = (
        label: string,
        defaultLabelValue: string,
        shiftStart: string,
        shiftEnd: string,
        abr: { text: string; background: string; color: string },
        breaks: IShiftAsTimelineViewProps['breaks']
    ) => (
        <>
            <Typography variant="h6">
                {t(label, defaultLabelValue, {
                    date: dateFormatter.format(DateHelper.fromDateTimeString(shiftStart).toDate())
                })}
            </Typography>
            <ShiftAsTimelineView
                shiftStart={DateHelper.fromDateTimeString(shiftStart)}
                shiftEnd={DateHelper.fromDateTimeString(shiftEnd)}
                abbr={abr}
                breaks={breaks}
                timeZone={timeZone}
            />
        </>
    );

    const title = (
        <>
            {[UserToRequestsStateEnum.ERROR, UserToRequestsStateEnum.CALCULATING_FUNDS_ERROR].some(
                (item) => item === request.state
            ) && (
                <>
                    <Typography variant="body1">
                        <b>
                            {t(
                                request.state === UserToRequestsStateEnum.ERROR
                                    ? 'label.error'
                                    : 'label.calculatingFundsError',
                                'Error while calculating funds'
                            )}
                        </b>
                        <br />
                        {request.message}
                    </Typography>
                    <Divider />
                </>
            )}
            {request.state === UserToRequestsStateEnum.CALCULATING_FUNDS && (
                <>
                    <Typography variant="body1">
                        <CircularProgress size={20} sx={{ marginRight: '1em' }} />
                        <b>{t('message.info.requestIsCalculatingFunds', 'Request is calculating funds')}</b>
                    </Typography>
                    <br />
                </>
            )}
            {request.schedule_plan_day_shift &&
                sourceShiftsData &&
                renderShiftAsTimelineView(
                    'label.sourceShiftAt',
                    'Source Shift At {{date}}',
                    request.schedule_plan_day_shift.shift_start,
                    request.schedule_plan_day_shift.shift_end,
                    {
                        text: sourceShiftsData.abbreviation,
                        background: sourceShiftsData.background,
                        color: sourceShiftsData.color
                    },
                    request.schedule_plan_day_shift?.schedule_plan_day_shift_breaks?.map((breakData) => ({
                        id: breakData.id,
                        break_id: breakData.break_id,
                        start: DateHelper.fromDateTimeString(breakData.start),
                        breakDuration: breakData.break.duration,
                        breakBackground: breakData.break.background,
                        required: breakData.break.required,
                        used: breakData.used
                    })) ?? []
                )}
            {isShift &&
                requestedShiftData &&
                renderShiftAsTimelineView(
                    'label.requestedShiftAt',
                    'Requested Shift At {{date}}',
                    DateHelper.fromTimeStringAndLocalDate(
                        requestedShiftData.time_start,
                        request.datetime_from,
                        timeZone
                    ).toISOString(),
                    DateHelper.addHours(
                        DateHelper.fromTimeStringAndLocalDate(
                            requestedShiftData.time_start,
                            request.datetime_from,
                            timeZone
                        ),
                        requestedShiftData.duration
                    ).toISOString(),
                    {
                        text: requestedShiftData.abbreviation,
                        background: requestedShiftData.background,
                        color: requestedShiftData.color
                    },
                    requestedShiftData.shift_items?.map((breakData) => ({
                        id: breakData.id,
                        break_id: breakData.break_id,
                        start: DateHelper.fromDateTimeString(breakData.start),
                        breakDuration: breakData.duration,
                        breakBackground: breakData.break?.background ?? '#ffffff',
                        required: false, // todo dodelat vazbu na breaku
                        used: false
                    })) ?? []
                )}
            {(isTimeOff || isAvailable) && (
                <>
                    <>
                        {dateTimeFormatter.format(request.datetime_from.toDate())} -{' '}
                        {dateTimeFormatter.format(request.datetime_to.toDate())}
                    </>
                    <br />
                </>
            )}
            <b>{t('label.user', 'User')}: </b> {userName}
            <br />
            {request.description && (
                <>
                    {t('label.description', 'Description')}: {request.description} <br />
                </>
            )}
            {t('label.requestType', 'Request Type: {{type}}', { type: request.request_type.name })}
        </>
    );

    useEffect(() => {
        fetchUserById(request.user_id);
    }, [request.user_id]);

    return (
        <Tooltip
            disableHoverListener={disableHoverListener}
            color="white"
            title={title}
            maxWidth="none"
            data-class="schedule_grid_request_tooltip"
            data-testid={`schedule_grid_request_tooltip_${request.user_id}_${request.datetime_from.toISOString()}`}
            wrapperStyles={wrapperStyles}
        >
            {children}
        </Tooltip>
    );
};

export default SchedulerRequestTooltip;
