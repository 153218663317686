enum UserToRequestsStateEnum {
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CALCULATING_FUNDS = 'CALCULATING_FUNDS',
    ERROR = 'ERROR',
    CALCULATING_FUNDS_ERROR = 'CALCULATING_FUNDS_ERROR'
}

export default UserToRequestsStateEnum;
