import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchPeriodsForSelect } from '@/data/Periods/PeriodActions';
import { periodsForSelect } from '@/data/Periods/PeriodSlice';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { fetchSkillsForSelect } from '@/data/Skills/SkillActions';
import {
    fetchUserHours,
    fetchUserShifts,
    fetchUserSkills,
    getUserHoursFile,
    getUserShiftsFile,
    getUserSkillsFile
} from '@/data/Summaries/SummariesActions';
import { isDownloadingCsvFilesLoadingSelector } from '@/data/Summaries/SummariesSlice';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { usersForSelect } from '@/data/Users/UserSlice';
import { fetchWorkplacesForSelect } from '@/data/Workplaces/WorkplaceActions';
import { workplacesForSelect } from '@/data/Workplaces/WorkplaceSlice';
import useAppTranslation from '@/hooks/useAppTranslation';
import SummariesTypeEnum from '@/utils/enums/SummariesTypeEnum';
import { serializeUser } from '@/utils/UserHelper';
import Button from '@/wrappers/Button/Button';
import DialogActions from '@/wrappers/Dialog/Actions';
import DialogContainer from '@/wrappers/Dialog/Container';
import DialogContent from '@/wrappers/Dialog/Content';
import LoadingButton from '@/wrappers/LoadingButton';
import MultiSelect from '@/wrappers/MultiSelect';
import Select from '@/wrappers/Select';
import Hours from '@/wrappers/SummarizationDialog/Datatables/Hours';
import Shifts from '@/wrappers/SummarizationDialog/Datatables/Shifts';
import Skills from '@/wrappers/SummarizationDialog/Datatables/Skills';

type IProps = {
    open: boolean;
    onClose: () => void;
    hasFilter?: boolean;
    scheduleName: string | null;
    workplaceId?: number | null;
    periodId?: number | null;
    userIds?: number[];
};

const StyledBottomNavigation = styled(BottomNavigation)`
    margin-top: 1em;
    margin-bottom: 1em;
`;

const StyledBoxForFilters = styled(Box)`
    padding-top: 1em;
    display: grid;
    gap: 10px;
`;

const SummarizationDialog = ({
    open,
    onClose,
    hasFilter = true,
    scheduleName,
    workplaceId = null,
    periodId = null,
    userIds = []
}: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const usersData = useAppSelector(usersForSelect);
    const isDownloadCsvLoading = useAppSelector(isDownloadingCsvFilesLoadingSelector);
    const periodsData = useAppSelector(periodsForSelect);
    const workplacesData = useAppSelector(workplacesForSelect);
    const [workplaceIdState, setWorkplaceIdState] = useState<number | null>(workplaceId ?? null);
    const [periodIdState, setPeriodIdState] = useState<number | null>(periodId ?? null);
    const [usersIdsList, setUsersIdsList] = useState<number[]>(userIds);
    const [summariesType, setSummariesType] = useState<SummariesTypeEnum>(SummariesTypeEnum.Shifts);

    const resetFilters = useCallback(() => {
        setWorkplaceIdState(workplaceId ?? null);
        setPeriodIdState(periodId ?? null);
        setUsersIdsList(userIds);
    }, [workplaceId, periodId, userIds]);

    const handleCloseDialog = useCallback(() => {
        resetFilters();
        onClose();
    }, []);

    const handleChangeWorkplaceId = useCallback((value: string | null) => {
        setWorkplaceIdState(typeof value === 'string' ? parseInt(value) : null);
    }, []);

    const handleChangePeriodId = useCallback((value: string | null) => {
        setPeriodIdState(typeof value === 'string' ? parseInt(value) : null);
    }, []);

    const handleChangeUsersIdsList = useCallback((value: string[] | null) => {
        setUsersIdsList(value ? value.map((item) => parseInt(item)) : []);
    }, []);

    const handleChangeSummariesType = useCallback((value: string | null) => {
        resetFilters();
        setSummariesType(value ? (value as SummariesTypeEnum) : SummariesTypeEnum.Shifts);
    }, []);

    useEffect(() => {
        if (open) {
            dispatch(fetchUsersForSelect({ search: '' }));
            dispatch(fetchShiftsForSelect({ search: '' }));
            dispatch(fetchSkillsForSelect({ search: '' }));
            if (hasFilter) {
                dispatch(fetchWorkplacesForSelect({ search: '' }));
                dispatch(fetchPeriodsForSelect({ search: '' }));
            }
        }
    }, [open]);

    const handleDownloadCsvFileOfReport = useCallback(() => {
        if (open) {
            if (summariesType === SummariesTypeEnum.Shifts) {
                dispatch(
                    getUserShiftsFile({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }

            if (summariesType === SummariesTypeEnum.Hours) {
                dispatch(
                    getUserHoursFile({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }

            if (summariesType === SummariesTypeEnum.Skills) {
                dispatch(
                    getUserSkillsFile({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }
        }
    }, [open, summariesType, workplaceIdState, periodIdState, usersIdsList]);

    useEffect(() => {
        if (open) {
            if (summariesType === SummariesTypeEnum.Shifts) {
                dispatch(
                    fetchUserShifts({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }

            if (summariesType === SummariesTypeEnum.Hours) {
                dispatch(
                    fetchUserHours({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }

            if (summariesType === SummariesTypeEnum.Skills) {
                dispatch(
                    fetchUserSkills({
                        ...(workplaceIdState ? { workplaceId: workplaceIdState } : {}),
                        ...(periodIdState ? { periodId: periodIdState } : {}),
                        ...(usersIdsList.length > 0 ? { userIds: usersIdsList } : {})
                    })
                );
            }
        }
    }, [open, summariesType, workplaceIdState, periodIdState, usersIdsList]);

    let dialogTitle = '';

    if (summariesType === SummariesTypeEnum.Shifts) {
        dialogTitle = scheduleName
            ? t('label.summarizationForShift', 'Summarization For {{shift}}', {
                  shift: scheduleName ?? ''
              })
            : t('label.summarizationOfShifts', 'Summarization Of Shifts');
    } else if (summariesType === SummariesTypeEnum.Hours) {
        dialogTitle = scheduleName
            ? t('label.hoursForShift', 'Hours For {{shift}}', {
                  shift: scheduleName ?? ''
              })
            : t('label.hoursOfShifts', 'Summarization Of Shifts');
    }

    return (
        <DialogContainer
            open={open}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth={'xl'}
            data-testid="summarizationDialog"
        >
            <StyledBottomNavigation
                showLabels
                value={summariesType}
                onChange={(_, value) => {
                    handleChangeSummariesType(value);
                }}
                data-testid="summarizationDialogTypeNavigation"
            >
                <BottomNavigationAction
                    label={t('label.shifts', 'Shifts')}
                    value={SummariesTypeEnum.Shifts}
                    icon={<DateRangeOutlinedIcon />}
                />
                <BottomNavigationAction
                    label={t('label.hours', 'Hours')}
                    value={SummariesTypeEnum.Hours}
                    icon={<QueryBuilderOutlinedIcon />}
                />
                <BottomNavigationAction
                    label={t('label.skills', 'Skills')}
                    value={SummariesTypeEnum.Skills}
                    icon={<PersonSearchOutlinedIcon />}
                />
            </StyledBottomNavigation>
            <DialogTitle data-testid="summarizationDialogTitle">{dialogTitle}</DialogTitle>
            {hasFilter ? (
                <Box data-testid="summarizationDialogFilters">
                    <DialogContent sx={{ overflow: 'visible' }}>
                        <Collapse
                            in={summariesType === SummariesTypeEnum.Shifts || summariesType === SummariesTypeEnum.Hours}
                        >
                            <StyledBoxForFilters>
                                <Select
                                    name="workplace"
                                    label={t('label.workplace', 'Workplace')}
                                    options={workplacesData.map((workplace) => ({
                                        id: workplace.id.toString(),
                                        label: workplace.name
                                    }))}
                                    onChange={handleChangeWorkplaceId}
                                    value={workplacesData?.find((workplace) => workplace.id == workplaceIdState)?.id}
                                />
                                <Select
                                    name="period"
                                    label={t('label.period', 'Period')}
                                    options={periodsData.map((period) => ({
                                        id: period.id.toString(),
                                        label: period.name
                                    }))}
                                    onChange={handleChangePeriodId}
                                    value={periodsData?.find((period) => period.id == periodIdState)?.id}
                                />
                                <MultiSelect
                                    name="user"
                                    label={t('label.user', 'User')}
                                    options={usersData.map((user) => ({
                                        id: user.id.toString(),
                                        label: serializeUser(user)
                                    }))}
                                    onChange={handleChangeUsersIdsList}
                                    value={usersData
                                        .filter((user) => usersIdsList.some((userId) => userId === user.id))
                                        .map((user) => user.id.toString())}
                                />
                            </StyledBoxForFilters>
                        </Collapse>
                    </DialogContent>
                </Box>
            ) : (
                <></>
            )}
            <Box data-testid="summarizationDialogContent">
                <DialogContent>
                    <Collapse in={summariesType === SummariesTypeEnum.Shifts}>
                        <Shifts key="shifts" />
                    </Collapse>
                    <Collapse in={summariesType === SummariesTypeEnum.Hours}>
                        <Hours key="hours" />
                    </Collapse>
                    <Collapse in={summariesType === SummariesTypeEnum.Skills}>
                        <Skills key="skills" />
                    </Collapse>
                </DialogContent>
            </Box>
            <Box data-testid="summarizationDialogActions">
                <DialogActions>
                    <Button name="no" variant="outlined" onClick={handleCloseDialog}>
                        {t('label.close', 'Close')}
                    </Button>
                    <LoadingButton
                        name="downloadCsv"
                        variant="contained"
                        color="primary"
                        loading={isDownloadCsvLoading}
                        onClick={handleDownloadCsvFileOfReport}
                    >
                        {t('label.downloadReportCsvFile', 'Download Report Csv File')}
                    </LoadingButton>
                </DialogActions>
            </Box>
        </DialogContainer>
    );
};

export default SummarizationDialog;
