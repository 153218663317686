export const getBestPlaceForShiftLabel = (
    isDayMode: boolean,
    width: number,
    breaks: {
        columnStart: number;
        width: number;
    }[]
) =>
    !isDayMode || breaks.length === 0
        ? { start: 1, width }
        : [...breaks, { columnStart: width - 1, width: 0 }].reduce(
              (result, breakItem) =>
                  result.width > 10 || breakItem.columnStart - result.currentColumn < result.width
                      ? {
                            ...result,
                            currentColumn: breakItem.columnStart + breakItem.width
                        }
                      : {
                            currentColumn: breakItem.columnStart + breakItem.width,
                            start: result.currentColumn,
                            width: breakItem.columnStart - result.currentColumn
                        },
              { currentColumn: 1, start: 1, width: breaks[0].columnStart - 1 }
          );
