import CrudDatatable from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypes, removeTypeRequest, updateTypeRequest } from '@/data/RequestTypes/RequestTypeActions';
import { IRequestTypeModel } from '@/data/RequestTypes/RequestTypeModels';
import {
    isRequestTypesListInProgress,
    requestPaging,
    requestTypeRemovingStatus,
    selectFilteredRequestTypes
} from '@/data/RequestTypes/RequestTypeSlice';
import { getPermissionsList } from '@/data/System/SystemReducer';
import RequestTypeForm from '@/forms/RequestTypeForm';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import Switch from '@/wrappers/Switch';

export default function RequestTypesListPage() {
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const inProgress = useAppSelector(isRequestTypesListInProgress);
    const data = useAppSelector(selectFilteredRequestTypes);
    const permissionsList = useAppSelector(getPermissionsList);

    return (
        <LayoutOfPage title={t('title.requestTypesList', 'Request Types List')}>
            <CrudDatatable<IRequestTypeModel>
                name="requestType"
                resource={PermissionsEnum.RequestTypes}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _rowData, renderButton) => (
                    <RequestTypeForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.requestName', 'Request Name'), field: 'name' },
                    { title: t('header.requestType', 'Request Type'), field: 'type' },
                    { title: t('header.description', 'Description'), field: 'description' },
                    {
                        title: t('header.active', 'Active'),
                        field: 'active',
                        render: (rowData: IRequestTypeModel) => (
                            <Switch
                                name={rowData.name}
                                value={rowData.active}
                                onChange={(value) =>
                                    isUserAllowed(
                                        {
                                            id: PermissionsEnum.RequestTypes,
                                            mode: [Mode.UPDATE, Mode.CREATE],
                                            operator: 'OR'
                                        },
                                        permissionsList
                                    ) &&
                                    dispatch(
                                        updateTypeRequest({
                                            id: rowData.id,
                                            data: {
                                                active: value
                                            }
                                        })
                                    )
                                }
                            />
                        )
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(requestPaging)}
                removingStatus={useAppSelector(requestTypeRemovingStatus)}
                onFetchList={fetchRequestTypes}
                onRemove={removeTypeRequest}
            />
        </LayoutOfPage>
    );
}
