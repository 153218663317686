import { useCallback } from 'react';
import { ISupportedFieldType } from '@/base/FormGenerator';
import CuForm, { ICuProps } from '@/components/CuForm';
import { useAppSelector } from '@/data/hooks';
import {
    INotificationEmailCUModel,
    INotificationEmailModel,
    NotificationEmailType
} from '@/data/NotificationEmail/EmailNotificationModels';
import { createNotificationEmail, updateNotificationEmail } from '@/data/NotificationEmail/NotificationEmailActions';
import {
    notificationEmailById,
    notificationEmailCreatingStatus,
    notificationEmailList,
    notificationEmailUpdatingStatus
} from '@/data/NotificationEmail/NotificationEmailSlice';
import LanguageEnum from '@/data/System/LanguageEnum';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';

type IProps = Omit<ICuProps<INotificationEmailModel>, 'resource'>;
type IDataModel = {
    type: INotificationEmailModel['type'];
    defaultLanguage: LanguageEnum | 'custom';
    body?: string;
    subject?: string;
} & Record<LanguageEnum, { subject: string; body: string }>;

const handleData = (id: number | undefined, data: IDataModel) =>
    ({
        type: data.type,
        subject: data.subject,
        body: data.subject,
        _translations: {
            ...(data.cs.subject !== ''
                ? {
                      cs: {
                          locale: 'cs',
                          subject: data.cs.subject,
                          body: data.cs.body
                      }
                  }
                : {}),
            ...(data.en.subject !== ''
                ? {
                      en: {
                          locale: 'en',
                          subject: data.en.subject,
                          body: data.en.body
                      }
                  }
                : {}),
            ...(data.en_gb.subject !== ''
                ? {
                      en_gb: {
                          locale: 'en_gb',
                          subject: data.en_gb.subject,
                          body: data.en_gb.body
                      }
                  }
                : {})
        }
    }) as INotificationEmailCUModel;

const NotificationEmailForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...rest }: IProps) => {
    const { t } = useAppTranslation();
    const allConfigs = useAppSelector(notificationEmailList);
    const data = useAppSelector((state) => notificationEmailById(state, id));

    const handleSubmit = useCallback(
        (entityId?: number) => (values: IDataModel) =>
            updateNotificationEmail({ id: entityId!, data: handleData(id, values) }),
        []
    );
    const handleCreate = useCallback(
        (entityId?: number) => (values: IDataModel) => createNotificationEmail(handleData(entityId, values)),
        []
    );

    return (
        <CuForm<INotificationEmailCUModel, INotificationEmailModel, number, true, IDataModel>
            {...rest}
            id={id}
            name="notificationEmail"
            title={t('label.notificationSetting', 'Notification Setting')}
            resource={PermissionsEnum.NotificationEmails}
            maxWidth="lg"
            creatingStatus={useAppSelector(notificationEmailCreatingStatus)}
            updatingStatus={useAppSelector(notificationEmailUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'select',
                    props: {
                        name: 'type',
                        required: true,
                        label: t('label.type', 'Type'),
                        options: Object.keys(NotificationEmailType)
                            .filter((entity) => !allConfigs.some((item) => item.type === entity))
                            .map((key) => ({
                                id: key,
                                label: t(`enums.notificationEmailTypes.${key}`, key)
                            })),
                        value: data?.type
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'subject',
                        required: true,
                        label: t('label.subject', 'Subject'),
                        value: data?.subject ?? ''
                    }
                },
                {
                    type: 'textArea',
                    props: {
                        name: 'body',
                        required: true,
                        minRows: 5,
                        label: t('label.body', 'Body'),
                        value: data?.body ?? ''
                    }
                },
                ...(Object.entries(LanguageEnum).map(([key, value]) => ({
                    type: 'collapse',
                    props: {
                        name: key,
                        expanded: false,
                        label: `${t('label.localization', 'Localization')} - ${t(`label.${key}`, key)}`,
                        inputs: [
                            {
                                type: 'textField',
                                props: {
                                    name: 'subject',
                                    required: (values) =>
                                        (values[key] as { body: string }).body !== '' ||
                                        (values[key] as { subject: string }).subject !== '',
                                    label: t('label.subject', 'Subject'),
                                    value: ((data?._translations ?? {})[value] ?? {})?.subject ?? '',
                                    validation: {
                                        deps: [`${key}.body`]
                                    }
                                }
                            },
                            {
                                type: 'textArea',
                                props: {
                                    name: 'body',
                                    minRows: 5,
                                    required: (values) =>
                                        (values[key] as { body: string }).body !== '' ||
                                        (values[key] as { subject: string }).subject !== '',
                                    label: t('label.body', 'Body'),
                                    value: data?._translations[value]?.body ?? '',
                                    validation: {
                                        deps: [`${key}.subject`]
                                    }
                                }
                            }
                        ]
                    }
                })) as ISupportedFieldType[])
            ]}
            onSubmitUpdate={handleSubmit(id)}
            onSubmitCreate={handleCreate(id)}
        />
    );
};

export default NotificationEmailForm;
