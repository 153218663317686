import { MouseEvent, useCallback, useState } from 'react';
import FormGenerator, { IOutputValueType } from '@/base/FormGenerator';
import { ICuProps } from '@/components/CuForm';
import UserPermission, { Mode } from '@/components/UserPermision';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { limitationOfAgentsAndEmptyShifts } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { periodById } from '@/data/Periods/PeriodSlice';
import { createSchedulePlan, updateSchedulePlan } from '@/data/SchedulePlans/SchedulePlanActions';
import { ISchedulePlanModel } from '@/data/SchedulePlans/SchedulePlanModels';
import {
    newSchedulePlanData,
    schedulePlanById,
    schedulePlanCreatingStatus,
    schedulePlanUpdatingStatus,
    setNewSchedulePlanData
} from '@/data/SchedulePlans/SchedulePlanSlice';
import { IRootState } from '@/data/store';
import { workplaceById } from '@/data/Workplaces/WorkplaceSlice';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import { getEmptyShiftFrom } from '@/helpers/schedule';
import useAppTranslation from '@/hooks/useAppTranslation';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import { message, regex } from '@/utils/validations';
import Button from '@/wrappers/Button';

type IProps = Pick<
    Omit<ICuProps<ISchedulePlanModel>, 'resource'>,
    'id' | 'justIcon' | 'displayAsModal' | 'displayAsSidebar' | 'subTitle'
> & {
    onSuccess?: (id: number) => void;
    periodId?: number;
    workplaceId?: number;
    isSchedulePlanClosed?: boolean;
};

const ScheduleForm = ({
    periodId,
    workplaceId,
    id,
    justIcon,
    isSchedulePlanClosed,
    displayAsModal,
    displayAsSidebar,
    onSuccess,
    subTitle
}: IProps) => {
    const isEdit = typeof id === 'number';
    const [isOpen, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useAppTranslation();
    const period = useAppSelector((state) => periodById(state, periodId ?? null));
    const workplace = useAppSelector((state) => workplaceById(state, workplaceId ?? null));

    const handleSuccessfulSubmit = (data: ISchedulePlanModel | null) => {
        if (data && onSuccess) {
            onSuccess(data.id);
        }

        setOpen(false);

        dispatch(
            addNotification({
                message: t('message.status.success', 'Success'),
                variant: 'success'
            })
        );
    };
    const handleSubmit = useCallback(
        (values: IOutputValueType) => {
            const preparedValues = {
                name: values.name as string,
                abbreviation: values.abbreviation as string,
                description: values.description as string,
                empty_shifts_from: values.empty_shifts_from
                    ? DateHelper.formatDate(values.empty_shifts_from as DateTimeType)
                    : isEdit
                    ? null
                    : undefined
            };

            if (isEdit) {
                if (periodId && workplaceId) {
                    dispatch(
                        updateSchedulePlan({
                            id,
                            data: { ...preparedValues, period_id: periodId, workplace_id: workplaceId }
                        })
                    )
                        .unwrap()
                        .then(handleSuccessfulSubmit);
                }
            } else {
                if (periodId && workplaceId) {
                    dispatch(createSchedulePlan({ ...preparedValues, period_id: periodId, workplace_id: workplaceId }))
                        .unwrap()
                        .then(handleSuccessfulSubmit);
                } else {
                    dispatch(setNewSchedulePlanData(preparedValues));
                    handleSuccessfulSubmit(null);
                }
            }
        },
        [isEdit, periodId, workplaceId]
    );
    const loadedData = useAppSelector((state: IRootState) => schedulePlanById(state, id ?? null));
    const newScheduleData = useAppSelector(newSchedulePlanData);
    const creatingStatus = useAppSelector(schedulePlanCreatingStatus);
    const updatingStatus = useAppSelector(schedulePlanUpdatingStatus);
    const agentsAndEmptyShift = useAppSelector(limitationOfAgentsAndEmptyShifts);

    const data = isEdit
        ? {
              name: loadedData?.name,
              abbreviation: loadedData?.abbreviation,
              description: loadedData?.description,
              empty_shifts_from: getEmptyShiftFrom(
                  undefined,
                  loadedData?.empty_shifts_from ?? undefined,
                  workplace?.time_zone
              )
          }
        : {
              ...newScheduleData,
              empty_shifts_from:
                  agentsAndEmptyShift === 'default' || !period
                      ? null
                      : getEmptyShiftFrom(period.period_start, undefined, workplace?.time_zone)
          };

    const handleCancel = useCallback(() => {
        setOpen(false);
    }, []);
    const handleOpen = useCallback(() => {
        setOpen(true);
        dispatch(fetchApplicationsSettingsItems());
    }, []);

    return (
        <UserPermission id={PermissionsEnum.SchedulePlans} mode={isEdit ? Mode.UPDATE : Mode.CREATE}>
            <FormGenerator
                name="schedule"
                isEdit={true}
                justIcon={justIcon || false}
                openButtonRender={(onClick: (e: MouseEvent) => void) => (
                    <Button
                        size="medium"
                        key="Edit"
                        name="edit"
                        color="primary"
                        data-testid="openDialog-schedule"
                        variant="contained"
                        onClick={onClick}
                    >
                        {t('label.edit', 'Edit')}
                    </Button>
                )}
                openForm={isOpen}
                onOpen={handleOpen}
                title="Update Schedule"
                subTitle={subTitle}
                displayAsModal={displayAsModal || false}
                displayAsSidebar={displayAsSidebar || false}
                openButtonValue="Edit"
                onSubmit={handleSubmit}
                fields={[
                    {
                        type: 'textField',
                        props: {
                            width: 8,
                            required: true,
                            name: 'name',
                            label: t('label.name', 'Name'),
                            value: data?.name,
                            validation: {
                                pattern: {
                                    value: regex.name,
                                    message: message.name
                                }
                            }
                        }
                    },
                    {
                        type: 'textField',
                        props: {
                            width: 4,
                            name: 'abbreviation',
                            label: t('label.abbreviation', 'Abbreviation'),
                            value: data?.abbreviation,
                            validation: {
                                pattern: {
                                    value: regex.name,
                                    message: message.name
                                }
                            }
                        }
                    },
                    {
                        type: 'textArea',
                        props: {
                            name: 'description',
                            label: 'Description',
                            value: data?.description,
                            validation: {
                                pattern: {
                                    value: regex.text,
                                    message: message.text
                                }
                            }
                        }
                    },
                    {
                        type: 'date',
                        display: () => agentsAndEmptyShift !== 'default',
                        props: {
                            name: 'empty_shifts_from',
                            label: t('label.emptyShiftFrom', 'Empty Shifts from'),
                            maxDate: period
                                ? DateHelper.fromDateString(period.period_start, workplace?.time_zone)
                                : undefined,
                            value: data?.empty_shifts_from
                        }
                    }
                ]}
                actions={[
                    {
                        type: 'button',
                        props: {
                            type: 'button',
                            name: 'cancel',
                            variant: 'text',
                            onClick: handleCancel,
                            children: 'Cancel'
                        }
                    },
                    {
                        type: 'loadingButton',
                        display: () => !isSchedulePlanClosed,
                        props: {
                            type: 'submit',
                            name: 'default',
                            disabled: updatingStatus === 'loading' || creatingStatus === 'loading',
                            variant: 'contained',
                            children: 'Update'
                        }
                    }
                ]}
            />
        </UserPermission>
    );
};

export default ScheduleForm;
