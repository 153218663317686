import { FieldsGenerator, IFieldsGeneratorProps } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getNeznamSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Neznam = (props: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getNeznamSettingsItems);
    const { t } = useAppTranslation();

    return (
        <FieldsGenerator
            {...props}
            name={SettingsTypesEnum.Neznam}
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'auth_url',
                        required: true,
                        label: t('label.authUrl', 'Auth URL'),
                        value: getSettingsItemValueByKey(data, 'auth_url')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'base_url',
                        required: true,
                        label: t('label.baseUrl', 'Base URL'),
                        value: getSettingsItemValueByKey(data, 'base_url')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'client_id',
                        required: true,
                        label: t('label.clientId', 'Client ID'),
                        value: getSettingsItemValueByKey(data, 'client_id')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'client_secret',
                        required: true,
                        label: t('label.clientSecret', 'Client Secret'),
                        value: getSettingsItemValueByKey(data, 'client_secret')
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'employees_uri',
                        required: true,
                        label: t('label.employeesUri', 'Employees URI'),
                        value: getSettingsItemValueByKey(data, 'employees_uri')
                    }
                }
            ]}
        />
    );
};

export default Neznam;
