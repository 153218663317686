import { IUserExtendedModel, IUserModel } from '@/data/Users/UserModels';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import unique from '@/helpers/array/unique';
import DateHelper, { DateTimeType } from '@/helpers/date/DateHelper';
import {
    IMergedColumn,
    IMergeRow,
    ISimpleColumn,
    RenderAbleBlock
} from '@/modules/Scheduler/components/SchedulerCalendarDataRow/SchedulerCalendarDataRow';
import {
    maxLengthOfColumnOnDayView,
    minLengthOfColumnOnDayView,
    minLengthOfColumnOnWeekView
} from '@/modules/Scheduler/scheduleConstants';
import SchedulerModeEnum from '@/utils/enums/SchedulerModeEnum';

export type ICellDataType<T = DateTimeType, EXT = {}> = {
    from: T;
    shift_joins: (EXT & {
        id: number;
        shift_id: number | null;
        shift_name: string | null;
        user_id: number | null;
        extra_padding_right: boolean;
        from: T;
        to: T;
        assigned_skills: number[];
    } & Pick<IUserModel, 'first_name' | 'middle_name' | 'last_name'>)[];
    vacations: (EXT & {
        id: number;
        from: T;
        to: T;
    })[];
    requests: (EXT & {
        id: number;
        from: T;
        to: T;
    })[];
};

export type IWorkerData = {
    from: string;
    now: string;
    to: string;
    isDayMode: boolean;
    usersWithShift:
        | Pick<
              IUserExtendedModel,
              | 'id'
              | 'first_name'
              | 'last_name'
              | 'middle_name'
              | 'username'
              | 'user_to_roles'
              | 'user_to_requests'
              | 'user_to_skills'
              | 'user_to_vacations'
          >[]
        | null;
    preparedBody: ICellDataType<string, { user_id: number | null }>[];
    allowedShifts?: number[];
    timeZoneOffset: number;
    timeZone: string;
};

const parseInputsOfWorker = (params: IWorkerData) => {
    const now = DateHelper.setTimeZone(DateHelper.fromDateTimeString(params.now), params.timeZone),
        from = DateHelper.setTimeZone(DateHelper.fromDateTimeString(params.from), params.timeZone),
        to = DateHelper.setTimeZone(DateHelper.fromDateTimeString(params.to), params.timeZone);

    return {
        from,
        to,
        now,
        allowedShifts: params.allowedShifts ?? null,
        isDayMode: params.isDayMode,
        preparedBody: params.preparedBody
            .map((item) => ({
                ...item,
                from: DateHelper.setTimeZone(DateHelper.fromDateTimeString(item.from), params.timeZone),
                shift_joins: item.shift_joins.map((shiftJoin) => ({
                    ...shiftJoin,
                    from: DateHelper.setTimeZone(DateHelper.fromDateTimeString(shiftJoin.from), params.timeZone),
                    to:
                        shiftJoin.to === null
                            ? now
                            : DateHelper.setTimeZone(DateHelper.fromDateTimeString(shiftJoin.to), params.timeZone)
                })),
                vacations: item.vacations.map((vacation) => ({
                    ...vacation,
                    from: DateHelper.ceilToNearestQuarterMinutes(
                        DateHelper.setTimeZone(DateHelper.fromDateTimeString(vacation.from), params.timeZone)
                    ),
                    to: DateHelper.ceilToNearestQuarterMinutes(
                        DateHelper.setTimeZone(DateHelper.fromDateTimeString(vacation.to), params.timeZone)
                    )
                })),
                requests: item.requests.map((request) => ({
                    ...request,
                    from: DateHelper.setTimeZone(DateHelper.fromDateTimeString(request.from), params.timeZone),
                    to: DateHelper.setTimeZone(DateHelper.fromDateTimeString(request.to), params.timeZone)
                }))
            }))
            .filter((item) => DateHelper.isBetween(item.from, from, to, '[)'))
            .map((item) => ({
                ...item,
                shift_joins: item.shift_joins.filter(
                    ({ id }) => !Array.isArray(params.allowedShifts) || params.allowedShifts.includes(id)
                )
            }))
            .reduce(
                (accumulator, currentValue) => ({
                    requests: [...accumulator.requests, ...currentValue.requests],
                    shift_joins: [...accumulator.shift_joins, ...currentValue.shift_joins],
                    vacations: [...accumulator.vacations, ...currentValue.vacations]
                }),
                { requests: [], shift_joins: [], vacations: [] } as Omit<
                    ICellDataType<DateTimeType, { user_id: number | null }>,
                    'from'
                >
            ),
        timeZone: params.timeZone,
        usersWithShift: params.usersWithShift
    };
};
const fillPrefixDate = <DATE_TYPE extends DateTimeType>(
    columnStart: number,
    from: DATE_TYPE,
    to: DATE_TYPE,
    isDay: boolean,
    timeZone: string,
    userId: number | null = null
): ISimpleColumn<DATE_TYPE>[] => {
    const result: ISimpleColumn<DATE_TYPE>[] = [];
    const isoFrom = DateHelper.formatISO(from);

    let currentDateTime = DateHelper.clone(from);
    let newColumnStart = columnStart;

    while (DateHelper.isBefore(currentDateTime, to)) {
        const minutesToEnd = DateHelper.getDifferenceAsMinutes(currentDateTime, to);
        const nextDay = DateHelper.getFirstMomentOfDay(DateHelper.addDays(currentDateTime, 1));
        const minutesToNextDay = DateHelper.getDifferenceAsMinutes(currentDateTime, nextDay);

        const end = isDay
            ? DateHelper.addMinutes(
                  currentDateTime,
                  Math.max(
                      Math.min(minutesToEnd, minutesToNextDay, maxLengthOfColumnOnDayView),
                      minLengthOfColumnOnDayView
                  )
              )
            : DateHelper.isBefore(nextDay, to)
            ? nextDay
            : to;

        const width = isDay
            ? DateHelper.isBefore(end, currentDateTime)
                ? 3
                : DateHelper.getDifferenceAsMinutes(currentDateTime, end) / minLengthOfColumnOnDayView
            : Math.max(Math.min(minutesToEnd, minutesToNextDay, 96 * 15) / minLengthOfColumnOnWeekView, 1);

        result.push({
            uId: `${isoFrom}_${result.length}_fillPrefix`,
            columnStart: newColumnStart,
            type: 'simple',
            from: currentDateTime,
            to: end,
            width,
            data: {
                uId: `${isoFrom}_${result.length}_fillPrefix`,
                id: result.length,
                from: currentDateTime,
                to: end,
                type: 'empty',
                paddingRight: false,
                width,
                userId,
                shiftId: null,
                shiftName: null
            }
        });
        newColumnStart += width;

        currentDateTime = end;
    }

    return result;
};

const hasOverlap = <DATE_TYPE extends DateTimeType | Date>(
    fromA: DATE_TYPE,
    toA: DATE_TYPE,
    fromB: DATE_TYPE,
    toB: DATE_TYPE,
    isShiftTradeMode: boolean
) =>
    DateHelper.isBetween(fromA, fromB, toB, isShiftTradeMode ? undefined : '[)') ||
    DateHelper.isBetween(fromB, fromA, toA, isShiftTradeMode ? undefined : '[)');

const generateColumns = <DATE_TYPE extends DateTimeType>(
    from: DATE_TYPE,
    to: DATE_TYPE,
    isDayMode: boolean,
    data: Omit<ICellDataType<DATE_TYPE, { user_id: number | null }>, 'from'>,
    timeZone: string,
    mode: SchedulerModeEnum,
    isEmptyShift = false,
    userId: number | null = null
) => {
    const items: RenderAbleBlock<DATE_TYPE>[] = [];
    const isShiftTradeMode = mode === SchedulerModeEnum.ShiftTrades;

    (
        [
            ...unique(data.shift_joins, (item) => item.id).map((shiftJoin, shiftJoinIndex) => ({
                ...shiftJoin,
                type: 'shift',
                hasOverride:
                    data.vacations.some((vacation) =>
                        hasOverlap(vacation.from, vacation.to, shiftJoin.from, shiftJoin.to, isShiftTradeMode)
                    ) ||
                    data.requests.some((request) =>
                        hasOverlap(request.from, request.to, shiftJoin.from, shiftJoin.to, isShiftTradeMode)
                    ) ||
                    data.shift_joins.some(
                        (subShiftJoin, subIndex) =>
                            subIndex !== shiftJoinIndex &&
                            hasOverlap(
                                subShiftJoin.from,
                                subShiftJoin.to,
                                shiftJoin.from,
                                shiftJoin.to,
                                isShiftTradeMode
                            )
                    )
            })),
            ...unique(data.vacations, (item) => item.id).map((vacation) => ({
                ...vacation,
                type: 'vacation',
                hasOverride:
                    data.shift_joins.some((shiftJoin) =>
                        hasOverlap(shiftJoin.from, shiftJoin.to, vacation.from, vacation.to, isShiftTradeMode)
                    ) ||
                    data.requests.some((request) =>
                        hasOverlap(request.from, request.to, vacation.from, vacation.to, isShiftTradeMode)
                    ) ||
                    data.vacations.some((subVacation) =>
                        hasOverlap(subVacation.from, subVacation.to, vacation.from, vacation.to, isShiftTradeMode)
                    )
            })),
            ...data.requests.map((request) => ({
                ...request,
                type: 'request',
                hasOverride:
                    data.shift_joins.some((shiftJoin) =>
                        hasOverlap(shiftJoin.from, shiftJoin.to, request.from, request.to, isShiftTradeMode)
                    ) ||
                    data.requests.some((subRequest) =>
                        hasOverlap(subRequest.from, subRequest.to, request.from, request.to, isShiftTradeMode)
                    ) ||
                    data.vacations.some((subVacation) =>
                        hasOverlap(subVacation.from, subVacation.to, request.from, request.to, isShiftTradeMode)
                    )
            }))
        ] as (ArrayElement<RenderAbleBlock<DATE_TYPE>['items']> & { hasOverride: boolean })[]
    )
        .sort((a, b) => (DateHelper.isBefore(a.from, b.from) ? -1 : 1))
        .map(
            ({ hasOverride, ...restItem }) =>
                ({
                    uId: `${restItem.type}_${restItem.id}`,
                    id: restItem.id,
                    from: DateHelper.isBefore(restItem.from, from) ? from : restItem.from,
                    to: DateHelper.isAfter(restItem.to, to) ? to : restItem.to,
                    toMerge: hasOverride,
                    items: [
                        {
                            ...restItem,
                            from: DateHelper.isBefore(restItem.from, from) ? from : restItem.from,
                            to: DateHelper.isAfter(restItem.to, to) ? to : restItem.to
                        }
                    ]
                }) as RenderAbleBlock<DATE_TYPE>
        )
        .forEach((currentValue) => {
            if (items.length === 0 || !currentValue.toMerge) {
                items.push(currentValue);
            } else {
                let override = false;

                items.forEach((item) => {
                    const overridingThisItem =
                        DateHelper.isBetween(
                            currentValue.from,
                            item.from,
                            item.to,
                            isShiftTradeMode ? undefined : '[)'
                        ) ||
                        DateHelper.isBetween(
                            item.from,
                            currentValue.from,
                            currentValue.to,
                            isShiftTradeMode ? undefined : '[)'
                        );

                    if (overridingThisItem) {
                        override = true;

                        item.to = DateHelper.isAfter(currentValue.to, item.to) ? currentValue.to : item.to;
                        item.items.push(...currentValue.items);
                    }
                });

                if (!override) {
                    items.push(currentValue);
                }
            }
        });

    let start = from;
    const result: (ISimpleColumn<DATE_TYPE> | IMergedColumn<DATE_TYPE>)[] = [];
    let startColumn = 0;

    if (items.length !== 0) {
        if (isEmptyShift) {
            items.forEach((dataItem, index) => {
                dataItem.items.push({
                    type: 'empty',
                    uId: `empty_${index}`,
                    id: dataItem.items.length,
                    extra_padding_right: false,
                    from: dataItem.from,
                    to: dataItem.to,
                    width: 0,
                    shift_id: null,
                    shift_name: null,
                    user_id: userId
                });
            });
        }

        items.forEach((dataItem) => {
            result.push(...fillPrefixDate(startColumn, start, dataItem.from, isDayMode, timeZone, userId ?? null));
            start = dataItem.from;
            if (DateHelper.isBefore(start, from)) {
                console.warn('before', { start, from });
                start = from;
            } else if (result.length) {
                const last = result[result.length - 1];

                startColumn = last.columnStart + last.width;
            }

            const width =
                Math.ceil(
                    DateHelper.getDifferenceAsMinutes(start, dataItem.to) /
                        (isDayMode ? minLengthOfColumnOnDayView : maxLengthOfColumnOnDayView)
                ) - (isDayMode ? 0 : (start.utcOffset() - dataItem.to.utcOffset()) / maxLengthOfColumnOnDayView);

            if (dataItem.items.length === 1 && dataItem.items[0].type === 'request') {
                dataItem.items.push({
                    type: 'empty',
                    uId: `empty_request_${dataItem.items[0].id}`,
                    id: dataItem.items[0].id,
                    extra_padding_right: false,
                    from: dataItem.items[0].from,
                    to: dataItem.items[0].to,
                    width: 0,
                    shift_id: null,
                    shift_name: null,
                    user_id: null
                });
            }

            if (dataItem.items.length === 1) {
                result.push({
                    uId: dataItem.uId,
                    type: 'simple',
                    columnStart: startColumn,
                    from: start,
                    to: dataItem.to,
                    width,
                    data: {
                        type: dataItem.items[0].type,
                        uId: dataItem.uId,
                        id: dataItem.id,
                        from: start,
                        to: dataItem.to,
                        paddingRight: dataItem.items[0].extra_padding_right,
                        shiftId: dataItem.items[0].shift_id,
                        shiftName: dataItem.items[0].shift_name,
                        userId: dataItem.items[0].user_id,
                        width
                    }
                });

                start = dataItem.to;
                startColumn += width;
            } else {
                const mappedRows: IMergeRow<DATE_TYPE>[] = [];

                dataItem.items
                    .reduce<IMergeRow<DATE_TYPE>[]>((previousValue, currentValue) => {
                        if (previousValue.length === 0) {
                            return [
                                {
                                    uId: 'row-0',
                                    from: currentValue.from,
                                    to: currentValue.to,
                                    data: [
                                        {
                                            from: currentValue.from,
                                            id: currentValue.id,
                                            to: currentValue.to,
                                            uId: `${currentValue.id}`,
                                            type: currentValue.type,
                                            paddingRight: currentValue.extra_padding_right,
                                            shiftId: currentValue.shift_id,
                                            shiftName: currentValue.shift_name,
                                            userId: currentValue.user_id,
                                            width: 0
                                        }
                                    ]
                                }
                            ];
                        } else {
                            let isMerged = false;

                            const newValue = previousValue.map((row) => {
                                const overridingThisItem =
                                    DateHelper.isBetween(currentValue.from, row.from, row.to) ||
                                    DateHelper.isBetween(row.from, currentValue.from, currentValue.to);

                                if (!overridingThisItem && !isMerged) {
                                    isMerged = true;

                                    return {
                                        ...row,
                                        to: DateHelper.isBefore(row.to, currentValue.to) ? currentValue.to : row.to,
                                        data: [
                                            ...row.data,
                                            {
                                                from: currentValue.from,
                                                id: currentValue.id,
                                                to: currentValue.to,
                                                uId: `${currentValue.id}`,
                                                type: currentValue.type,
                                                paddingRight: currentValue.extra_padding_right,
                                                shiftId: currentValue.shift_id,
                                                shiftName: currentValue.shift_name,
                                                userId: currentValue.user_id,
                                                width: 0
                                            }
                                        ]
                                    };
                                } else {
                                    return row;
                                }
                            });

                            if (!isMerged) {
                                return [
                                    ...previousValue,
                                    {
                                        uId: `row-${previousValue.length}`,
                                        from: currentValue.from,
                                        to: currentValue.to,
                                        data: [
                                            {
                                                from: currentValue.from,
                                                id: currentValue.id,
                                                to: currentValue.to,
                                                uId: `${currentValue.id}`,
                                                type: currentValue.type,
                                                paddingRight: currentValue.extra_padding_right,
                                                shiftId: currentValue.shift_id,
                                                shiftName: currentValue.shift_name,
                                                userId: currentValue.user_id,
                                                width: 0
                                            }
                                        ]
                                    }
                                ];
                            } else return newValue;
                        }
                    }, [])
                    .forEach((row) => {
                        let rowStart = start;
                        const mapped: IMergeRow<DATE_TYPE>['data'] = [];

                        row.data.forEach((rowItem) => {
                            mapped.push(
                                ...fillPrefixDate(
                                    startColumn,
                                    rowStart,
                                    rowItem.from,
                                    isDayMode,
                                    timeZone,
                                    userId ?? null
                                ).map((item) => item.data)
                            );

                            const overMidnight = DateHelper.isBefore(dataItem.from, start);

                            if (!overMidnight) {
                                mapped.push({
                                    ...rowItem,
                                    width: Math.ceil(DateHelper.getDifferenceAsMinutes(rowItem.from, rowItem.to) / 15)
                                });
                            }

                            rowStart = rowItem.to;
                        });
                        mapped.push(
                            ...fillPrefixDate(
                                startColumn,
                                row.to,
                                dataItem.to,
                                isDayMode,
                                timeZone,
                                userId ?? null
                            ).map((item) => item.data)
                        );

                        if (mapped.length) {
                            mappedRows.push({
                                uId: row.uId,
                                from: row.from,
                                to: row.to,
                                data: mapped
                            });
                        }
                    });

                start = dataItem.to;

                if (mappedRows.length) {
                    result.push({
                        uId: dataItem.uId,
                        type: 'merged',
                        columnStart: startColumn,
                        from: dataItem.from,
                        to: dataItem.to,
                        width,
                        data: mappedRows
                    });
                }

                startColumn += width;
            }
        });
    }

    result.push(...fillPrefixDate(startColumn, start, to, isDayMode, timeZone, userId ?? null));

    return result.map((item) => ({
        ...item,
        from: DateHelper.toDate(item.from),
        to: DateHelper.toDate(item.to),
        data:
            item.type === 'simple'
                ? {
                      ...item.data,
                      from: DateHelper.toDate(item.data.from),
                      to: DateHelper.toDate(item.data.to)
                  }
                : item.data.map((dataItem) => ({
                      ...dataItem,
                      from: DateHelper.toDate(dataItem.from),
                      to: DateHelper.toDate(dataItem.to),
                      data: dataItem.data.map((row) => ({
                          ...row,
                          from: DateHelper.toDate(row.from),
                          to: DateHelper.toDate(row.to)
                      }))
                  }))
    }));
};

const runWorker = (inputs: IWorkerData, mode: SchedulerModeEnum) => {
    const { from, isDayMode, preparedBody, timeZone, to, usersWithShift } = parseInputsOfWorker(inputs);

    const columnsFromToData = {
        requests: unique(preparedBody.requests, (item) => item.id),
        shift_joins: unique(preparedBody.shift_joins, (item) => item.id),
        vacations: unique(preparedBody.vacations, (item) => item.id)
    };

    const users: (ArrayElement<NonNullable<IWorkerData['usersWithShift']>> & {
        columns: ReturnType<typeof generateColumns>;
        recommendedSkills: any[];
    })[] = [];

    usersWithShift?.forEach((userWithShift) => {
        const byUser = {
            requests: columnsFromToData.requests.filter(({ user_id }) => user_id === userWithShift.id),
            shift_joins: columnsFromToData.shift_joins.filter(({ user_id }) => user_id === userWithShift.id),
            vacations: columnsFromToData.vacations.filter(({ user_id }) => user_id === userWithShift.id)
        };

        const columns = generateColumns(from, to, isDayMode, byUser, timeZone, mode, false, userWithShift.id);

        users.push({
            ...userWithShift,
            columns,
            recommendedSkills: []
        });
    });

    return {
        users,
        unAssigned: generateColumns(
            from,
            to,
            isDayMode,
            {
                requests: columnsFromToData.requests.filter(({ user_id }) => user_id === null),
                shift_joins: columnsFromToData.shift_joins.filter(({ user_id }) => user_id === null),
                vacations: columnsFromToData.vacations.filter(({ user_id }) => user_id === null)
            },
            timeZone,
            mode,
            true,
            null
        )
    };
};

export { fillPrefixDate, runWorker };
