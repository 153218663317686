import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { breakEntities } from '@/data/Breaks/BreakSlice';
import {
    calculateSchedulePlan,
    clearShiftOfSchedulePlan,
    fetchLogInToPlan,
    fetchLogOutFromPlan,
    fetchSchedulePlanById,
    recalculateSchedulePlan,
    recalculateSchedulePlanWithShifts,
    removeSchedulePlanFromStore
} from '@/data/SchedulePlans/SchedulePlanActions';
import { shiftById, shiftEntities } from '@/data/Shifts/ShiftSlice';
import {
    agreeWithOfferedShift,
    fetchShiftTradeOffersByAssignedShift
} from '@/data/ShiftTradeOffers/ShiftTradeOfferActions';
import {
    approveTrade,
    fetchShiftTradesByAssignedShift,
    takeThisShiftFromTrade
} from '@/data/ShiftTrades/ShiftTradeActions';
import { skillEntities } from '@/data/Skills/SkillSlice';
import { IRootState } from '@/data/store';
import { userById, userEntities } from '@/data/Users/UserSlice';
import { patchStateToRequest } from '@/data/UserToRequests/UserToRequestActions';
import {
    assignShiftIntoDay,
    assignShiftToSignedUser,
    declineShiftToSignedUser,
    detachShiftFromDay,
    moveShift,
    updateAssignedShift
} from './SchedulePlanDayShiftActions';
import {
    ISchedulePlanDayShiftExtendedModel,
    ISchedulePlanDayShiftFromBEModel,
    ISchedulePlanDayShiftModel,
    ISchedulePlanDayShiftWithoutDefinitionExtendedModel,
    ISchedulePlanDayShiftWithoutDefinitionModel
} from './SchedulePlanDayShiftModels';

type IState = {
    assignState: IRequestState;
    loadingCalendarListStatus: IRequestState;
    updateAssignedShiftState: IRequestState;
    removeAssignedShiftState: IRequestState;
};

const initialState: IState = {
    assignState: 'idle',
    loadingCalendarListStatus: 'idle',
    updateAssignedShiftState: 'idle',
    removeAssignedShiftState: 'idle'
};

const adapter = createEntityAdapter<ISchedulePlanDayShiftModel | ISchedulePlanDayShiftWithoutDefinitionModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.created.localeCompare(b.created)
});

const schedulePlanDayShiftSlice = createSlice({
    name: 'schedulePlanDayShifts',
    initialState: adapter.getInitialState(initialState),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedulePlanById.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                adapter.addMany(
                    state,
                    [
                        ...action.payload.schedule_plan_day_shifts,
                        ...action.payload.schedule_plan_day_shifts_without_definition
                    ]
                        .map(
                            ({
                                schedule_plan_day_shift_delays,
                                schedule_plan_day_shift_trades,
                                schedule_plan_day_shift_trade_offers,
                                ...item
                            }) => item
                        )
                        .flat()
                );
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
            })
            .addCase(patchStateToRequest.fulfilled, (state, action) => {
                if (action.payload.schedule_plan_day_shifts) {
                    /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                    adapter.upsertMany(
                        state,
                        action.payload.schedule_plan_day_shifts.map(
                            ({
                                schedule_plan_day_shift_delays,
                                schedule_plan_day_shift_trades,
                                schedule_plan_day_shift_trade_offers,
                                user,
                                ...item
                            }: ISchedulePlanDayShiftFromBEModel) => item as ISchedulePlanDayShiftModel
                        )
                    );
                    /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                }
            })
            .addCase(recalculateSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayShiftModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(recalculateSchedulePlanWithShifts.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayShiftModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(clearShiftOfSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayShiftModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(calculateSchedulePlan.fulfilled, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter(
                            (item) => item?.schedule_plan_id === action.meta.arg
                        ) as ISchedulePlanDayShiftModel[]
                    ).map(({ id }) => id)
                );
            })
            .addCase(fetchShiftTradeOffersByAssignedShift.fulfilled, (state, action) => {
                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                adapter.upsertMany(
                    state,
                    action.payload.data.map(({ schedule_plan_day_shift }) => {
                        const { shift, user, ...item } = schedule_plan_day_shift;

                        return item;
                    })
                );
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
            })
            .addCase(fetchShiftTradesByAssignedShift.fulfilled, (state, action) => {
                adapter.upsertMany(
                    state,
                    action.payload.data.map(({ schedule_plan_day_shift }) => {
                        /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                        const { user, ...item } = schedule_plan_day_shift;
                        /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                        return item;
                    })
                );
            })
            .addCase(approveTrade.fulfilled, (state, action) => {
                adapter.updateOne(state, {
                    id: action.payload.schedule_plan_day_shift_id,
                    changes: { user_id: action.payload.selected_offer.schedule_plan_day_shift.user_id }
                });
                adapter.updateOne(state, {
                    id: action.payload.selected_offer.schedule_plan_day_shift_id,
                    changes: { user_id: action.payload.schedule_plan_day_shift.user_id }
                });
            })
            .addCase(takeThisShiftFromTrade.fulfilled, (state, action) => {
                adapter.updateOne(state, {
                    id: action.meta.arg.id,
                    changes: { user_id: action.payload.signedUserId }
                });
            });
        builder
            .addCase(assignShiftIntoDay.pending, (state) => {
                state.assignState = 'loading';
            })
            .addCase(assignShiftIntoDay.fulfilled, (state, action) => {
                state.assignState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    schedule_plan_days,
                    ...assignedShift
                } = action.payload;

                adapter.addOne(state, assignedShift);
            })
            .addCase(assignShiftIntoDay.rejected, (state) => {
                state.assignState = 'failed';
            })
            .addCase(updateAssignedShift.pending, (state) => {
                state.updateAssignedShiftState = 'loading';
            })
            .addCase(updateAssignedShift.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    skills,
                    roles,
                    schedule_plan_days,
                    body,
                    table_body,
                    ...assignedShift
                } = action.payload.new;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.updateOne(state, {
                    id: action.meta.arg.id,
                    changes: assignedShift
                });
            })
            .addCase(updateAssignedShift.rejected, (state) => {
                state.updateAssignedShiftState = 'failed';
            })
            .addCase(moveShift.pending, (state) => {
                state.updateAssignedShiftState = 'loading';
            })
            .addCase(moveShift.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    onlyUpdated,
                    removeOriginal,
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    body,
                    skills,
                    roles,
                    schedule_plan_days,
                    ...assignedShift
                } = action.payload;

                adapter.updateOne(state, {
                    id: action.meta.arg.id,
                    changes: {
                        user_id: assignedShift.user_id
                    }
                });
            })
            .addCase(moveShift.rejected, (state) => {
                state.updateAssignedShiftState = 'failed';
            })
            .addCase(assignShiftToSignedUser.pending, (state) => {
                state.updateAssignedShiftState = 'loading';
            })
            .addCase(assignShiftToSignedUser.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    skills,
                    body,
                    table_body,
                    schedule_plan_days,
                    roles,
                    ...assignedShift
                } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.updateOne(state, {
                    id: action.meta.arg.schedulePlanDayShiftId,
                    changes: assignedShift
                });
            })
            .addCase(fetchLogInToPlan.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    ...assignedShift
                } = action.payload.shift;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.addOne(state, assignedShift);
            })
            .addCase(fetchLogOutFromPlan.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    ...assignedShift
                } = action.payload.shift;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.updateOne(state, { id: assignedShift.id, changes: assignedShift });
            })
            .addCase(assignShiftToSignedUser.rejected, (state) => {
                state.updateAssignedShiftState = 'failed';
            })
            .addCase(declineShiftToSignedUser.pending, (state) => {
                state.updateAssignedShiftState = 'loading';
            })
            .addCase(declineShiftToSignedUser.fulfilled, (state, action) => {
                state.updateAssignedShiftState = 'idle';

                /* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */
                const {
                    schedule_plan_day_shift_delays,
                    schedule_plan_day_shift_trades,
                    schedule_plan_day_shift_trade_offers,
                    ...assignedShift
                } = action.payload;
                /* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars */

                adapter.updateOne(state, {
                    id: action.meta.arg.schedulePlanDayShiftId,
                    changes: assignedShift
                });
            })
            .addCase(agreeWithOfferedShift.fulfilled, (state, action) => {
                if (action.payload?.plan) {
                    adapter.updateOne(state, {
                        id: action.payload.shift.schedule_plan_day_shift_id,
                        changes: { user_id: action.payload.shift.user_id }
                    });
                    adapter.updateOne(state, {
                        id: action.payload.shiftTrade.schedule_plan_day_shift_id,
                        changes: { user_id: action.payload.shiftTrade.user_id }
                    });
                }
            })
            .addCase(declineShiftToSignedUser.rejected, (state) => {
                state.updateAssignedShiftState = 'failed';
            })
            .addCase(detachShiftFromDay.pending, (state) => {
                state.removeAssignedShiftState = 'loading';
            })
            .addCase(detachShiftFromDay.fulfilled, (state, action) => {
                state.removeAssignedShiftState = 'idle';

                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(detachShiftFromDay.rejected, (state) => {
                state.removeAssignedShiftState = 'failed';
            })
            .addCase(removeSchedulePlanFromStore, (state, action) => {
                adapter.removeMany(
                    state,
                    (
                        Object.values(state.entities).filter((item) => item?.schedule_plan_id === action.payload) as {
                            id: number;
                        }[]
                    ).map(({ id }) => id)
                );
            });
    }
});

const getState = (state: IRootState) => state[schedulePlanDayShiftSlice.name];
const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export default schedulePlanDayShiftSlice;

export const workingFromHomeBySchedulePlanDayShift = (state: IRootState, schedulePlanDayShiftId: number | null) =>
    schedulePlanDayShiftId !== null
        ? adapterSelectors.selectById(state, schedulePlanDayShiftId)?.work_from_home ?? false
        : false;
export const schedulePlanDayShiftAll = (state: IRootState) => adapterSelectors.selectAll(state);
export const schedulePlanDayShiftById = (state: IRootState, id: number | null) =>
    id ? adapterSelectors.selectById(state, id) ?? null : null;
export const shiftIdOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    schedulePlanDayShiftById(state, id)?.shift_id ?? null;
export const startOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    schedulePlanDayShiftById(state, id)?.shift_start ?? null;
export const endOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    schedulePlanDayShiftById(state, id)?.shift_end ?? null;
export const shiftBySchedulePlanDayShift = (state: IRootState, id: number | null) => {
    const item = schedulePlanDayShiftById(state, id);

    if (item && item.shift_id) {
        return shiftById(state, item.shift_id) ?? null;
    }

    return null;
};
export const abbreviationOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    shiftBySchedulePlanDayShift(state, id)?.abbreviation ?? '';
export const nameOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    shiftBySchedulePlanDayShift(state, id)?.name ?? null;
export const backgroundOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    shiftBySchedulePlanDayShift(state, id)?.background ?? null;
export const colorOfSchedulePlanDayShift = (state: IRootState, id: number | null) =>
    shiftBySchedulePlanDayShift(state, id)?.color ?? null;

const selectSchedulePlanDayShiftsBySchedulePlanEntities = createSelector(
    adapterSelectors.selectAll,
    (schedulePlanDays) => {
        const result = {} as Record<
            number,
            (ISchedulePlanDayShiftModel | ISchedulePlanDayShiftWithoutDefinitionModel)[]
        >;

        schedulePlanDays.forEach((schedulePlanDay) => {
            if (schedulePlanDay.schedule_plan_id in result)
                result[schedulePlanDay.schedule_plan_id].push(schedulePlanDay);
            else result[schedulePlanDay.schedule_plan_id] = [schedulePlanDay];
        });

        return result;
    }
);

export const selectSchedulePlanDayShiftsBySchedulePlanById = (state: IRootState, schedulePlanId: number | null) =>
    schedulePlanId !== null ? selectSchedulePlanDayShiftsBySchedulePlanEntities(state)[schedulePlanId] ?? null : null;

export const userBySchedulePlanDayShift = (state: IRootState, id: number | null) => {
    const item = schedulePlanDayShiftById(state, id);

    if (item) {
        return userById(state, item.user_id ?? undefined) ?? null;
    }

    return null;
};

export const removeAssignedShiftStateStatus = (state: IRootState) => getState(state).removeAssignedShiftState;
export const updateAssignedShiftStatus = (state: IRootState) => getState(state).updateAssignedShiftState;

export const selectSchedulePlanDayShiftEntities = createSelector(
    (state: IRootState) => schedulePlanDayShiftAll(state),
    (state: IRootState) => shiftEntities(state),
    (state: IRootState) => userEntities(state),
    (state: IRootState) => breakEntities(state),
    (state: IRootState) => skillEntities(state),
    (schedulePlanDayShifts, shifts, users, breaks, skills) => {
        const result: Record<
            number,
            ISchedulePlanDayShiftExtendedModel | ISchedulePlanDayShiftWithoutDefinitionExtendedModel
        > = {};

        schedulePlanDayShifts.forEach((schedulePlanDayShift) => {
            const shift = schedulePlanDayShift.shift_id ? shifts[schedulePlanDayShift.shift_id] ?? null : null;
            const user = schedulePlanDayShift.user_id ? users[schedulePlanDayShift.user_id] ?? null : null;

            if (
                (shift && schedulePlanDayShift.shift_id) ||
                (shift === null && schedulePlanDayShift.shift_id === null)
            ) {
                const newItem = {
                    ...schedulePlanDayShift,
                    shift,
                    schedule_plan_day_shift_breaks:
                        schedulePlanDayShift.schedule_plan_day_shift_breaks?.map((item) => ({
                            ...item,
                            break: breaks[item.break_id] ?? null
                        })) ?? [],
                    schedule_plan_day_shift_skills:
                        schedulePlanDayShift.schedule_plan_day_shift_skills?.map((item) => ({
                            ...item,
                            skill: skills[item.skill_id]
                        })) ?? [],
                    user
                };

                if (
                    !newItem.schedule_plan_day_shift_breaks.some((item) => item.break === null) &&
                    !newItem.schedule_plan_day_shift_skills.some((item) => item.skill === null)
                ) {
                    result[schedulePlanDayShift.id] = newItem as
                        | ISchedulePlanDayShiftExtendedModel
                        | ISchedulePlanDayShiftWithoutDefinitionExtendedModel;
                }
            }
        });

        return result;
    }
);
export const selectSchedulePlanDayShiftById = (
    state: IRootState,
    id: number
): ISchedulePlanDayShiftExtendedModel | ISchedulePlanDayShiftWithoutDefinitionExtendedModel | null =>
    selectSchedulePlanDayShiftEntities(state)[id] ?? null;
