import Paper from '@mui/material/Paper';
import { useCallback, useEffect, useMemo } from 'react';
import { ISupportedValueType, IValuesOfSimpleFields, IValueType } from '@/base/FormGenerator';
import CuForm, { ICuProps, IOutputValueType } from '@/components/CuForm';
import { fetchBreaksForSelect } from '@/data/Breaks/BreakActions';
import { breaksForSelect } from '@/data/Breaks/BreakSlice';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { queuesForSelect } from '@/data/Queues/QueueSlice';
import { fetchGooglesSettings } from '@/data/SettingsItems/SettingsItemActions';
import { isGoogleEnabled } from '@/data/SettingsItems/SettingsItemSlice';
import { fetchShiftsForSelect } from '@/data/Shifts/ShiftActions';
import { shiftsForSelect } from '@/data/Shifts/ShiftSlice';
import { IShiftToWorkplaceModel } from '@/data/ShiftToWorkplaces/ShiftToWorkplaceModels';
import { fetchSkillsForSelect } from '@/data/Skills/SkillActions';
import { skillsForSelect } from '@/data/Skills/SkillSlice';
import { fetchUsersForSelect } from '@/data/Users/UserActions';
import { usersForSelect } from '@/data/Users/UserSlice';
import { IUserToWorkplaceModel } from '@/data/UserToWorkplaces/UserToWorkplaceModels';
import { createWorkplace, fetchWorkplaceById, updateWorkplace } from '@/data/Workplaces/WorkplaceActions';
import {
    IWorkplaceCUModel,
    IWorkplaceModel,
    IWorkplaceSkillsWeightCUModel,
    IWorkplaceWorkingHoursCUModel
} from '@/data/Workplaces/WorkplaceModels';
import {
    selectWorkplaceById,
    updatePaging,
    workplaceCreatingStatus,
    workplacePaging,
    workplaceUpdatingStatus
} from '@/data/Workplaces/WorkplaceSlice';
import list from '@/helpers/countries/list';
import DateHelper from '@/helpers/date/DateHelper';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import useTranslatedDays from '@/hooks/useTranslatedDays';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ValidationEnum from '@/utils/enums/ValidationEnum';
import WorkplaceAlgorithmTypeEnum from '@/utils/enums/WorkplaceAlgorithmTypeEnum';
import { serializeUser } from '@/utils/UserHelper';
import { message, regex } from '@/utils/validations';
import { ITimePickerProps } from '@/wrappers/TimePicker';
import { ITimeRangeOrTimeAndLengthProps } from '@/wrappers/TimeRangeOrTimeAndLength';

type IProps = Omit<ICuProps<IWorkplaceModel>, 'resource'>;

const WorkplaceForm = ({ id, justIcon, displayAsModal, displayAsSidebar, ...props }: IProps) => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const loadedData = useAppSelector((state) => selectWorkplaceById(state, id ?? null));
    const googleEnabled = useAppSelector(isGoogleEnabled);
    const usersForSelectData = useAppSelector(usersForSelect);
    const paging = useAppSelector(workplacePaging);
    const shifts = useAppSelector(shiftsForSelect) ?? [];
    const queues = useAppSelector(queuesForSelect) ?? [];
    const skills = useAppSelector(skillsForSelect) ?? [];
    const breaks = useAppSelector(breaksForSelect) ?? [];
    const countries = list;

    useEffect(() => {
        dispatch(fetchBreaksForSelect({ search: '' }));
        dispatch(fetchQueuesForSelect({ search: '' }));
        dispatch(fetchShiftsForSelect({ search: '' }));
        dispatch(fetchSkillsForSelect({ search: '' }));
        dispatch(fetchBreaksForSelect({ search: '' }));
        dispatch(fetchUsersForSelect({ search: '' }));
    }, []);

    const handleData = (values: IOutputValueType): IWorkplaceCUModel => {
        const employees = values.employees as { administrators: string[]; employees: string[] };
        const queueValues = values.queues as { queues?: string[] };
        const shiftValues = values.shifts as { allowedShift?: string[]; allowedAllowedShiftsForCalculation?: string[] };
        const skillValues = values.skills as { skillsWeightList?: { [name: string]: IValueType }[] };
        const forecast = values.forecast as {
            nightTime?: ITimeRangeOrTimeAndLengthProps['value'];
            disabledValidations?: string[];
        };
        const settings = values.settings as { [name: string]: IValueType };

        return {
            administrators: employees.administrators.map((value) => parseInt(value)),
            algorithm_type: values.algorithm_type as string,
            allowed_shift: (shiftValues.allowedShift ?? []).map((value) => parseInt(value)),
            allowed_shifts_for_calculation: (shiftValues.allowedAllowedShiftsForCalculation ?? []).map((value) =>
                parseInt(value)
            ),
            after_night_shift: settings?.after_night_shift as number,
            automatic_approve_trade: settings?.automatic_approve_trade as boolean,
            automatic_approve_trade_level_of_competence_difference:
                settings?.automatic_approve_trade === true
                    ? parseInt(`${settings?.automatic_approve_trade_level_of_competence_difference}`)
                    : null,
            before_night_shift: settings?.before_night_shift as number,
            calendar_id: values?.calendarId ?? null,
            country_name: (values?.country_name ?? '') as string,
            disable_empty_shifts: settings?.disable_empty_shifts as boolean,
            disabled_validations: (forecast.disabledValidations ?? []).map((value) => ({
                validation: value
            })),
            employees: employees.employees.map((value) => parseInt(value)),
            enable_work_from_home: settings.enable_work_from_home as boolean,
            external_connection_id: settings.external_connection_id as string,
            choose_breaks_to_synchronize: values?.synchronizeBreaks
                ? values?.chooseBreaksToSynchronize ?? false
                : false,
            location: values.location,
            name: values.name,
            night_time_duration: forecast.nightTime?.duration ?? null,
            night_time_start: forecast.nightTime?.start ? DateHelper.formatTime(forecast.nightTime?.start) : null,
            number_of_seats_max: settings?.enable_work_from_home ? (settings.number_of_seats_max as number) : 0,
            number_of_seats_min: settings?.enable_work_from_home ? (settings.number_of_seats_min as number) : 0,
            queues: (queueValues.queues as string[]).map((value) => parseInt(value)),
            remind_time: settings?.remind_time
                ? DateHelper.formatTime(settings?.remind_time as ITimePickerProps['value'])
                : null,
            start_day_of_emergency: values.start_day_of_emergency as number,
            selected_breaks_to_synchronized:
                values?.synchronizeBreaks && values?.chooseBreaksToSynchronize
                    ? values?.selectedBreaksToSynchronized ?? []
                    : [],
            shift_distance: settings?.shift_distance as number,
            show_attendees_name_in_title: values?.showAttendeesNameInTitle ?? false,
            show_attendees_skill_in_name_title: values?.showAttendeesNameInTitle
                ? values?.showAttendeesSkillInNameTitle ?? false
                : false,
            synchronize_breaks: values?.synchronizeBreaks ?? false,
            synchronize_schedules: values?.synchronizeSchedules ?? false,
            time_zone: values.time_zone,
            trade_compare_level_of_competence: settings?.trade_compare_level_of_competence as boolean,
            trade_compare_level_of_competence_difference:
                settings?.trade_compare_level_of_competence_difference as number,
            work_on_holiday: values.work_on_holiday as boolean,
            working_hours: (
                values.days_and_working_hours as IValuesOfSimpleFields[]
            ).map<IWorkplaceWorkingHoursCUModel>((item) => ({
                days: item.days as number,
                start: DateHelper.formatTime((item.working_hours as ITimeRangeOrTimeAndLengthProps['value'])?.start),
                duration: (item.working_hours as ITimeRangeOrTimeAndLengthProps['value'])?.duration ?? 0
            })),
            skills: (skillValues.skillsWeightList ?? [])
                .filter((item) => !!item.skill_id && !!item.weight)
                .map<IWorkplaceSkillsWeightCUModel>((item) => ({
                    skill_id: typeof item.skill_id === 'string' ? parseInt(item.skill_id) : (item.skill_id as number),
                    weight: typeof item.weight === 'string' ? parseInt(item.weight) : (item.weight as number)
                }))
        } as Partial<IWorkplaceCUModel> as IWorkplaceCUModel;
    };
    const handleCreate = useCallback((values: IOutputValueType) => {
        return createWorkplace(handleData(values));
    }, []);
    const handleUpdate = useCallback(
        (values: IOutputValueType) => {
            return updateWorkplace({ id: id!, data: handleData(values) });
        },
        [id]
    );
    const handleUpdatePaging = useCallback(() => dispatch(updatePaging({ ...paging, count: paging.count + 1 })), []);
    const handleOpen = useCallback(() => {
        dispatch(fetchGooglesSettings());
        if (id) {
            dispatch(fetchWorkplaceById(id));
        }
    }, [id]);

    const shiftsOptions = useMemo(
        () =>
            shifts.map((entity) => ({
                id: `${entity.id}`,
                sortableValue: entity.name
            })),
        [shifts]
    );
    const mappedUsers = useMemo(
        () =>
            usersForSelectData.map((joinEntity) => ({
                id: `${joinEntity.id}`,
                content: serializeUser(joinEntity),
                sortableValue: serializeUser(joinEntity, true)
            })),
        [usersForSelectData]
    );
    const availableAdministrators = useCallback(
        (selectedEmployees: string[]) =>
            mappedUsers?.filter(({ id: userId }) => !(selectedEmployees ?? []).includes(userId.toString())),
        [mappedUsers]
    );

    const availableEmployees = useCallback(
        (selectedAdministrators: string[]) =>
            mappedUsers.filter(({ id: userId }) => !(selectedAdministrators ?? []).includes(userId.toString())),
        [mappedUsers]
    );
    const getFilteredUsers = (type: IUserToWorkplaceModel['type']): string[] =>
        loadedData?.user_to_workplaces
            ?.filter((joinEntity) => joinEntity.type === type)
            .map(({ user_id }) => user_id.toString()) ?? [];

    const getFilteredShifts = (type: IShiftToWorkplaceModel['type']): string[] =>
        loadedData?.shift_to_workplaces
            ?.filter((joinEntity) => joinEntity.type === type)
            .map(({ shift_id }) => shift_id.toString()) ?? [];

    const translatedDays = useTranslatedDays();

    const algorithmOptions = Object.keys(WorkplaceAlgorithmTypeEnum).map((key) => ({
        id: WorkplaceAlgorithmTypeEnum[key as keyof typeof WorkplaceAlgorithmTypeEnum],
        label: t(`enums.workplaceAlgorithmTypeEnum.${formatPascalToCamel(key)}`, key)
    }));

    return (
        <CuForm
            {...props}
            id={id}
            name="workplace"
            resource={PermissionsEnum.Workplaces}
            maxWidth="lg"
            creatingStatus={useAppSelector(workplaceCreatingStatus)}
            updatingStatus={useAppSelector(workplaceUpdatingStatus)}
            justIcon={justIcon}
            displayAsModal={displayAsModal}
            displayAsSidebar={displayAsSidebar}
            items={[
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'name',
                        label: t('label.name', 'Name'),
                        value: loadedData?.name,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'textField',
                    props: {
                        required: true,
                        name: 'location',
                        label: t('label.location', 'Location'),
                        value: loadedData?.location,
                        width: 6,
                        validation: {
                            pattern: {
                                value: regex.text,
                                message: message.text
                            }
                        }
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'algorithm_type',
                        label: t('label.algorithmType', 'Type of generating Shifts'),
                        value: loadedData?.algorithm_type,
                        options: algorithmOptions,
                        validation: {
                            deps: 'start_day_of_emergency'
                        },
                        width: 6
                    }
                },
                {
                    type: 'select',
                    props: {
                        required: true,
                        name: 'country_name',
                        label: t('label.country', 'Country'),
                        value: loadedData?.country_name,
                        options: countries.map((item) => ({
                            id: item.name,
                            label: item.title
                        })),
                        width: 6
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'work_on_holiday',
                        label: t('label.workOnHoliday', 'Work On Holiday'),
                        value: loadedData?.work_on_holiday ?? true,
                        width: 4
                    }
                },
                {
                    type: 'select',
                    display: ({ algorithm_type }) => algorithm_type === WorkplaceAlgorithmTypeEnum.Recurring,
                    props: {
                        name: 'start_day_of_emergency',
                        label: t('label.startOfEmergency', 'Start of emergency'),
                        value: loadedData?.start_day_of_emergency,
                        options: translatedDays.map((item) => ({
                            id: `${item.id}`,
                            label: item.name
                        })),
                        validation: {
                            deps: 'algorithm_type'
                        },
                        width: 4
                    }
                },
                {
                    type: 'multiRowInputs',
                    props: {
                        name: 'days_and_working_hours',
                        value: loadedData?.workplace_working_hours?.map((item) => ({
                            days: item.days,
                            working_hours: {
                                start: DateHelper.fromTimeString(item.start),
                                duration: item.duration
                            }
                        })),
                        inputs: [
                            {
                                type: 'weekdays',
                                props: {
                                    required: true,
                                    name: 'days',
                                    multiple: true,
                                    width: 12,
                                    sx: { alignItems: 'center' }
                                }
                            },
                            {
                                type: 'timeRangeOrTimeAndLength',
                                props: {
                                    name: 'working_hours',
                                    label: {
                                        start: t('label.workingHoursStart', 'Working Hours Start'),
                                        end: t('label.workingHoursEnd', 'Working Hours End'),
                                        length: t('label.length', 'Length'),
                                        range: t('label.range', 'Range')
                                    },
                                    required: true,
                                    minutesStep: 15,
                                    width: 10
                                }
                            }
                        ]
                    }
                },
                {
                    type: 'tabs',
                    props: {
                        name: 'tabs',
                        value: 'employees',
                        tabsContainer: Paper,
                        tabs: [
                            {
                                name: 'employees',
                                label: t('subTitle.employees', 'Employees'),
                                inputs: [
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'administrators',
                                            label: t('label.administrators', 'Administrators'),
                                            items: availableAdministrators([]),
                                            values: getFilteredUsers('admin'),
                                            validation: {
                                                deps: 'employees.employees'
                                            }
                                        }
                                    },
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'employees',
                                            label: t('label.employees', 'Employees'),
                                            items: availableEmployees([]),
                                            values: getFilteredUsers('user'),
                                            validation: {
                                                deps: 'employees.administrators'
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'queues',
                                label: t('subTitle.queues', 'Queues'),
                                inputs: [
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'queues',
                                            items: queues.map((item) => ({
                                                id: `${item.id}`,
                                                sortableValue: item.name
                                            })),
                                            values:
                                                loadedData?.queue_to_workplaces?.map((item) =>
                                                    item.queue_id.toString()
                                                ) ?? []
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'shifts',
                                label: t('subTitle.shifts', 'Shifts'),
                                inputs: [
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'allowedShift',
                                            label: t(
                                                'label.allowedShiftsForManualWork',
                                                'Allowed shifts for manual work'
                                            ),
                                            items: shiftsOptions,
                                            values: getFilteredShifts('allowed_shift')
                                        }
                                    },
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'allowedAllowedShiftsForCalculation',
                                            label: t('label.allowedShiftsForEA', 'Allowed shifts for EA'),
                                            items: shiftsOptions,
                                            values: getFilteredShifts('allowed_shifts_for_calculation')
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'skills',
                                label: t('subTittle.skills', 'Skills'),
                                inputs: [
                                    {
                                        type: 'multiRowInputs',
                                        props: {
                                            name: 'skillsWeightList',
                                            value:
                                                loadedData?.skill_weight_to_workplaces?.map((item) => ({
                                                    skill_id: item.skill_id,
                                                    weight: item.weight
                                                })) ?? [],
                                            inputs: [
                                                {
                                                    type: 'select',
                                                    props: {
                                                        name: 'skill_id',
                                                        label: t('label.skillName', 'Skill Name'),
                                                        options: (
                                                            { skills: skillWeightWrapper, ...rest },
                                                            rowIndex
                                                        ) => {
                                                            const selectedSkillsWeightList = (
                                                                skillWeightWrapper as {
                                                                    skillsWeightList: ISupportedValueType[];
                                                                }
                                                            ).skillsWeightList;

                                                            return (
                                                                skills
                                                                    ?.filter(
                                                                        (item) =>
                                                                            !selectedSkillsWeightList.some(
                                                                                (selectedSkill) =>
                                                                                    selectedSkill.skill_id == item.id
                                                                            ) ||
                                                                            (rowIndex || rowIndex === 0
                                                                                ? selectedSkillsWeightList[rowIndex]
                                                                                      .skill_id == item.id
                                                                                : false)
                                                                    )
                                                                    .map((item) => ({
                                                                        id: `${item.id}`,
                                                                        label: item.name
                                                                    })) ?? []
                                                            );
                                                        },
                                                        width: 5,
                                                        validation: {
                                                            deps: ['skill_id', 'weight']
                                                        }
                                                    }
                                                },
                                                {
                                                    type: 'textField',
                                                    props: {
                                                        name: 'weight',
                                                        type: 'number',
                                                        label: t('label.weight', 'Weight'),
                                                        value: undefined,
                                                        width: 5,
                                                        validation: {
                                                            deps: ['skill_id', 'weight']
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'google',
                                label: t('subTitle.google', 'Google'),
                                display: () => googleEnabled,
                                inputs: [
                                    {
                                        type: 'textField',
                                        props: {
                                            name: 'calendarId',
                                            label: t('label.googleCalendarId', 'Google Calendar ID'),
                                            value: loadedData?.calendar_id ?? ''
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'synchronizeSchedules',
                                            label: t('label.synchronizeSchedules', 'Synchronize Schedules'),
                                            value: loadedData?.synchronize_schedules ?? false,
                                            width: 6
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'synchronizeBreaks',
                                            label: t('label.synchronizeBreaks', 'Synchronize Breaks'),
                                            value: loadedData?.synchronize_breaks ?? false,
                                            width: 6,
                                            validation: {
                                                deps: 'synchronizeBreaks'
                                            }
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'showAttendeesNameInTitle',
                                            label: t('label.showAttendeesNameInTitle', 'Show Attendees Name In Title'),
                                            value: loadedData?.show_attendees_name_in_title ?? false,
                                            width: 6,
                                            validation: {
                                                deps: 'showAttendeesNameInTitle'
                                            }
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        display: ({ showAttendeesNameInTitle }) => showAttendeesNameInTitle === true,
                                        props: {
                                            name: 'showAttendeesSkillInNameTitle',
                                            label: t(
                                                'label.showAttendeesSkillInNameTitle',
                                                'Show Skill In Attendees Name Title'
                                            ),
                                            value: loadedData?.show_attendees_skill_in_name_title ?? false,
                                            width: 6,
                                            validation: {
                                                deps: 'showAttendeesNameInTitle'
                                            }
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        display: ({ synchronizeBreaks }) => synchronizeBreaks === true,
                                        props: {
                                            name: 'chooseBreaksToSynchronize',
                                            label: t('label.chooseBreaksSynchronize', 'Choose Breaks Synchronize'),
                                            value: loadedData?.choose_breaks_to_synchronize ?? false,
                                            width: 6,
                                            validation: {
                                                deps: 'synchronizeBreaks'
                                            }
                                        }
                                    },
                                    {
                                        type: 'transferList',
                                        display: ({ chooseBreaksToSynchronize }) => chooseBreaksToSynchronize === true,
                                        props: {
                                            name: 'selectedBreaksToSynchronized',
                                            items: breaks.map((item) => ({
                                                id: `${item.id}`,
                                                sortableValue: item.name
                                            })),
                                            values:
                                                loadedData?.workplaces_breaks_to_synchronized_with_google?.map(
                                                    ({ break_id }) => break_id.toString()
                                                ) ?? [],
                                            validation: {
                                                deps: 'chooseBreaksToSynchronize'
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'forecast',
                                label: t('label.forecast', 'Forecast'),
                                inputs: [
                                    {
                                        type: 'timeRangeOrTimeAndLength',
                                        props: {
                                            name: 'nightTime',
                                            label: {
                                                start: t('label.nightTimeStart', 'Night Time Start'),
                                                end: t('label.nightTimeEnd', 'Night Time End'),
                                                length: t('label.length', 'Length'),
                                                range: t('label.range', 'Range')
                                            },
                                            minDuration: 0,
                                            value: {
                                                start: DateHelper.fromOptionalTime(loadedData?.night_time_start),
                                                duration: loadedData?.night_time_duration ?? null,
                                                isRange: false
                                            }
                                        }
                                    },
                                    {
                                        type: 'transferList',
                                        props: {
                                            name: 'disabledValidations',
                                            items: Object.keys(ValidationEnum).map((key) => ({
                                                id: ValidationEnum[key as keyof typeof ValidationEnum],
                                                sortableValue: t(`enums.validation.${key}`, key)
                                            })),
                                            values:
                                                loadedData?.workplace_disabled_validations?.map(({ validation }) =>
                                                    validation.toString()
                                                ) ?? []
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'settings',
                                label: t('label.settings', 'Settings'),
                                inputs: [
                                    {
                                        type: 'time',
                                        props: {
                                            label: t('label.remindTime', 'Remind Time'),
                                            name: 'remind_time',
                                            value: loadedData?.remind_time
                                                ? DateHelper.fromTimeString(loadedData?.remind_time)
                                                : null,
                                            disableEmpty: false
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'trade_compare_level_of_competence',
                                            label: t(
                                                'label.tradeCompareLevelOfCompetence',
                                                'Trade compare level of competence'
                                            ),
                                            value: loadedData?.trade_compare_level_of_competence ?? false,
                                            width: 6,
                                            validation: {
                                                deps: 'settings.trade_compare_level_of_competence_difference'
                                            }
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        display: ({ trade_compare_level_of_competence }) =>
                                            trade_compare_level_of_competence === true,
                                        props: {
                                            type: 'number',
                                            name: 'trade_compare_level_of_competence_difference',
                                            label: t(
                                                'label.tradeCompareLevelOfCompetenceDifference',
                                                'Trade compare level of competence difference'
                                            ),
                                            required: true,
                                            value: loadedData?.trade_compare_level_of_competence_difference,
                                            width: 6,
                                            validation: {
                                                deps: 'settings.trade_compare_level_of_competence_difference'
                                            }
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'automatic_approve_trade',
                                            label: t('label.automaticApproveTrade', 'Automatic approve trade'),
                                            width: 6,
                                            value: loadedData?.automatic_approve_trade,
                                            validation: {
                                                deps: 'settings.automatic_approve_trade_level_of_competence_difference'
                                            }
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        display: ({ automatic_approve_trade }) => automatic_approve_trade === true,
                                        props: {
                                            type: 'number',
                                            name: 'automatic_approve_trade_level_of_competence_difference',
                                            label: t(
                                                'label.automaticApproveTradeLevelOfCompetenceDifference',
                                                'Automatic approve trade level of competence difference'
                                            ),
                                            required: true,
                                            value: loadedData?.automatic_approve_trade_level_of_competence_difference,
                                            width: 6,
                                            validation: {
                                                deps: 'settings.automatic_approve_trade'
                                            }
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'enable_work_from_home',
                                            label: t('label.enableWorkFromHome', 'Enable work from home'),
                                            value: loadedData?.enable_work_from_home,
                                            width: 6,
                                            validation: {
                                                deps: ['settings.number_of_seats_min', 'settings.number_of_seats_max']
                                            }
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        display: ({ enable_work_from_home }) => enable_work_from_home === true,
                                        props: {
                                            required: true,
                                            name: 'number_of_seats_min',
                                            label: t('label.numberOfSeatsMin', 'Number of Seats Min'),
                                            type: 'number',
                                            value: loadedData?.number_of_seats_min,
                                            width: 6
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        display: ({ enable_work_from_home }) => enable_work_from_home === true,
                                        props: {
                                            required: true,
                                            name: 'number_of_seats_max',
                                            label: t('label.numberOfSeatsMax', 'Number of Seats Max'),
                                            type: 'number',
                                            value: loadedData?.number_of_seats_max,
                                            width: 6
                                        }
                                    },
                                    {
                                        type: 'switch',
                                        props: {
                                            name: 'disable_empty_shifts',
                                            label: t('label.disableEmptyShifts', 'Disable empty shifts'),
                                            value: loadedData?.disable_empty_shifts ?? false,
                                            width: 12
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        props: {
                                            name: 'shift_distance',
                                            label: t('label.shift_distance', 'Distance between shifts'),
                                            type: 'number',
                                            value: loadedData?.shift_distance,
                                            width: 4
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        props: {
                                            name: 'after_night_shift',
                                            label: t('label.distanceAfterNightShift', 'Distance after night shift'),
                                            type: 'number',
                                            value: loadedData?.after_night_shift,
                                            width: 4
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        props: {
                                            name: 'before_night_shift',
                                            label: t('label.distanceBeforeNightShift', 'Distance before night shift'),
                                            type: 'number',
                                            value: loadedData?.before_night_shift,
                                            width: 4
                                        }
                                    },
                                    {
                                        type: 'textField',
                                        props: {
                                            name: 'external_connection_id',
                                            label: t('label.externalConnectionId', 'External Connection ID'),
                                            type: 'text',
                                            value: loadedData?.external_connection_id,
                                            width: 4
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]}
            onOpen={handleOpen}
            onSubmitCreate={handleCreate}
            onSubmitUpdate={handleUpdate}
            onSuccessCreate={handleUpdatePaging}
        />
    );
};

export default WorkplaceForm;
