import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchSchedulePlanCoverageRequire,
    fetchSchedulePlanPredictedTime,
    getUsersAttendanceFile,
    getUsersAttendance,
    getUsersCategoryTypeOptions
} from '@/data/Reports/ReportApi';
import { IUserAttendanceReportFileRequest, IUserAttendanceReportRequest } from '@/data/Reports/ReportModels';
import {
    getLoadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId,
    getLoadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId
} from '@/data/Reports/ReportSlice';
import { IRootState } from '@/data/store';

export const fetchSchedulePlansForecastAccuracyReportCoverageRequire = createAsyncThunk(
    'reports/fetchSchedulePlansAdherenceCoverageRequire',
    async (schedulePlanId: number) => {
        return await fetchSchedulePlanCoverageRequire(schedulePlanId);
    },
    {
        condition(schedulePlanId: number, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;
            const loadedSchedulePlanId =
                getLoadedSchedulePlansCoverageRequireForecastAccuracyReportSchedulePlanId(state);

            if (schedulePlanId === loadedSchedulePlanId) {
                return false;
            }
        }
    }
);

export const fetchSchedulePlansForecastAccuracyReportPredictedTime = createAsyncThunk(
    'reports/fetchSchedulePlansAdherencePredictedTime',
    async (schedulePlanId: number) => {
        return await fetchSchedulePlanPredictedTime(schedulePlanId);
    },
    {
        condition(schedulePlanId: number, thunkAPI) {
            const state = thunkAPI.getState() as IRootState;
            const loadedSchedulePlanId = getLoadedSchedulePlansPredictedTimeForecastAccuracyReportSchedulePlanId(state);

            if (schedulePlanId === loadedSchedulePlanId) {
                return false;
            }
        }
    }
);

export const getUsersAttendanceReportFile = createAsyncThunk(
    'reports/getUsersAttendanceReportFile',
    async (props?: IUserAttendanceReportFileRequest) => {
        return await getUsersAttendanceFile(props);
    }
);

export const fetchUsersAttendanceReport = createAsyncThunk(
    'reports/getUsersAttendanceReport',
    async (props?: IUserAttendanceReportRequest) => {
        return await getUsersAttendance(props);
    }
);

export const fetchUsersCategoryTypeOptions = createAsyncThunk('reports/fetchUsersCategoryTypeOptions', async () => {
    return await getUsersCategoryTypeOptions();
});
