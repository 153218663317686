import { useMemo } from 'react';
import { DateTimeType } from '@/helpers/date/DateHelper';
import useSchedulerColumnTitles from '@/hooks/scheduler/useSchedulerColumnTitles';

const useSchedulerTitles = (isDayMode: boolean, from: DateTimeType, to: DateTimeType) => {
    const columnTitles = useSchedulerColumnTitles(isDayMode, from, to);

    return useMemo(
        () => (isDayMode ? columnTitles.filter((_, index) => index % 4 === 0) : columnTitles),
        [isDayMode, from.toISOString(), to.toISOString()]
    );
};

export default useSchedulerTitles;
