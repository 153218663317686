import { FieldErrors } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { DateTimeType } from '@/helpers/date/DateHelper';
import { message } from '@/utils/validations';
import { IAbbreviationValue } from '@/wrappers/Abbreviation';
import { IDatePickerProps } from '@/wrappers/DatePicker';
import { IDateRangePickerProps } from '@/wrappers/DateRangePicker';
import { ISelectOption } from '@/wrappers/Select';
import { ITimeRangeOrTimeAndLengthValueType } from '@/wrappers/TimeRangeOrTimeAndLength';
import { ITimeRangePickerProps } from '@/wrappers/TimeRangePicker';
import { IItem, ISupportedFieldType } from './FieldTypes';

export type ISupportedValueSimpleType =
    | string
    | number
    | object
    | boolean
    | string[]
    | number[]
    | boolean[]
    | DateTimeType
    | ITimeRangePickerProps['value']
    | IDatePickerProps['value']
    | IDateRangePickerProps['value']
    | IAbbreviationValue
    | ITimeRangeOrTimeAndLengthValueType;
export type IInnerValuesType = { [name: string]: ISupportedValueSimpleType };
export type IValuesOfSimpleFields = { [name: string]: ISupportedValueSimpleType };

export type IValueType =
    | ISupportedValueSimpleType
    | IValuesOfSimpleFields[]
    | { [name: string]: ISupportedValueSimpleType };

export type ISupportedValueType = {
    [name: string]: IValueType;
};

type IIsRequiredDisplayChild = (inputs: FieldValues, rowIndex: number | null) => boolean;
type IIsRequiredDisplayParent = (inputs: FieldValues, parentInputs: FieldValues, rowIndex: number | null) => boolean;

export const getFullNameOfField = (fieldName: string, containerName?: string, rowIndex?: number): string =>
    !!containerName && typeof rowIndex === 'number'
        ? `${containerName}.${rowIndex}${!fieldName ? '' : `.${fieldName}`}`
        : containerName
        ? `${containerName}.${fieldName}`
        : fieldName;

export const getSxValue = (width?: number) => ({
    p: 1,
    pb: 3,
    width: typeof width === 'undefined' ? '100%' : `${((width || 12) * 100) / 12}%`
});

export const isFullWidth = <T extends IItem<{ [name: string]: unknown }>>(fullWidthForm: boolean, input: T) =>
    fullWidthForm &&
    ('fullWidth' in input.props ? input.props.fullWidth === true : true) &&
    typeof input.props.width === 'undefined';

const valueGetter = (item: ISupportedFieldType): ISupportedValueSimpleType => {
    switch (item.type) {
        case 'abbreviation':
            return {
                text: item.props.value?.text ?? '',
                color: item.props.value?.color ?? '#000000',
                background: item.props.value?.background ?? '#ffffff'
            };
        case 'collapse':
            return (item.props.expanded ?? false) as boolean;
        case 'multiChipSelect':
        case 'multiSelect':
            return (item.props.value ?? []) as string[];
        case 'checkBox':
        case 'switch':
            return (item.props.value || false) as boolean;
        case 'dateTimeRange':
        case 'dateRange':
            return item.props.value ?? { start: null, end: null };
        case 'timeRange':
            return item.props.value ?? { start: null, duration: null };
        case 'timeRangeOrTimeAndLength':
            return item.props.value ?? { start: null, duration: null, isRange: false };
        case 'slider':
        case 'weekdays':
            return (item.props.value ?? 0) as number;
        case 'html':
            return item.props.value ?? '';
        case 'password':
        case 'textArea':
        case 'textField':
        case 'select':
            if (typeof item.props.value === 'number' && item.props.value === 0) {
                return '0';
            }

            return (item.props.value || '') as string;
        case 'timezone':
        case 'iconPicker':
        case 'colorPicker':
        case 'time':
        case 'date':
        case 'button':
            return (item.props.value || '') as string;
        case 'transferList':
            return item.props.values || [];
        case 'bottomNavigation':
            return item.props.value as string;
        default:
            throw Error(`Not implemented support defaultValue for item of type [${item.type}]`);
    }
};

export const getValuesFromProps = (fields: ISupportedFieldType[]): ISupportedValueType => {
    const data: ISupportedValueType = {};

    fields.forEach((item) => {
        switch (item.type) {
            case 'custom': {
                const values = item.props.values;

                if (Array.isArray(item.props.values)) {
                    data[item.props.name] = values;
                } else {
                    Object.keys(values).forEach(
                        (fieldName) => (data[fieldName] = (values as IValuesOfSimpleFields)[fieldName])
                    );
                }

                break;
            }
            case 'multiRowInputs': {
                let result: IValuesOfSimpleFields[] = [];

                if (item.props.value && (item.props.value.length > 0 || item.props.fixedSize)) {
                    result = item.props.value;
                } else if (item.props.value || !item.props.fixedSize) {
                    result = [getValuesFromProps(item.props.inputs)] as IValuesOfSimpleFields[];
                }

                data[item.props.name] = result;
                break;
            }
            case 'tabs': {
                data[item.props.name] = item.props.value;

                item.props.tabs.forEach((tab) => {
                    data[tab.name] = getValuesFromProps(tab.inputs);
                });
                break;
            }
            case 'collapse': {
                data[item.props.name] = getValuesFromProps(item.props.inputs);
                break;
            }
            case 'newLine':
                break;
            default:
                if (!item.changeValueOnlyFromInside) {
                    data[item.props.name] = valueGetter(item);
                }

                break;
        }
    });

    return data;
};

type IFieldErrorType = { type: string; message: string };

export const getErrorByFieldName = (errors: FieldErrors, fieldName: string): IFieldErrorType | undefined => {
    const parts = fieldName.split('.');

    if (parts.length > 1) {
        const subErrors = errors[parts[0]];

        if (subErrors) {
            return getErrorByFieldName(subErrors as FieldErrors, parts.slice(1).join('.'));
        }

        return undefined;
    } else {
        return errors[parts[0]] as IFieldErrorType;
    }
};
export const isRequired = (
    getValues: UseFormGetValues<FieldValues>,
    required?: boolean | ((inputs: FieldValues, rowIndex: number | null) => boolean),
    shouldDisplay?: boolean | IIsRequiredDisplayChild | IIsRequiredDisplayParent,
    shouldBeDisabled?: boolean | ((inputs: FieldValues, rowIndex: number | null) => boolean),
    rowIndex: number | null = null
) => {
    const values =
        typeof shouldDisplay === 'function' || typeof required === 'function' || typeof shouldBeDisabled === 'function'
            ? getValues()
            : {};

    let isDisplayed: boolean = false;

    if (typeof shouldDisplay === 'boolean') {
        isDisplayed = shouldDisplay;
    } else if (typeof shouldDisplay === 'function') {
        if (shouldDisplay.length === 2) {
            isDisplayed = (shouldDisplay as IIsRequiredDisplayChild)(values, rowIndex);
        } else if (shouldDisplay.length === 3) {
            isDisplayed = (shouldDisplay as IIsRequiredDisplayParent)(values, values, rowIndex);
        }
    }

    const isDisabled =
        typeof shouldBeDisabled === 'function' ? shouldBeDisabled(values, rowIndex) : shouldBeDisabled === true;
    const isOptional = typeof required === 'function' ? !required(values, rowIndex) : required !== true;

    return isDisplayed && !isOptional && !isDisabled;
};

export const getOptions = (
    getValues: UseFormGetValues<FieldValues>,
    options: ISelectOption[] | ((inputs: FieldValues, rowIndex: number | null) => ISelectOption[]),
    rowIndex: number | null = null
): ISelectOption[] => {
    const values = typeof options === 'function' ? getValues() : {};

    return typeof options === 'function' ? options(values, rowIndex) : options;
};

export const callableValidationOfRequired = ({
    currentValue,
    getValues,
    required,
    rowIndex = null,
    shouldDisplay,
    shouldBeDisabled
}: {
    currentValue: unknown;
    getValues: UseFormGetValues<FieldValues>;
    required?: boolean | ((inputs: FieldValues, rowIndex: number | null) => boolean);
    shouldDisplay?: boolean | ((inputs: FieldValues, parentInputs?: FieldValues, rowIndex?: number | null) => boolean);
    shouldBeDisabled?: boolean | ((inputs: FieldValues, rowIndex: number | null) => boolean);
    rowIndex?: number | null;
}) =>
    isRequired(getValues, required ?? false, shouldDisplay, shouldBeDisabled, rowIndex) && !currentValue
        ? message.required
        : undefined;

export const getContentByFiledName = (
    fieldName: string,
    content: { [name: string]: IValueType | IValuesOfSimpleFields }
): undefined | IValueType | IValuesOfSimpleFields => {
    const parts = fieldName.split('.');

    const subContent = content[parts[0]];

    if (parts.length > 1) {
        if (subContent) {
            return getContentByFiledName(
                parts.slice(1).join('.'),
                subContent as { [name: string]: IValueType | IValuesOfSimpleFields }
            );
        }

        return undefined;
    } else {
        return subContent;
    }
};
