import { useEffect } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchRequestTypesForSelect } from '@/data/RequestTypes/RequestTypeActions';
import { fetchSettingsItems, removeSetting } from '@/data/SettingsItems/SettingsItemActions';
import {
    getApplicationKes,
    getRemovingOfSettingsItemStatus,
    getUpdatingOfSettingsItemStatus,
    isSettingsItemListLoaded,
    settingsItemPaging
} from '@/data/SettingsItems/SettingsItemSlice';
import IntegratedApplicationForm from '@/forms/IntegratedApplicationForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum, {
    PermissionsControllerEnum,
    PermissionsEnumType,
    PermissionsSettingsItemsEnum
} from '@/utils/enums/PermissionsEnum';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';

function IntegratedApplicationListPage() {
    const { t } = useAppTranslation();
    const data = useAppSelector(getApplicationKes);
    const isDataLoaded = useAppSelector(isSettingsItemListLoaded);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchRequestTypesForSelect({ search: '' }));
    }, []);

    return (
        <LayoutOfPage title={t('title.applications', 'Applications')}>
            <CrudDatatable<ArrayElement<typeof data>, string>
                hasSelection={false}
                resource={PermissionsControllerEnum.SettingsItems}
                rowResource={(rowData) => {
                    const indexOfEnum = Object.values(PermissionsEnum).indexOf(
                        `${PermissionsEnum.SettingsItems}-${rowData.id}` as PermissionsSettingsItemsEnum
                    );

                    return rowData.id
                        ? (Object.values(PermissionsEnum)[indexOfEnum] as PermissionsEnumType)
                        : undefined;
                }}
                editResourceMode={{ mode: [Mode.READ, Mode.UPDATE], operator: 'OR' }}
                name="integratedApplication"
                nameOfEntity={(item) => item.id}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <IntegratedApplicationForm
                        id={id ? (id as SettingsTypesEnum) : undefined}
                        displayAsSidebar
                        justIcon={justIcon}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                onRemove={removeSetting}
                header={[{ title: t('header.name', 'Name'), field: 'id' }]}
                onFetchList={fetchSettingsItems}
                data={data}
                paging={useAppSelector(settingsItemPaging)}
                removingStatus={useAppSelector(getRemovingOfSettingsItemStatus)}
                updatingStatus={useAppSelector(getUpdatingOfSettingsItemStatus)}
                isLoading={!isDataLoaded}
            />
        </LayoutOfPage>
    );
}

export default IntegratedApplicationListPage;
