import { combineReducers } from '@reduxjs/toolkit';
import applicationSettingsItemSlice from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import callCenterSlice from '@/data/CallCenters/CallCenterSlice';
import daktelaSlice from '@/data/Daktela/DaktelaSlice';
import notificationEmailSlice from '@/data/NotificationEmail/NotificationEmailSlice';
import reportSlice from '@/data/Reports/ReportSlice';
import schedulePlanDayShiftDelaySlice from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelaySlice';
import schedulePlanDayShiftSlice from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftSlice';
import schedulePlanValidationSlice from '@/data/SchedulePlanValidations/SchedulePlanValidationSlice';
import settingsItemSlice from '@/data/SettingsItems/SettingsItemSlice';
import userToShiftItemSlice from '@/data/UserToShiftItems/UserToShiftItemSlice';
import userToShiftsSlice from '@/data/UserToShifts/UserToShiftsSlice';
import userToVacationFundSlice from '@/data/UserToVacationFunds/UserToVacationFundSlice';
import activitySlice from './Activities/ActivitySlice';
import agentGroupsReducer from './AgentGroups/AgentGroupReducer';
import alertSlice from './Alert/AlertSlice';
import breakSlice from './Breaks/BreakSlice';
import contractSlice from './Contracts/ContractSlice';
import googleSlice from './Google/GoogleSlice';
import languageReducer from './Languages/LanguageReducer';
import needsReducer from './Needs/NeedReducer';
import notificationSlice from './Notification/NotificationSlice';
import periodsSlice from './Periods/PeriodSlice';
import queueSlice from './Queues/QueueSlice';
import requestTypeSlice from './RequestTypes/RequestTypeSlice';
import roleSlice from './Roles/RoleSlice';
import schedulePlanDaySlice from './SchedulePlanDays/SchedulePlanDaySlice';
import schedulePlansSlice from './SchedulePlans/SchedulePlanSlice';
import settingSlice from './Settings/SettingSlice';
import shiftsSlice from './Shifts/ShiftSlice';
import shiftToWorkplaceSlice from './ShiftToWorkplaces/ShiftToWorkplaceSlice';
import shiftTradeOfferSlice from './ShiftTradeOffers/ShiftTradeOfferSlice';
import shiftTradesSlice from './ShiftTrades/ShiftTradeSlice';
import sidebarReducer from './Sidebar/SidebarReducer';
import skillSlice from './Skills/SkillSlice';
import summariesSlice from './Summaries/SummariesSlice';
import systemReducer from './System/SystemReducer';
import timeoffsReducer from './Timeoffs/TimeoffReducer';
import userSlice from './Users/UserSlice';
import userToContractSlice from './UserToContracts/UserToContractSlice';
import userToRequestSlice from './UserToRequests/UserToRequestSlice';
import userToVacationSlice from './UserToVacations/UserToVacationSlice';
import userToWorkplaceSlice from './UserToWorkplaces/UserToWorkplaceSlice';
import workplacesSlice from './Workplaces/WorkplaceSlice';

export default combineReducers({
    [activitySlice.name]: activitySlice.reducer,
    [alertSlice.name]: alertSlice.reducer,
    [breakSlice.name]: breakSlice.reducer,
    [callCenterSlice.name]: callCenterSlice.reducer,
    [contractSlice.name]: contractSlice.reducer,
    [daktelaSlice.name]: daktelaSlice.reducer,
    [googleSlice.name]: googleSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [notificationEmailSlice.name]: notificationEmailSlice.reducer,
    [periodsSlice.name]: periodsSlice.reducer,
    [queueSlice.name]: queueSlice.reducer,
    [reportSlice.name]: reportSlice.reducer,
    [requestTypeSlice.name]: requestTypeSlice.reducer,
    [roleSlice.name]: roleSlice.reducer,
    [schedulePlanDaySlice.name]: schedulePlanDaySlice.reducer,
    [schedulePlanDayShiftDelaySlice.name]: schedulePlanDayShiftDelaySlice.reducer,
    [schedulePlanDayShiftSlice.name]: schedulePlanDayShiftSlice.reducer,
    [schedulePlansSlice.name]: schedulePlansSlice.reducer,
    [schedulePlanValidationSlice.name]: schedulePlanValidationSlice.reducer,
    [settingSlice.name]: settingSlice.reducer,
    [settingsItemSlice.name]: settingsItemSlice.reducer,
    [applicationSettingsItemSlice.name]: applicationSettingsItemSlice.reducer,
    [shiftToWorkplaceSlice.name]: shiftToWorkplaceSlice.reducer,
    [shiftTradeOfferSlice.name]: shiftTradeOfferSlice.reducer,
    [shiftTradesSlice.name]: shiftTradesSlice.reducer,
    [shiftsSlice.name]: shiftsSlice.reducer,
    [skillSlice.name]: skillSlice.reducer,
    [summariesSlice.name]: summariesSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [userToContractSlice.name]: userToContractSlice.reducer,
    [userToRequestSlice.name]: userToRequestSlice.reducer,
    [userToVacationFundSlice.name]: userToVacationFundSlice.reducer,
    [userToVacationSlice.name]: userToVacationSlice.reducer,
    [userToShiftItemSlice.name]: userToShiftItemSlice.reducer,
    [userToShiftsSlice.name]: userToShiftsSlice.reducer,
    [userToWorkplaceSlice.name]: userToWorkplaceSlice.reducer,
    [workplacesSlice.name]: workplacesSlice.reducer,
    agentGroups: agentGroupsReducer,
    language: languageReducer,
    needs: needsReducer,
    sidebar: sidebarReducer,
    system: systemReducer,
    timeoffs: timeoffsReducer
});
