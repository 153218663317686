enum SettingsTypesEnum {
    AzureActiveDirectoryLogin = 'azure_active_directory_login',
    DaktelaTools = 'daktela_tools',
    EvolutionAlgorithmBlocksParameters = 'evolution_algorithm_parameters',
    Google = 'google',
    MicrosoftActiveDirectoryLogin = 'microsoft_active_directory_login',
    Neznam = 'neznam',
    Pinya = 'pinya',
    SendGrid = 'send_grid',
    MegalaxExportMap = 'megalax_export_map'
}

export default SettingsTypesEnum;
