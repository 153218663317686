import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { fetchApplicationsSettingsItems } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemActions';
import { useAppDispatch } from '@/data/hooks';
import RequestApplicationSettingsFormViewPage from '@/forms/ApplicationsSettingsForms/RequestApplicationSettingsForm/RequestApplicationSettingsFormViewPage';
import SchedulePlanApplicationSettingsFormsViewPage from '@/forms/ApplicationsSettingsForms/SchedulePlanApplicationSettingsForm/SchedulePlanApplicationSettingsFormsViewPage';
import NotificationSettingsFormViewPage from '@/forms/NotificationEmailsForm/NotificationSettingsFormViewPage';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import ApplicationSettingsItemTypesEnum from '@/utils/enums/ApplicationSettingsItemTypesEnum';
import Box from '@/wrappers/Box';
import Tabs from '@/wrappers/Tabs';

const StyledBox = styled(Box)(({ theme }) => ({
    '& .MuiTab-root': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.paper
    },
    '& .MuiTab-root:last-child': {
        borderRight: 'none'
    },
    '& .MuiTab-root.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey['50']
    }
}));

function SettingsPage() {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchApplicationsSettingsItems());
    }, []);

    return (
        <LayoutOfPage title={t('title.settings', 'Settings')}>
            <StyledBox>
                <Tabs
                    name="setting"
                    tabs={[
                        {
                            id: 'emailNotification',
                            label: t('label.emailNotification', 'Email Notification'),
                            content: <NotificationSettingsFormViewPage />
                        },
                        {
                            id: ApplicationSettingsItemTypesEnum.request,
                            label: t('label.requests', 'Requests'),
                            content: <RequestApplicationSettingsFormViewPage />
                        },
                        {
                            id: ApplicationSettingsItemTypesEnum.schedulePlan,
                            label: t('label.schedule', 'Schedule'),
                            content: <SchedulePlanApplicationSettingsFormsViewPage />
                        }
                    ]}
                />
            </StyledBox>
        </LayoutOfPage>
    );
}

export default SettingsPage;
