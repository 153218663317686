import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRequestState } from '../ApiRequest';
import { defaultPaging, IPaging } from '../Paging';
import { IRootState } from '../store';
import { INotificationEmailModel } from './EmailNotificationModels';
import {
    createNotificationEmail,
    fetchNotificationEmailById,
    fetchNotificationEmails,
    removeNotificationEmail,
    updateNotificationEmail
} from './NotificationEmailActions';

type INotificationEmailReducerState = {
    paging: IPaging;
    loadingByIdStatus: IRequestState;
    loadingListStatus: IRequestState;
    creatingStatus: IRequestState;
    updatingStatus: IRequestState;
    removingStatus: IRequestState;
};

const initialState: INotificationEmailReducerState = {
    paging: defaultPaging('name'),
    loadingByIdStatus: 'idle',
    loadingListStatus: 'idle',
    creatingStatus: 'idle',
    updatingStatus: 'idle',
    removingStatus: 'idle'
};
const adapter = createEntityAdapter<INotificationEmailModel>({
    selectId: (entity) => entity.id,
    sortComparer: (a, b) => a.type.localeCompare(b.type)
});
const notificationEmailSlice = createSlice({
    name: 'notificationEmails',
    initialState: adapter.getInitialState(initialState),
    reducers: {
        updatePaging: (state, action: PayloadAction<IPaging>) => {
            state.paging = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationEmails.pending, (state) => {
                state.loadingListStatus = 'loading';
            })
            .addCase(fetchNotificationEmails.fulfilled, (state, action) => {
                state.loadingListStatus = 'idle';
                adapter.setAll(state, action.payload.data);
                state.paging = action.payload.collection;
            })
            .addCase(fetchNotificationEmails.rejected, (state) => {
                state.loadingListStatus = 'failed';
            })
            .addCase(fetchNotificationEmailById.pending, (state) => {
                state.loadingByIdStatus = 'loading';
            })
            .addCase(fetchNotificationEmailById.fulfilled, (state, action) => {
                state.loadingByIdStatus = 'idle';
                adapter.upsertOne(state, action.payload);
            })
            .addCase(fetchNotificationEmailById.rejected, (state) => {
                state.loadingByIdStatus = 'failed';
            })
            .addCase(createNotificationEmail.pending, (state) => {
                state.creatingStatus = 'loading';
            })
            .addCase(createNotificationEmail.fulfilled, (state, action) => {
                state.creatingStatus = 'idle';
                adapter.addOne(state, action.payload);
            })
            .addCase(createNotificationEmail.rejected, (state) => {
                state.creatingStatus = 'failed';
            })
            .addCase(removeNotificationEmail.pending, (state) => {
                state.removingStatus = 'loading';
            })
            .addCase(removeNotificationEmail.fulfilled, (state, action) => {
                state.removingStatus = 'idle';
                adapter.removeOne(state, action.meta.arg.id);
            })
            .addCase(removeNotificationEmail.rejected, (state) => {
                state.removingStatus = 'failed';
            })
            .addCase(updateNotificationEmail.pending, (state) => {
                state.updatingStatus = 'loading';
            })
            .addCase(updateNotificationEmail.fulfilled, (state, action) => {
                state.updatingStatus = 'idle';
                adapter.updateOne(state, { id: action.meta.arg.id, changes: action.payload });
            })
            .addCase(updateNotificationEmail.rejected, (state) => {
                state.updatingStatus = 'failed';
            });
    }
});

const getState = (state: IRootState) => state[notificationEmailSlice.name];

const adapterSelectors = adapter.getSelectors<IRootState>(getState);

export const isNotificationEmailListInProgress = (state: IRootState) => getState(state).loadingListStatus === 'loading';
export const notificationEmailPaging = (state: IRootState) => getState(state).paging;
export const isNotificationEmailByIdInProgress = (state: IRootState) => getState(state).loadingByIdStatus === 'loading';
export const notificationEmailList = adapterSelectors.selectAll;
export const notificationEmailCreatingStatus = (state: IRootState) => getState(state).creatingStatus;
export const notificationEmailById = (state: IRootState, id?: number) =>
    id ? adapterSelectors.selectById(state, id) : undefined;
export const notificationEmailUpdatingStatus = (state: IRootState) => getState(state).updatingStatus;
export const notificationEmailRemovingStatus = (state: IRootState) => getState(state).removingStatus;
export const { updatePaging } = notificationEmailSlice.actions;

export default notificationEmailSlice;
