import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { TabsTypeMap } from '@mui/material/Tabs/Tabs';
import { ElementType, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import theme from '@/assets/theme';

const StyledWrapper = styled(Box)`
    flex-flow: nowrap;
    white-space: pre-wrap;
`;

type ITabPanelProps = PropsWithChildren<
    BoxProps & {
        index: number;
        mode: 'js' | 'css';
        value: number;
    }
>;
type ITabsProps<ContentContainer extends ElementType, TabContainer extends ElementType> = Pick<
    TabsTypeMap['props'],
    'orientation'
> & {
    name: string;
    mode?: 'js' | 'css';
    contentContainer?: ContentContainer;
    tabsContainer?: TabContainer;
    value?: string;
    tabs: { id: string; display?: boolean; label: ReactNode; content: ReactNode }[];
    onChange?: (id: string | null) => void;
};

function TabPanel({ children, mode, value, index, ...rest }: ITabPanelProps) {
    return (
        <Box
            {...rest}
            className="tabContent"
            hidden={value !== index}
            sx={{ ...rest.sx, ...(mode === 'css' ? { display: index === value ? 'block' : 'none' } : {}) }}
        >
            {mode === 'css' || index === value ? children : null}
        </Box>
    );
}

export default function Tabs<ContentContainer extends ElementType, TabContainer extends ElementType>({
    name,
    mode = 'css',
    tabs,
    tabsContainer,
    contentContainer,
    orientation,
    value,
    onChange,
    ...rest
}: ITabsProps<ContentContainer, TabContainer>) {
    const tabsToRender = tabs.filter((tab) => tab.display ?? true);
    const tabIndexByValue = tabsToRender.findIndex((tab) => tab.id === value);
    const [selectedTab, setSelectedTab] = useState<number | null>(tabIndexByValue === -1 ? 0 : tabIndexByValue);
    const ignoreTabIndexByValue = typeof value === 'undefined';

    useEffect(() => {
        const newValue = selectedTab === null ? null : tabsToRender[selectedTab].id;

        if (onChange && newValue !== value) {
            onChange(newValue);
        }
    }, [selectedTab]);
    useEffect(() => {
        const newValue = tabsToRender.findIndex((tab) => tab.id === value) ?? 0;

        if (newValue !== selectedTab) {
            setSelectedTab(newValue === -1 ? 0 : newValue);
        }
    }, [value]);
    useEffect(() => {
        if (!ignoreTabIndexByValue && tabIndexByValue === -1) {
            if (tabs.length === 0) {
                setSelectedTab(null);
            } else {
                setSelectedTab(0);
            }
        }
    }, [tabs.length, tabIndexByValue, ignoreTabIndexByValue]);

    const renderTabs = (
        <>
            <StyledWrapper data-name={`${name}-header`} component={tabsContainer} sx={{ marginBottom: 2 }}>
                <MuiTabs
                    {...rest}
                    variant="scrollable"
                    className="tabsContainer"
                    scrollButtons="auto"
                    value={selectedTab}
                    orientation={orientation}
                    sx={
                        orientation === 'vertical'
                            ? { backgroundColor: theme.palette.grey.A200, borderRight: 1, borderColor: 'divider' }
                            : undefined
                    }
                    onChange={(_, index) => setSelectedTab(index)}
                    data-testid="tabButtons"
                >
                    {tabsToRender.map((tab) => (
                        <MuiTab
                            className="tab"
                            key={tab.id}
                            label={tab.label}
                            wrapped={false}
                            data-testid={`tab-button-${tab.id}`}
                        />
                    ))}
                </MuiTabs>
            </StyledWrapper>
            <Box component={contentContainer} data-name={`${name}-content`} sx={{ width: '100%' }}>
                {tabsToRender
                    // .filter(
                    //     (tab, index) => (tab.display ?? true) && ((mode === 'js' && value === index) || mode === 'css')
                    // )
                    .map((tab, index) => (
                        <TabPanel
                            key={index}
                            value={!ignoreTabIndexByValue && tabIndexByValue === -1 ? 0 : selectedTab ?? 0}
                            index={index}
                            mode={mode}
                            data-testid={`tab-content-${tab.id}`}
                            sx={{ width: '100%', ...(orientation === 'vertical' ? { paddingLeft: '1em' } : {}) }}
                        >
                            {tab.content}
                        </TabPanel>
                    ))}
            </Box>
        </>
    );

    return orientation === 'vertical' ? (
        <Box data-name={`${name}-container`} sx={{ flexGrow: 1, display: 'flex', height: '100%', width: '100%' }}>
            {renderTabs}
        </Box>
    ) : (
        renderTabs
    );
}
