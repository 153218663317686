import Avatar from '@mui/material/Avatar';
import { useEffect } from 'react';
import CrudDatatable from '@/components/CrudDatatable';
import { isUserAllowed, Mode } from '@/components/UserPermision';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { fetchQueuesForSelect } from '@/data/Queues/QueueActions';
import { getPermissionsList } from '@/data/System/SystemReducer';
import { fetchWorkplaces, removeWorkplace } from '@/data/Workplaces/WorkplaceActions';
import {
    isWorkplaceListInProgress,
    selectWorkplaceList,
    workplacePaging,
    workplaceRemovingStatus
} from '@/data/Workplaces/WorkplaceSlice';
import WorkplaceForm from '@/forms/WorkplaceForm';
import { ArrayElement } from '@/helpers/array/ArrayElementType';
import useAppTranslation from '@/hooks/useAppTranslation';
import LayoutOfPage from '@/pages/LayoutOfPage';
import PermissionsEnum from '@/utils/enums/PermissionsEnum';
import ChipStack from '@/wrappers/ChipStack';

const WorkplacesListPage = () => {
    const { t } = useAppTranslation();
    const dispatch = useAppDispatch();
    const inProgress = useAppSelector(isWorkplaceListInProgress);
    const data = useAppSelector(selectWorkplaceList);
    const permissionList = useAppSelector(getPermissionsList);

    useEffect(() => {
        if (isUserAllowed({ id: PermissionsEnum.Queues, mode: Mode.READ }, permissionList)) {
            dispatch(fetchQueuesForSelect({ search: '' }));
        }
    }, []);

    return (
        <LayoutOfPage title={t('title.workplacesList', 'Workplaces List')}>
            <CrudDatatable<ArrayElement<typeof data>>
                name="workplace"
                resource={PermissionsEnum.Workplaces}
                nameOfEntity={(item) => item.name}
                formRender={(id, justIcon, openButtonValue, _, renderButton) => (
                    <WorkplaceForm
                        displayAsSidebar
                        justIcon={justIcon}
                        id={id}
                        openButtonValue={openButtonValue}
                        openButtonRender={renderButton}
                    />
                )}
                header={[
                    { title: t('header.workplaceName', 'Workplace Name'), field: 'name' },
                    {
                        title: t('header.administrators', 'Administrators'),
                        field: 'administrators',
                        cellStyle: {
                            width: '35%'
                        },
                        sorting: false,
                        render: (rowData) => (
                            <ChipStack
                                name="administrators"
                                direction="row"
                                values={
                                    rowData.administrators
                                        .map(
                                            (joinRow) =>
                                                `${joinRow.first_name},${
                                                    joinRow.middle_name || null ? joinRow.middle_name + ', ' : ''
                                                } ${joinRow.last_name}`
                                        )
                                        .map((user) => ({
                                            id: user,
                                            name: user,
                                            label: user,
                                            color: 'primary',
                                            avatar: <Avatar>{user.substring(0, 1)}</Avatar>
                                        })) ?? []
                                }
                            />
                        )
                    },
                    ...(isUserAllowed({ id: PermissionsEnum.Queues, mode: Mode.READ }, permissionList)
                        ? [
                              {
                                  title: t('header.queues', 'Queues'),
                                  field: 'queue_to_workplaces',
                                  sorting: false,
                                  render: (rowData: ArrayElement<typeof data>) => (
                                      <ChipStack
                                          name="queues"
                                          direction="row"
                                          values={
                                              rowData?.queue_to_workplaces?.map(({ queue }) => ({
                                                  id: `${queue.id}`,
                                                  name: queue.name,
                                                  label: queue.name,
                                                  color: 'primary'
                                              })) ?? []
                                          }
                                      />
                                  )
                              }
                          ]
                        : []),
                    {
                        title: t('header.location', 'Location'),
                        field: 'location'
                    },
                    {
                        title: t('header.employees', 'Employees'),
                        field: 'employees',
                        sorting: false,
                        render: (rowData) =>
                            rowData.user_to_workplaces.filter((joinTable) => joinTable.type === 'user').length ?? 0
                    }
                ]}
                isLoading={inProgress}
                data={data}
                paging={useAppSelector(workplacePaging)}
                removingStatus={useAppSelector(workplaceRemovingStatus)}
                onFetchList={fetchWorkplaces}
                onRemove={removeWorkplace}
            />
        </LayoutOfPage>
    );
};

export default WorkplacesListPage;
