import { createAsyncThunk } from '@reduxjs/toolkit';
import { connect, Socket } from 'socket.io-client';
import { IClientToServerEvents, IServerToClientEvents } from '@/data/Notification/NotificationModels';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { fetchQueueById } from '@/data/Queues/QueueActions';
import { patchSchedulePlanDayShiftDelays } from '@/data/SchedulePlanDayShiftDelays/SchedulePlanDayShiftDelaySlice';
import { SchedulePlanStateEnum } from '@/data/SchedulePlans/SchedulePlanEnums';
import { changeStateToPlan, invalidateSchedulePlan } from '@/data/SchedulePlans/SchedulePlanSlice';
import { invalidateAbsentUsers, patchDownloadedUsersStatus } from '@/data/Users/UserSlice';
import { changeStateToRequest } from '@/data/UserToRequests/UserToRequestSlice';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { serializeListOfUsers, serializeUser } from '@/utils/UserHelper';

let currentSocket: Socket<IServerToClientEvents, IClientToServerEvents> | null = null;

export const connectToWS = createAsyncThunk('notification/connect', async (_, { dispatch }) => {
    const token = localStorage.getItem('token');

    const socket: Socket<IServerToClientEvents, IClientToServerEvents> = connect('/', {
        path: '/ws',
        query: { token }
    });

    // socket.on('connect', () => {
    //     console.log('connected', socket.id);
    // });
    socket.on('connect_error', (err) => {
        console.log('connect_error', err);
    });
    socket.on('disconnect', () => {
        console.log('disconnect', socket.id);
    });
    socket.on('queueIsChanged', (queueId) => {
        dispatch(fetchQueueById({ id: queueId, reloadOnly: true }));
    });
    socket.on('queueIsReady', (queueId) => {
        dispatch(fetchQueueById({ id: queueId, reloadOnly: true }));
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.queueIsReady',
                defaultMessage: 'Queue ID {{id}} is ready',
                values: { id: queueId }
            })
        );
    });
    socket.on('queueIsAggregated', ({ id, name }) => {
        dispatch(fetchQueueById({ id, reloadOnly: true }));
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.queueIsAggregated',
                defaultMessage: 'Queue {{name}} is aggregated',
                values: { name }
            })
        );
    });
    socket.on('schedulePlanCalculated', (schedulePlanId) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.schedulePlanWasCalculated',
                defaultMessage: 'Schedule Plan ID {{id}} was calculated',
                values: { id: schedulePlanId }
            })
        );
        dispatch(invalidateSchedulePlan(schedulePlanId));
    });
    socket.on('schedulePlanRecalculated', (schedulePlanId) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.schedulePlanWasRecalculated',
                defaultMessage: 'Schedule Plan {{id}} was recalculated',
                values: { id: schedulePlanId }
            })
        );
        dispatch(invalidateSchedulePlan(schedulePlanId));
    });
    socket.on('schedulePlanRecalculatedWithShifts', (schedulePlanId) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.schedulePlanWasRecalculatedWithShifts',
                defaultMessage: 'Schedule Plan {{id}} was recalculated with Shifts',
                values: { id: schedulePlanId }
            })
        );
        dispatch(invalidateSchedulePlan(schedulePlanId));
    });
    socket.on('schedulePlanCreated', (schedulePlanId) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.schedulePlanWasCreated',
                defaultMessage: 'Schedule Plan ID {{id}} was created',
                values: { id: schedulePlanId }
            })
        );
        dispatch(invalidateSchedulePlan(schedulePlanId));
    });
    socket.on('absentUsers', (usersList) => {
        dispatch(
            addNotification({
                variant: 'info',
                context: 'message.info.listOfUsersThatAreAbsent',
                defaultMessage: 'List of users that are absent: {{users}}',
                values: { users: serializeListOfUsers(usersList) }
            })
        );
    });
    socket.on('schedulePlanLocked', (schedulePlanId) => {
        dispatch(changeStateToPlan({ schedulePlanId: schedulePlanId, state: SchedulePlanStateEnum.Locked }));
    });
    socket.on('downloadUser', () => {
        dispatch(
            addNotification({
                context: 'message.success.userDownloads',
                defaultMessage:
                    'There were downloaded new users from external source. \n We recommend to refresh page to download actualized data',
                variant: 'success'
            })
        );
    });
    socket.on('downloadUserStatus', (usersError) => {
        dispatch(patchDownloadedUsersStatus('idle'));
        if (usersError.status === 'success') {
            dispatch(
                addNotification({
                    context: 'message.success.downloadUserStatus',
                    defaultMessage: '{{count}} users were downloaded',
                    variant: 'success',
                    autoHideDuration: 10000,
                    values: {
                        count: Object.keys(usersError.downloadedUsersList).length
                    }
                })
            );
        } else {
            dispatch(
                addNotification({
                    variant: 'collapse',
                    customProps: {
                        variant: 'warning',
                        titleContext: 'message.warning.downloadUserStatusTitle',
                        titleDefaultMessage:
                            'There were some issues with downloading. \n There were {{successCount}} users downloaded successfully and there were {{errorCount}} problems',
                        titleValues: {
                            successCount: Object.keys(usersError.downloadedUsersList).length,
                            errorCount: Object.keys(usersError.errors).length
                        },
                        innerMessages: usersError.errors.map((error) => {
                            return {
                                message: error.message + ' - ' + serializeUser(error.entity)
                            };
                        })
                    }
                })
            );
        }
    });
    socket.on('schedulePlanDayShiftDelaysList', (schedulePlanDayShiftDelaysList) => {
        dispatch(patchSchedulePlanDayShiftDelays(schedulePlanDayShiftDelaysList));
    });
    socket.on('schedulePlanValidation', (schedulePlanId) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.validationOfSchedulePlanIsFinished',
                defaultMessage: 'Validation of Schedule Plan ID {{id}} is finished',
                values: { id: schedulePlanId }
            })
        );
        dispatch(invalidateSchedulePlan(schedulePlanId));
    });
    socket.on('schedulePlanFundDone', (data) => {
        dispatch(
            addNotification({
                variant: 'success',
                context: 'message.info.calculatingFundsOfSchedulePlanIsFinished',
                defaultMessage: 'Calculating funds of Schedule Plan ID {{id}} is finished',
                values: { id: data.schedulePlanId }
            })
        );

        const indexOfEnum = Object.values(SchedulePlanStateEnum).indexOf(data.state as SchedulePlanStateEnum);
        const state = Object.values(SchedulePlanStateEnum)[indexOfEnum];

        dispatch(changeStateToPlan({ schedulePlanId: data.schedulePlanId, state: state }));
    });
    socket.on('schedulePlanFundForRequest', (data) => {
        const indexOfEnum = Object.values(UserToRequestsStateEnum).indexOf(data.state as UserToRequestsStateEnum);
        const state = Object.values(UserToRequestsStateEnum)[indexOfEnum];
        const message = data.message;

        dispatch(changeStateToRequest({ requestId: data.requestId, state: state, message: message }));
    });
    socket.on('schedulePlanFundForRequestCalculating', (requestId) => {
        dispatch(changeStateToRequest({ requestId: requestId, state: UserToRequestsStateEnum.CALCULATING_FUNDS }));
    });
    socket.on('changeStateOfSchedulePlan', (data) => {
        const indexOfEnum = Object.values(SchedulePlanStateEnum).indexOf(data.state as SchedulePlanStateEnum);
        const state = Object.values(SchedulePlanStateEnum)[indexOfEnum];

        dispatch(changeStateToPlan({ schedulePlanId: data.schedulePlanId, state: state }));
    });
    socket.on('clearAbsentUsers', () => {
        dispatch(invalidateAbsentUsers([]));
    });

    currentSocket = socket;

    return Promise.resolve();
});

export { currentSocket };
