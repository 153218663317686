import { getSchedulePlanApplicationsSettings } from '@/data/ApplicationSettingsItems/ApplicationSettingsItemSlice';
import { useAppSelector } from '@/data/hooks';
import SchedulePlanApplicationSettingsForm from '@/forms/ApplicationsSettingsForms/SchedulePlanApplicationSettingsForm/SchedulePlanApplicationSettingsForm';
import { StyledApplicationSettingsActionPanel } from '@/forms/ApplicationsSettingsForms/utils';
import {
    getSettingsItemBooleanValueByKey,
    getSettingsItemStringValueByKey
} from '@/forms/IntegratedApplicationForm/utils';
import useAppTranslation from '@/hooks/useAppTranslation';
import ApplicationSettingsSchedulePlanItemEnum from '@/utils/enums/ApplicationSettings/ApplicationSettingsSchedulePlanItemEnum';
import Select from '@/wrappers/Select';
import StackInformation from '@/wrappers/StackInformations';
import Switch from '@/wrappers/Switch';

const SchedulePlanApplicationSettingsFormsViewPage = () => {
    const { t } = useAppTranslation();
    const data = useAppSelector(getSchedulePlanApplicationsSettings);

    return (
        <>
            <StyledApplicationSettingsActionPanel>
                <SchedulePlanApplicationSettingsForm />
            </StyledApplicationSettingsActionPanel>
            <StackInformation
                items={[
                    {
                        label: t('label.onHolidayInsertSetShiftAsEmpty', 'On Holiday Insert Set Shift As Empty'),
                        value: (
                            <Switch
                                name="onHolidayInsertSetShiftAsEmpty"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.onHolidayInsertSetShiftAsEmpty
                                )}
                            />
                        )
                    },
                    {
                        label: t(
                            'label.dndShiftBetweenDays',
                            'When Drag & Drop Shift between dates move Original Shift into Empty Shift'
                        ),
                        value: (
                            <Switch
                                name="dndShiftBetweenDays"
                                readOnly
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.dndShiftBetweenDays
                                )}
                            />
                        )
                    },
                    {
                        label: t('label.canTakeTradeWithoutCounteroffer', 'Can take trade without counteroffer'),
                        value: (
                            <Switch
                                name="canTakeTradeWithoutCounteroffer"
                                readOnly={true}
                                value={getSettingsItemBooleanValueByKey(
                                    data,
                                    ApplicationSettingsSchedulePlanItemEnum.canTakeTradeWithoutCounteroffer
                                )}
                            />
                        )
                    },
                    {
                        label: t('label.limitationOfAgentsAndEmptyShift', 'Limitation of Agents & Empty Shifts'),
                        value: (
                            <Select
                                name="limitationOfAgentsAndEmptyShift"
                                readOnly={true}
                                options={[
                                    {
                                        id: 'default',
                                        label: t('label.noLimitation', 'No limitation (default)')
                                    },
                                    {
                                        id: 'before_date',
                                        label: t(
                                            'label.agentIsAbleToTakeEmptyShiftsOnlyAfterDate',
                                            'Agent is able to take Empty Shifts only after specific Date'
                                        )
                                    },
                                    {
                                        id: 'from_date',
                                        label: t(
                                            'label.agentIsAbleToSeeAndTakeEmptyShiftsOnlyAfterSpecificDate',
                                            'Agent is able to see and take Empty Shifts only after specific Date'
                                        )
                                    }
                                ]}
                                value={
                                    getSettingsItemStringValueByKey(
                                        data,
                                        ApplicationSettingsSchedulePlanItemEnum.limitationOfAgentsAndEmptyShifts
                                    ) ?? 'default'
                                }
                            />
                        ),
                        valueSx: {
                            flexGrow: 1
                        }
                    }
                ]}
            />
        </>
    );
};

export default SchedulePlanApplicationSettingsFormsViewPage;
