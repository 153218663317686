import { z } from 'zod';
import { IQueueItemCU } from '@/data/Queues/QueueModels';

export type ISkillCUModel = {
    icon: string;
    color?: string;
    name: string;
    allow_to_combine: boolean;
    allow_work_from_home: boolean;
    only_work_from_home: boolean;
    external_connection_id?: string;
    queue_items?: Omit<IQueueItemCU, 'skill'>[];
};
export type ISkillSelectModel = Pick<ISkillModel, 'id' | 'name' | 'color'> & { icon?: ISkillCUModel['icon'] };

export const SkillValidationModel = z.object({
    allow_to_combine: z.boolean(),
    allow_work_from_home: z.boolean(),
    color: z.string().optional(),
    id: z.number(),
    icon: z.string(),
    name: z.string().min(1),
    only_work_from_home: z.boolean(),
    created: z.string(),
    modified: z.string(),
    external_connection_id: z.string().optional().nullish(),
    queue_items: z.union([
        z.undefined(),
        z.array(
            z.object({
                id: z.number(),
                queue_id: z.number(),
                skill_id: z.number().optional()
            })
        )
    ])
});
export const SelectSkillValidationModel = z.object({
    color: z.string().optional(),
    icon: z.string(),
    id: z.number(),
    name: z.string().min(1)
});
export const SelectSkillListValidationModel = z.array(SelectSkillValidationModel);
export const SkillListValidationModel = z.array(SkillValidationModel);
export type ISkillModel = z.infer<typeof SkillValidationModel>;
