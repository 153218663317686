import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { FieldsGenerator, IFieldsGeneratorProps, IOutputValueType, ISupportedFieldType } from '@/base/FormGenerator';
import { useAppSelector } from '@/data/hooks';
import { getMicrosoftSettingsItems } from '@/data/SettingsItems/SettingsItemSlice';
import { getSettingsItemValueByKey } from '@/forms/IntegratedApplicationForm/utils';
import formatPascalToCamel from '@/helpers/format/formatPascalToCamel';
import useAppTranslation from '@/hooks/useAppTranslation';
import IntegrationsSettingsMicrosoftItemEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemEnum';
import IntegrationsSettingsMicrosoftItemKeyMapEnum from '@/utils/enums/IntegrationsSettings/IntegrationsSettingsMicrosoftItemKeyMapEnum';
import SettingsTypesEnum from '@/wrappers/Dashboard/Content/BreaksSummary/types/SettingsTypesEnum';
import Tooltip from '@/wrappers/Tooltip';

type RequiredByFieldsGenerator = Pick<
    IFieldsGeneratorProps,
    | 'clearErrors'
    | 'control'
    | 'fullWidth'
    | 'getFieldState'
    | 'getValues'
    | 'initialValues'
    | 'readOnly'
    | 'register'
    | 'setValue'
    | 'watch'
>;

const Microsoft = (props: RequiredByFieldsGenerator) => {
    const data = useAppSelector(getMicrosoftSettingsItems);
    const { t } = useAppTranslation();

    const hasUserName = useMemo(
        () => !!getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.username),
        [data]
    );
    const hasPassword = useMemo(
        () => !!getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.password),
        [data]
    );

    const requiredKeyMapOptions: IntegrationsSettingsMicrosoftItemKeyMapEnum[] = [
        IntegrationsSettingsMicrosoftItemKeyMapEnum.name,
        IntegrationsSettingsMicrosoftItemKeyMapEnum.surname,
        IntegrationsSettingsMicrosoftItemKeyMapEnum.login,
        IntegrationsSettingsMicrosoftItemKeyMapEnum.email,
        IntegrationsSettingsMicrosoftItemKeyMapEnum.userExternalID
    ];

    const optionalKeyMapOptions: IntegrationsSettingsMicrosoftItemKeyMapEnum[] = Object.values(
        IntegrationsSettingsMicrosoftItemKeyMapEnum
    ).filter((item) => !requiredKeyMapOptions.some((requiredItem) => requiredItem === item));

    const getPlaceholder = useCallback((option: IntegrationsSettingsMicrosoftItemKeyMapEnum) => {
        switch (option) {
            case IntegrationsSettingsMicrosoftItemKeyMapEnum.name:
                return 'user.name / cn.0 / cn';
            case IntegrationsSettingsMicrosoftItemKeyMapEnum.surname:
                return 'user.surname / sn.0 / sn';
            case IntegrationsSettingsMicrosoftItemKeyMapEnum.login:
                return 'user.login / login.0 / login';
            case IntegrationsSettingsMicrosoftItemKeyMapEnum.email:
                return 'user.email / email.0 / email';
            case IntegrationsSettingsMicrosoftItemKeyMapEnum.userExternalID:
                return 'user.id / id.0 / id';

            default:
                return undefined;
        }
    }, []);

    const getOptionalRowInputOptions = useCallback(
        (formValues: IOutputValueType, rowIndex: number | null) => {
            const optionalKeyMap = formValues.optionalKeyMap as {
                key: IntegrationsSettingsMicrosoftItemKeyMapEnum;
            }[];

            return optionalKeyMapOptions
                .filter(
                    (optionItem) =>
                        optionItem == optionalKeyMap[rowIndex as number].key ||
                        !optionalKeyMap.some((keyItem) => keyItem.key === optionItem)
                )
                .map((optionItem) => {
                    const indexOfEnum = Object.values(IntegrationsSettingsMicrosoftItemKeyMapEnum).indexOf(
                        optionItem as unknown as IntegrationsSettingsMicrosoftItemKeyMapEnum
                    );

                    const key = Object.keys(IntegrationsSettingsMicrosoftItemKeyMapEnum)[indexOfEnum];

                    return {
                        id: optionItem,
                        label: t(`enums.integrationsSettingsMicrosoftItemKeyMapEnum.${formatPascalToCamel(key)}`, key)
                    };
                });
        },
        [optionalKeyMapOptions]
    );

    return (
        <FieldsGenerator
            {...props}
            name={SettingsTypesEnum.MicrosoftActiveDirectoryLogin}
            fields={[
                {
                    type: 'textField',
                    props: {
                        name: 'host',
                        required: true,
                        label: t('label.host', 'Host'),
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.host)
                    }
                },
                {
                    type: 'textField',
                    props: {
                        name: 'authenticate_domain',
                        label: t('label.authenticateDomain', 'authenticateDomain'),
                        helperText: t(
                            'message.info.activeDirectoryDomain',
                            'Active directory domain without username, In authenticate these two are combined to login into active directory ( example value: "@example.com" )'
                        ),
                        placeholder: '@example.com',
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.authenticateDomain)
                    }
                },
                {
                    type: 'switch',
                    props: {
                        name: 'set_download_settings',
                        label: t('label.setDownloadSettings', 'Set Download Settings'),
                        value:
                            (getSettingsItemValueByKey(
                                data,
                                IntegrationsSettingsMicrosoftItemEnum.setDownloadSettings
                            ) as boolean) ?? false,
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'html',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'divider',
                        render: () => <Divider />,
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'username',
                        required: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        label: t('label.username', 'username'),
                        placeholder: 'example\\uid=example',
                        color: hasUserName ? 'warning' : undefined,
                        helperText: t(
                            'message.info.activeDirectoryUsername',
                            'Active directory username without domain, The domain is specified in authenticate domain (  example value: "example" or "uid=example" )'
                        ),
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.username),
                        InputProps: {
                            endAdornment: hasUserName ? (
                                <InputAdornment position="end">
                                    <Tooltip
                                        color="primary"
                                        title={t(
                                            'message.info.encryptedValue',
                                            'The value in the field is encrypted and therefore cannot be copied and reused. If the value needs to be modified, it needs to be saved entirely again.'
                                        )}
                                    >
                                        <ContentPasteOffOutlinedIcon />
                                    </Tooltip>
                                </InputAdornment>
                            ) : undefined
                        },
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'password',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'password',
                        required: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        color: hasPassword ? 'warning' : undefined,
                        label: t('label.password', 'password'),
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.password) as
                            | string
                            | undefined,
                        endAdornment: hasPassword ? (
                            <InputAdornment position="end">
                                <Tooltip
                                    color="primary"
                                    title={t(
                                        'message.info.encryptedValue',
                                        'The value in the field is encrypted and therefore cannot be copied and reused. If the value needs to be modified, it needs to be saved entirely again.'
                                    )}
                                >
                                    <ContentPasteOffOutlinedIcon />
                                </Tooltip>
                            </InputAdornment>
                        ) : undefined,
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'download_domain',
                        required: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        label: t('label.downloadDomain', 'Download Domain'),
                        helperText: t(
                            'message.info.activeDirectoryDomain',
                            'Active directory domain without username, In authenticate these two are combined to login into active directory ( example value: "@example.com" )'
                        ),
                        placeholder: '@example.com',
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.downloadDomain),
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'base',
                        required: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        label: t('label.base', 'base'),
                        placeholder: 'CN=Users, DC=example, DC=com',
                        helperText: t(
                            'message.info.activeDirectoryBase',
                            'Required attribute to specify base where to downloading users from active directory'
                        ),
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.base),
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'filter',
                        required: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        label: t('label.filter', 'Filter'),
                        placeholder: '(objectClass=user)',
                        helperText: t(
                            'message.info.activeDirectoryFilter',
                            'Required attribute to specify filter of ldap to downloading users active directory'
                        ),
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.filter),
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'textField',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'port',
                        label: t('label.port', 'Port'),
                        type: 'number',
                        value: getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.port),
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'switch',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'tls',
                        label: t('label.tls', 'TLS'),
                        value:
                            (getSettingsItemValueByKey(data, IntegrationsSettingsMicrosoftItemEnum.tls) as
                                | boolean
                                | undefined) ?? false,
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                {
                    type: 'html',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'divider2',
                        render: () => (
                            <>
                                <Divider textAlign="left">
                                    <Typography variant="caption">{t('subTitle.keyMap', 'Key Map')}</Typography>
                                </Divider>
                                <Typography variant="subtitle2">
                                    {t(
                                        'message.info.microsoftActiveDirectoryKeyMap',
                                        'The key map from the active directory, where the key is case sensitive. The nested value can be set by dotted syntax. If the mapped value is array, it will automatically take first value.'
                                    )}
                                </Typography>
                            </>
                        ),
                        validation: {
                            deps: ['set_download_settings']
                        }
                    }
                },
                ...(requiredKeyMapOptions?.map((option) => {
                    const indexOfEnum = Object.values(IntegrationsSettingsMicrosoftItemKeyMapEnum).indexOf(
                        option as unknown as IntegrationsSettingsMicrosoftItemKeyMapEnum
                    );

                    const key = Object.keys(IntegrationsSettingsMicrosoftItemKeyMapEnum)[indexOfEnum];

                    return {
                        type: 'textField',
                        display: ({ microsoft_active_directory_login }) => {
                            const values = microsoft_active_directory_login as IOutputValueType;

                            return !!values?.set_download_settings;
                        },
                        props: {
                            required: ({ microsoft_active_directory_login }) => {
                                const values = microsoft_active_directory_login as IOutputValueType;

                                return !!values?.set_download_settings;
                            },
                            name: option,
                            label: t(
                                `enums.integrationsSettingsMicrosoftItemKeyMapEnum.${formatPascalToCamel(key)}`,
                                key
                            ),
                            type: 'text',
                            value: getSettingsItemValueByKey(data, option),
                            placeholder: getPlaceholder(option),
                            validation: {
                                deps: ['set_download_settings']
                            }
                        }
                    } as ISupportedFieldType;
                }) ?? []),
                {
                    type: 'multiRowInputs',
                    display: ({ microsoft_active_directory_login }) => {
                        const values = microsoft_active_directory_login as IOutputValueType;

                        return !!values?.set_download_settings;
                    },
                    props: {
                        name: 'optionalKeyMap',
                        validation: {
                            deps: ['set_download_settings']
                        },
                        maxNumberOfItems: optionalKeyMapOptions.length,
                        inputs: [
                            {
                                type: 'select',
                                props: {
                                    name: 'key',
                                    label: t('label.key', 'key'),
                                    width: 4,
                                    options: getOptionalRowInputOptions
                                }
                            },
                            {
                                type: 'textField',
                                props: {
                                    name: 'value',
                                    label: t('label.keyMapValue', 'Key Map Value'),
                                    type: 'text',
                                    width: 4
                                }
                            },
                            {
                                type: 'switch',
                                props: {
                                    name: 'setStartAndEndIndex',
                                    label: t('label.setIndex', 'Set Index'),
                                    width: 3,
                                    labelPlacement: 'top'
                                }
                            },
                            {
                                type: 'textField',
                                display: ({ setStartAndEndIndex }) => !!setStartAndEndIndex,
                                props: {
                                    name: 'indexStart',
                                    label: t('label.start', 'Start'),
                                    type: 'number',
                                    width: 5
                                }
                            },
                            {
                                type: 'textField',
                                display: ({ setStartAndEndIndex }) => !!setStartAndEndIndex,
                                props: {
                                    name: 'indexEnd',
                                    label: t('label.end', 'End'),
                                    type: 'number',
                                    width: 5
                                }
                            }
                        ]
                    }
                }
            ]}
        />
    );
};

export default Microsoft;
