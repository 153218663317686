import { AsyncThunkAction } from '@reduxjs/toolkit';
import { Ref, useCallback, useState } from 'react';
import FormGenerator, {
    IOutputValueType,
    IFormGeneratorProps,
    ISupportedFieldType,
    ISupportedValueType
} from '@/base/FormGenerator';
import ErrorBoundary from '@/components/ErrorBoundary';
import UserPermission, { isUserAllowed, Mode } from '@/components/UserPermision';
import { IRequestState } from '@/data/ApiRequest';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { addNotification } from '@/data/Notification/NotificationSlice';
import { getPermissionsList } from '@/data/System/SystemReducer';
import formatSnakeToPascal from '@/helpers/format/formatSnakeToPascal';
import useAppTranslation from '@/hooks/useAppTranslation';
import { PermissionsEnumType } from '@/utils/enums/PermissionsEnum';

export type ICuProps<Entity, IDType = number> = Omit<
    IFormGeneratorProps,
    'id' | 'displayAsModal' | 'fields' | 'isEdit' | 'justIcon' | 'name' | 'onOpen' | 'onSubmit' | 'openForm'
> & {
    formRef?: Ref<HTMLFormElement>;
    id?: IDType;
    justIcon?: boolean;
    editResource?: IResourceModeProp;
    createResource?: IResourceModeProp;
    displayAsModal?: boolean;
    displayAsSidebar?: boolean;
    hideActions?: boolean;
    disableEdit?: boolean;
    isValid?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onSuccessCreate?: (entity: Entity) => void;
    onSuccessUpdate?: (entity: Entity) => void;
    cancelButtonWidth?: number;
    submitButtonWidth?: number;
    successCreateMessage?: string;
    successUpdateMessage?: string;
    removeButtonWidth?: number;
};
export { type IOutputValueType };

type IResourceModeProp = {
    mode: Mode | Mode[];
    operator?: 'AND' | 'OR';
};

type INeedFromChildProps<CreateEntity, Entity, AsyncCommunication, IDType = number, DataModel = ISupportedValueType> = {
    isAsync?: AsyncCommunication extends true ? true : false;
    items: (ISupportedFieldType | null)[];
    creatingStatus?: IRequestState;
    updatingStatus?: IRequestState;
    removingStatus?: IRequestState;
    resource?: PermissionsEnumType | PermissionsEnumType[];
    onSubmitCreate?: (
        values: DataModel
    ) => AsyncCommunication extends false ? void : AsyncThunkAction<Entity, CreateEntity, {}>;
    onSubmitUpdate?: (
        values: DataModel
    ) => AsyncCommunication extends false ? void : AsyncThunkAction<Entity, { id: IDType; data: CreateEntity }, {}>;
    onRemove?: () => AsyncCommunication extends false ? void : Promise<void>;
    /** For tests */
    name: string;
};

type IProps<CreateEntity, Entity, AsyncCommunication, IDType = number, DataModel = ISupportedValueType> = ICuProps<
    Entity,
    IDType
> &
    INeedFromChildProps<CreateEntity, Entity, AsyncCommunication, IDType, DataModel>;

export default function CuForm<
    CreateEntity,
    Entity,
    IDType = number,
    AsyncCommunication = boolean,
    DataModel = ISupportedValueType
>(props: IProps<CreateEntity, Entity, AsyncCommunication, IDType, DataModel>) {
    const { t } = useAppTranslation();
    const {
        id,
        innerRef,
        isAsync = true,
        className,
        resource,
        editResource,
        createResource,
        disableEdit,
        displayAsModal,
        displayAsSidebar,
        hideActions,
        items,
        creatingStatus,
        updatingStatus,
        removingStatus,
        onOpen,
        onClose,
        cancelButtonWidth,
        submitButtonWidth,
        removeButtonWidth,
        onSubmitCreate,
        onSubmitUpdate,
        onRemove,
        onSuccessCreate,
        onSuccessUpdate,
        openButtonValue,
        justIcon,
        name,
        title,
        isValid = true,
        successCreateMessage = t('message.status.success', 'Success'),
        successUpdateMessage = t('message.status.success', 'Success'),
        ...rest
    } = props;
    const isEdit = typeof id !== 'undefined';
    const [isOpen, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const permissionsList = useAppSelector(getPermissionsList);
    const handleSuccessOfOperation = useCallback(() => {
        dispatch(
            addNotification({
                message: isEdit ? successUpdateMessage : successCreateMessage,
                variant: 'success'
            })
        );
    }, [isEdit, successUpdateMessage, successUpdateMessage]);
    const handleSuccessfulHandle = useCallback(() => {
        setOpen(false);

        if (onClose) {
            onClose();
        }
    }, []);

    const handleSuccessfulSubmit = useCallback((data: Entity) => {
        handleSuccessOfOperation();

        if (onSuccessCreate && !isEdit) {
            onSuccessCreate(data);
        }

        if (onSuccessUpdate && isEdit) {
            onSuccessUpdate(data);
        }

        setOpen(false);

        if (onClose) {
            onClose();
        }
    }, []);

    const handleRemove = useCallback(() => {
        if (onRemove) {
            const result = onRemove();

            if (result instanceof Object) {
                result.then(() => setOpen(false));
            } else {
                handleSuccessfulHandle();
            }
        }
    }, [id, isAsync, onRemove]);

    const handleSubmit = useCallback(
        (values: ISupportedValueType) => {
            if (isEdit) {
                if (!disableEdit && onSubmitUpdate) {
                    const result = onSubmitUpdate(values as DataModel);

                    if (result instanceof Object) {
                        dispatch(result).unwrap().then(handleSuccessfulSubmit);
                    } else {
                        handleSuccessfulHandle();
                    }
                }
            } else if (onSubmitCreate) {
                const result = onSubmitCreate(values as DataModel);

                if (result instanceof Object) {
                    dispatch(result).unwrap().then(handleSuccessfulSubmit);
                } else {
                    handleSuccessfulHandle();
                }
            }
        },
        [id, isEdit, disableEdit, onSubmitUpdate, onSubmitCreate, handleSuccessfulSubmit]
    );

    const handleCancel = useCallback(() => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const handleOpen = useCallback(() => {
        setOpen(true);
        if (onOpen) {
            onOpen();
        }
    }, [onOpen]);

    const isUserAllowedByResource = useCallback(
        (mode: Mode | Mode[]) => {
            return (resource && isUserAllowed({ id: resource, mode: mode }, permissionsList)) || !resource;
        },
        [resource, permissionsList]
    );

    const isUserAbleToAdd = useCallback(() => {
        return !isEdit && onSubmitCreate;
    }, [isEdit, onSubmitCreate]);

    const isUserAbleToEdit = useCallback(() => {
        return isEdit && onSubmitUpdate;
    }, [isEdit, onSubmitUpdate]);

    const isUserAbleToEditOrAdd = useCallback(() => {
        return (isUserAbleToAdd() || isUserAbleToEdit()) && isUserAllowedByResource(isEdit ? Mode.UPDATE : Mode.CREATE);
    }, [isEdit]);

    const isUserAbleToRemove = useCallback(() => {
        return isEdit && onRemove && isUserAllowedByResource(Mode.DELETE);
    }, [isEdit, onRemove, handleRemove]);

    const generateForm = (
        <FormGenerator
            {...rest}
            innerRef={innerRef}
            onClose={handleCancel}
            id={id ? `${id}` : undefined}
            name={name}
            className={className}
            isEdit={isEdit}
            justIcon={justIcon || false}
            openForm={isOpen}
            onOpen={handleOpen}
            title={
                title
                    ? title
                    : isEdit
                    ? t(`title.update${formatSnakeToPascal(name)}`, `Update ${name}`)
                    : t(`title.new${formatSnakeToPascal(name)}`, `New ${name}`)
            }
            displayAsModal={displayAsModal || false}
            displayAsSidebar={displayAsSidebar || false}
            openButtonValue={
                openButtonValue ||
                (isEdit
                    ? t(`label.update${formatSnakeToPascal(name)}`, `Update ${name}`)
                    : t(`label.add${formatSnakeToPascal(name)}`, `Add ${name}`))
            }
            onSubmit={handleSubmit}
            fields={items.filter((item) => typeof item === 'object') as ISupportedFieldType[]}
            actions={
                [
                    ...(hideActions
                        ? []
                        : [
                              {
                                  type: 'button',
                                  props: {
                                      type: 'button',
                                      name: 'cancel',
                                      disabled: removingStatus === 'loading',
                                      variant: 'text',
                                      onClick: handleCancel,
                                      children: t('label.cancel', 'Cancel'),
                                      width: cancelButtonWidth
                                  }
                              },
                              ...(isUserAbleToRemove()
                                  ? [
                                        {
                                            type: 'loadingButton',
                                            props: {
                                                type: 'button',
                                                name: 'remove',
                                                disabled: updatingStatus === 'loading',
                                                loading: removingStatus === 'loading',
                                                onClick: handleRemove,
                                                variant: 'outlined',
                                                color: 'error',
                                                children: t('label.remove', 'Remove'),
                                                width: removeButtonWidth
                                            }
                                        }
                                    ]
                                  : [])
                          ]),
                    ...(rest.actions ? rest.actions : []),
                    ...(isUserAbleToEditOrAdd()
                        ? [
                              {
                                  type: hideActions ? 'button' : 'loadingButton',
                                  props: {
                                      type: 'submit',
                                      name: 'default',
                                      disabled: removingStatus === 'loading' || !isValid,
                                      loading: hideActions
                                          ? ''
                                          : isEdit
                                          ? updatingStatus === 'loading'
                                          : creatingStatus === 'loading',
                                      variant: 'contained',
                                      hidden: hideActions,
                                      children: isEdit ? t('label.update', 'Update') : t('label.add', 'Add'),
                                      width: submitButtonWidth
                                  }
                              }
                          ]
                        : [])
                ] as ISupportedFieldType[]
            }
        />
    );

    return (
        <ErrorBoundary>
            {resource ? (
                <UserPermission
                    id={resource}
                    mode={isEdit ? editResource?.mode ?? Mode.UPDATE : createResource?.mode ?? Mode.CREATE}
                    operator={isEdit ? editResource?.operator : createResource?.operator}
                >
                    {generateForm}
                </UserPermission>
            ) : (
                generateForm
            )}
        </ErrorBoundary>
    );
}
