import { createSelector, createSlice } from '@reduxjs/toolkit';
import { IRequestState } from '@/data/ApiRequest';
import { IRootState } from '@/data/store';
import {
    fetchUserHours,
    fetchUserShifts,
    fetchUserSkills,
    getUserHoursFile,
    getUserShiftsFile,
    getUserSkillsFile
} from '@/data/Summaries/SummariesActions';
import { IUserHours, IUserShiftsSummaries, IUserSkills } from '@/data/Summaries/SummariesModels';

export type ISummariesSlice = {
    loadingUserShiftsSummariesListStatus: IRequestState;
    loadingUserHoursListStatus: IRequestState;
    loadingUserSkillsListStatus: IRequestState;
    userShiftsSummariesList: IUserShiftsSummaries[];
    userHoursList: IUserHours[];
    userSkillsList: IUserSkills[];
    loadingUserShiftsSummariesFileStatus: IRequestState;
    loadingUserHoursFileStatus: IRequestState;
    loadingUserSkillsFileStatus: IRequestState;
};

const initialState: ISummariesSlice = {
    loadingUserShiftsSummariesListStatus: 'idle',
    loadingUserHoursListStatus: 'idle',
    loadingUserSkillsListStatus: 'idle',
    loadingUserShiftsSummariesFileStatus: 'idle',
    loadingUserHoursFileStatus: 'idle',
    loadingUserSkillsFileStatus: 'idle',
    userShiftsSummariesList: [],
    userHoursList: [],
    userSkillsList: []
};

const summariesSlice = createSlice({
    name: 'summaries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserShifts.pending, (state) => {
            state.loadingUserShiftsSummariesListStatus = 'loading';
        });
        builder.addCase(fetchUserShifts.fulfilled, (state, action) => {
            state.loadingUserShiftsSummariesListStatus = 'idle';
            state.userShiftsSummariesList = action.payload;
        });
        builder.addCase(fetchUserShifts.rejected, (state) => {
            state.loadingUserShiftsSummariesListStatus = 'failed';
        });
        builder.addCase(getUserShiftsFile.pending, (state) => {
            state.loadingUserShiftsSummariesFileStatus = 'loading';
        });
        builder.addCase(getUserShiftsFile.fulfilled, (state, action) => {
            state.loadingUserShiftsSummariesFileStatus = 'idle';
        });
        builder.addCase(getUserShiftsFile.rejected, (state) => {
            state.loadingUserShiftsSummariesFileStatus = 'failed';
        });
        builder.addCase(getUserHoursFile.pending, (state) => {
            state.loadingUserHoursFileStatus = 'loading';
        });
        builder.addCase(getUserHoursFile.fulfilled, (state, action) => {
            state.loadingUserHoursFileStatus = 'idle';
        });
        builder.addCase(getUserHoursFile.rejected, (state) => {
            state.loadingUserHoursFileStatus = 'failed';
        });
        builder.addCase(getUserSkillsFile.pending, (state) => {
            state.loadingUserSkillsFileStatus = 'loading';
        });
        builder.addCase(getUserSkillsFile.fulfilled, (state, action) => {
            state.loadingUserSkillsFileStatus = 'idle';
        });
        builder.addCase(getUserSkillsFile.rejected, (state) => {
            state.loadingUserSkillsFileStatus = 'failed';
        });
        builder.addCase(fetchUserHours.pending, (state) => {
            state.loadingUserHoursListStatus = 'loading';
        });
        builder.addCase(fetchUserHours.fulfilled, (state, action) => {
            state.loadingUserHoursListStatus = 'idle';
            state.userHoursList = action.payload;
        });
        builder.addCase(fetchUserHours.rejected, (state) => {
            state.loadingUserHoursListStatus = 'failed';
        });
        builder.addCase(fetchUserSkills.pending, (state) => {
            state.loadingUserSkillsListStatus = 'loading';
        });
        builder.addCase(fetchUserSkills.fulfilled, (state, action) => {
            state.loadingUserSkillsListStatus = 'idle';
            state.userSkillsList = action.payload;
        });
        builder.addCase(fetchUserSkills.rejected, (state) => {
            state.loadingUserSkillsListStatus = 'failed';
        });
    }
});

export default summariesSlice;

const getState = (state: IRootState) => state[summariesSlice.name];

export const userShiftsSummariesStatus = (state: IRootState) => getState(state).loadingUserShiftsSummariesListStatus;
export const isUserShiftsSummariesLoading = (state: IRootState) =>
    getState(state).loadingUserShiftsSummariesListStatus === 'loading';
export const userShiftsSummariesList = (state: IRootState) => getState(state).userShiftsSummariesList;
export const userHoursStatus = (state: IRootState) => getState(state).loadingUserHoursListStatus;
export const isUserHoursLoading = (state: IRootState) => getState(state).loadingUserHoursListStatus === 'loading';
export const userHoursList = (state: IRootState) => getState(state).userHoursList;
export const userHoursListByPeriodAndWorkplace = (
    state: IRootState,
    periodId: number | null,
    workplaceId: number | null
) => {
    const data = getState(state).userHoursList.filter(
        (userHourItem) => userHourItem.period_id === periodId && userHourItem.workplace_id === workplaceId
    );

    return data.length > 0 ? data : null;
};
export const userSkillsStatus = (state: IRootState) => getState(state).loadingUserSkillsListStatus;
export const isUserSkillsLoading = (state: IRootState) => getState(state).loadingUserSkillsListStatus === 'loading';
export const userSkillsList = (state: IRootState) => getState(state).userSkillsList;
export const isDownloadingCsvFilesLoadingSelector = createSelector(
    (state: IRootState) => getState(state).loadingUserShiftsSummariesFileStatus === 'loading',
    (state: IRootState) => getState(state).loadingUserHoursFileStatus === 'loading',
    (state: IRootState) => getState(state).loadingUserSkillsFileStatus === 'loading',
    (...states) => states.some((item) => item)
);
