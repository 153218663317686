import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import intersection from '@/helpers/array/intersection';
import not from '@/helpers/array/not';
import useAppTranslation from '@/hooks/useAppTranslation';
import Button from '@/wrappers/Button';
import Tooltip from '@/wrappers/Tooltip';
import Content from './Content';

export type IItemType = {
    id: string;
    isDivider?: boolean;
    shouldDisplay?: (ids: string[]) => boolean;
    sortableValue: string;
    content?: ReactNode;
};

export type ITransferListProps = {
    className?: string;
    disabled?: boolean;
    externalSorting?: boolean;
    filteredItems?: boolean;
    height?: number;
    items: IItemType[];
    label?: string;
    name?: string;
    onChange?: (values: string[]) => void;
    values?: string[];
};

const StyledContainer = styled(Grid)`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: stretch;
`;
const StyledControls = styled(Grid)(
    ({ theme }) => `
        margin-inline: ${theme.spacing(2)};
        align-self: center;
    `
);
const StyledControlsContent = styled(Grid)(
    ({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${theme.spacing(2)};
    `
);

const StyledLabel = styled(Typography)(
    ({ theme }) => `
        margin-bottom: 0;
        margin-left: ${theme.spacing(1.5)};
        letter-spacing: 0.15px;
    `
);

const TransferList = ({
    className,
    disabled = false,
    externalSorting = false,
    filteredItems = false,
    label,
    name = '',
    values,
    items,
    height = 210,
    onChange
}: ITransferListProps) => {
    const { t } = useAppTranslation();
    const [checked, setChecked] = useState<string[]>([]);
    const [left, setLeft] = useState<string[]>(
        not(
            items.map((item) => item.id),
            values ?? []
        )
    );
    const [right, setRight] = useState<string[]>(values ?? []);
    const prevValues = useRef(values ?? []);
    const getByIds = (content: IItemType[], ids: string[]): IItemType[] =>
        content.filter(
            ({ id, shouldDisplay }) =>
                ids.includes(id) && (typeof shouldDisplay === 'undefined' || shouldDisplay(right))
        );
    const leftItems = getByIds(items, left);
    const rightItems = getByIds(items, right);
    const leftChecked = intersection(
        checked,
        leftItems.map(({ id }) => id)
    );
    const rightChecked = intersection(
        checked,
        rightItems.map(({ id }) => id)
    );
    const handleToggle = useCallback(
        (value: string) => {
            const currentIndex = checked.indexOf(value);

            if (currentIndex === -1) {
                setChecked([...checked, value]);
            } else {
                setChecked(checked.filter((checkedId) => checkedId !== value));
            }
        },
        [checked]
    );
    const handleCheckedRight = useCallback(() => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    }, [items, left, right, checked]);
    const handleCheckedLeft = useCallback(() => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    }, [items, left, right, checked]);
    const handleAllRight = useCallback(() => {
        const toMove = filteredItems ? items.filter((item) => left.includes(item.id)).map((item) => item.id) : left;

        setRight(right.concat(toMove));
        setLeft(not(left, toMove));
    }, [items, left, right, filteredItems]);
    const handleAllLeft = useCallback(() => {
        const toMove = filteredItems ? items.filter((item) => right.includes(item.id)).map((item) => item.id) : right;

        setLeft(left.concat(toMove));
        setRight(not(right, toMove));
    }, [items, left, right, filteredItems]);

    useEffect(() => {
        if (prevValues.current.length !== values?.length) {
            setLeft(not(left, values ?? []));
        }

        prevValues.current = values ?? [];
    }, [values]);
    useEffect(() => {
        if (onChange) {
            onChange(right);
        }
    }, [right]);
    useEffect(() => {
        setLeft(
            not(
                items.map((item) => item.id),
                values ?? []
            )
        );
    }, [items]);

    return (
        <>
            {label && <StyledLabel>{label}</StyledLabel>}
            <StyledContainer
                container
                className={classNames({
                    transferList: true,
                    [className || '']: true
                })}
                data-testid="transfer-list"
            >
                <Grid item>
                    <Content
                        name={name}
                        alreadySorted={externalSorting}
                        items={leftItems}
                        checked={checked}
                        handleToggle={handleToggle}
                    />
                </Grid>
                <StyledControls item>
                    <StyledControlsContent container>
                        <Tooltip color="primary" placement="right" title={t('title.moveAllRight', 'Move All Right')}>
                            <Button
                                name={`${name}moveAllRight`}
                                onClick={handleAllRight}
                                disabled={disabled || leftItems.length === 0}
                                data-testid="moveAllRight"
                            >
                                <KeyboardDoubleArrowRightIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            color="primary"
                            placement="right"
                            title={t('title.moveSelectedRight', 'Move Selected Right')}
                        >
                            <Button
                                name={`${name}moveSelectedRight`}
                                variant="outlined"
                                onClick={handleCheckedRight}
                                disabled={disabled || leftChecked.length === 0}
                                aria-label="move selected right"
                                data-testid="moveSelectedRight"
                            >
                                <KeyboardArrowRightIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip
                            color="primary"
                            placement="right"
                            title={t('title.moveSelectedLeft', 'Move Selected Left')}
                        >
                            <Button
                                name={`${name}moveSelectedLeft`}
                                variant="outlined"
                                onClick={handleCheckedLeft}
                                disabled={disabled || rightChecked.length === 0}
                                aria-label="move selected left"
                                data-testid="moveSelectedLeft"
                            >
                                <KeyboardArrowLeftIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip color="primary" placement="right" title={t('title.moveAllLeft', 'Move All Left')}>
                            <Button
                                name={`${name}moveAllLeft`}
                                onClick={handleAllLeft}
                                disabled={disabled || rightItems.length === 0}
                                aria-label="move all left"
                                data-testid="moveAllLeft"
                            >
                                <KeyboardDoubleArrowLeftIcon />
                            </Button>
                        </Tooltip>
                    </StyledControlsContent>
                </StyledControls>
                <Grid item>
                    <Content
                        name={`${name}Value`}
                        alreadySorted={externalSorting}
                        items={rightItems}
                        checked={checked}
                        handleToggle={handleToggle}
                    />
                </Grid>
            </StyledContainer>
        </>
    );
};

export default TransferList;
