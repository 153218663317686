import { IListResponse } from '@/data/ApiResponse';
import {
    ISchedulePlanPredictedTimeForecastAccuracyReportResponse,
    ISchedulePlanCoverageRequireForecastAccuracyReportResponse,
    IUserAttendanceReportResponse,
    IUserAttendanceReportRequest,
    IUserAttendanceReportFileRequest
} from '@/data/Reports/ReportModels';
import { fetchDownloadFile, fetchGet, urlBuilderWithParams } from '@/data/request';

export function fetchSchedulePlanCoverageRequire(schedulePlanId: number) {
    return fetchGet<IListResponse<ISchedulePlanCoverageRequireForecastAccuracyReportResponse>>(
        `/api/reports/schedule-plan-coverage-require/${schedulePlanId}`
    );
}

export function fetchSchedulePlanPredictedTime(schedulePlanId: number) {
    return fetchGet<IListResponse<ISchedulePlanPredictedTimeForecastAccuracyReportResponse>>(
        `/api/reports/schedule-plan-predicted-time/${schedulePlanId}`
    );
}

export function getUsersAttendanceFile(props?: IUserAttendanceReportFileRequest) {
    const url = urlBuilderWithParams('/api/reports/users-attendance-file', props ?? {});

    return fetchDownloadFile(url);
}

export function getUsersAttendance(props?: IUserAttendanceReportRequest) {
    const url = urlBuilderWithParams('/api/reports/users-attendance', props ?? {});

    return fetchGet<IListResponse<IUserAttendanceReportResponse>>(url);
}

export function getUsersCategoryTypeOptions() {
    const url = urlBuilderWithParams('/api/reports/user-category-type-options', {});

    return fetchGet<IListResponse<string>>(url);
}
