import { IFilters } from '@/data/Filters';
import { ISchedulePlanDayShiftFromBEModel } from '@/data/SchedulePlanDayShifts/SchedulePlanDayShiftModels';
import { ISchedulePlanDetail } from '@/data/SchedulePlans/SchedulePlanApi';
import { ISkillModel } from '@/data/Skills/SkillModels';
import { IUserVacationFundModel } from '@/data/UserToVacationFunds/UserToVacationFundModel';
import { IUserToVacationModel } from '@/data/UserToVacations/UserToVacationModels';
import UserToRequestsStateEnum from '@/utils/enums/UserToRequestsStateEnum';
import { IListResponse, IListResponseWithPaging } from '../ApiResponse';
import { IPaging } from '../Paging';
import { fetchDelete, fetchGet, fetchPatch, fetchPost, urlBuilderWithParams } from '../request';
import { ISchedulerUserToRequestFromBeModel, IUserToRequestCUModel } from './UserToRequestModels';

export function fetchList(paging: Partial<IPaging>) {
    const url = urlBuilderWithParams('/api/user-to-requests', paging || {});

    return fetchGet<IListResponseWithPaging<ISchedulerUserToRequestFromBeModel>>(url);
}

export function fetchSelectList(search: string, fields: string[] = [], filters: IFilters = {}) {
    const url = urlBuilderWithParams('/api/user-to-requests/select', { search, fields, filters });

    return fetchGet<IListResponse<ISchedulerUserToRequestFromBeModel>>(url);
}

export function fetchSchedulerList(filters: IFilters = {}) {
    const url = urlBuilderWithParams('/api/user-to-requests/requests-for-scheduler', { filters });

    return fetchGet<IListResponse<ISchedulerUserToRequestFromBeModel>>(url);
}

export function fetchById(id: number) {
    return fetchGet<ISchedulerUserToRequestFromBeModel>(`/api/user-to-requests/${id}`);
}

export function create(data: IUserToRequestCUModel) {
    return fetchPost<ISchedulerUserToRequestFromBeModel>('/api/user-to-requests/', data);
}

export function update(id: number, data: Partial<IUserToRequestCUModel>) {
    return fetchPatch<ISchedulerUserToRequestFromBeModel>(`/api/user-to-requests/${id}`, data);
}

export function remove(id: number) {
    return fetchDelete(`/api/user-to-requests/${id}`);
}

export function recalculateFund(id: number) {
    return fetchPost<ISchedulerUserToRequestFromBeModel>(`/api/user-to-requests/recalculate-fund/${id}`);
}

export function patchState(id: number, value: UserToRequestsStateEnum, schedulePlanId?: number) {
    return fetchPatch<
        ISchedulePlanDetail & {
            user_to_vacations: IUserToVacationModel | null;
            user_to_vacations_funds: IUserVacationFundModel[] | null;
            schedule_plan_day_shifts?: ISchedulePlanDayShiftFromBEModel[];
            request: ISchedulerUserToRequestFromBeModel | null;
            skills?: ISkillModel[];
            skills_from_shift?: ISkillModel[];
        }
    >(
        `/api/user-to-requests/patch-state/${id}`,
        {
            state: value,
            schedulePlanId: schedulePlanId
        },
        false
    );
}
