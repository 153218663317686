import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import TextField from '@/wrappers/TextField';
import { IItemType } from '@/wrappers/TransferList';
import { VirtualizedContent } from '@/wrappers/TransferList/Content/VirtualizedContent';

type IContent = {
    alreadySorted: boolean;
    items: IItemType[];
    checked: string[];
    name?: string;
    handleToggle: (id: string) => void;
};

const StyledItem = styled(ListItemButton)`
    padding: 0;
`;

const Content = ({ alreadySorted, items, checked, name = '', handleToggle }: IContent) => {
    const [searchedValue, setSearchedValue] = useState('');

    const debouncedValue = useDebounce(searchedValue);

    const itemsToRender = (
        alreadySorted ? items : items.sort((a, b) => a.sortableValue.localeCompare(b.sortableValue))
    ).filter((item) => item.sortableValue.toLowerCase().includes(debouncedValue));

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            sx={{ display: 'grid', gridTemplateRows: 'auto 210px', height: '100%', overflow: 'auto' }}
        >
            <Grid item>
                <TextField
                    name={`${name}search`}
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    value={null}
                    variant="standard"
                    onChange={(_event, value) => setSearchedValue(value.toLowerCase())}
                />
            </Grid>
            <Grid data-testid={`${name}contentWrapper`} item sx={{ height: '100%' }}>
                <VirtualizedContent rowHeight={50}>
                    {itemsToRender
                        .filter((item) => item.sortableValue.toLowerCase().includes(debouncedValue))
                        .map((item) =>
                            !item.isDivider ? (
                                <StyledItem
                                    key={item.id}
                                    role="listitem"
                                    onClick={() => handleToggle(item.id)}
                                    data-testid={`transferItem-${item.id}`}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(item.id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': `transfer-list-item-${item.id}-label`
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={`transfer-list-item-${item.id}-label`}
                                        primary={item.content ?? item.sortableValue}
                                    />
                                </StyledItem>
                            ) : (
                                <Divider key={item.id} />
                            )
                        )}
                </VirtualizedContent>
            </Grid>
        </Grid>
    );
};

export default Content;
