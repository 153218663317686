import CircularProgress from '@mui/material/CircularProgress';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import EmptyLayout from '@/components/layouts/EmptyLayout';
import MainLayout from '@/components/layouts/MainLayout';
import NotificationStack from '@/components/layouts/NotificationStack';
import PopupAlert from '@/components/layouts/PopupAlert';
import { WebSocketContext } from '@/components/providers/WebSocketContextProvider';
import { useAppDispatch, useAppSelector } from '@/data/hooks';
import { setLanguage } from '@/data/Languages/LanguageReducer';
import { connectToWS, currentSocket } from '@/data/Notification/NotificationActions';
import { refreshToken, signInWithToken } from '@/data/System/SystemActions';
import { isSigned as isUserSigned, refreshTokenStatus } from '@/data/System/SystemReducer';
import useAppTranslation from '@/hooks/useAppTranslation';
import LoginPage from '@/pages/LoginPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import WelcomePage from '@/pages/WelcomePage';
import UserSourceEnum from '@/utils/enums/UserSourceEnum';
import * as routes from '@/utils/routes';
import { routeWithoutAuthMapping } from '@/utils/routes';

function App() {
    const isSigned = useAppSelector(isUserSigned);
    const checkToken = useAppSelector(refreshTokenStatus);
    const [location, setLocation] = useState<string | undefined>(routes.welcome());
    const [, setSocket] = useContext(WebSocketContext);
    const dispatch = useAppDispatch();
    const { pathname, search } = useLocation();
    const { i18n } = useAppTranslation();

    const params = new URLSearchParams(search);

    useEffect(() => {
        if (pathname !== routeWithoutAuthMapping.resetPassword) {
            dispatch(refreshToken())
                .unwrap()
                .then((res) => {
                    dispatch(connectToWS()).then(() => setSocket(currentSocket));
                    i18n.changeLanguage(res.language, () => dispatch(setLanguage({ language: res.language })));
                });
        }
    }, []);

    useEffect(() => {
        if (pathname === routeWithoutAuthMapping.resetPassword) {
            setLocation(routes.resetPassword());
        }

        if (checkToken === 'passed' || checkToken === 'failed') {
            setTimeout(() => {
                const code = params.get('code');

                if (!isSigned && code) {
                    dispatch(signInWithToken({ token: code, type: UserSourceEnum.AzureActiveDirectory }));
                    params.delete('code');
                    params.delete('session_state');
                    params.delete('state');
                }

                if (isSigned) {
                    setLocation(undefined);
                } else if (pathname !== routeWithoutAuthMapping.resetPassword) {
                    setLocation(routes.login() + (params.size > 0 ? '?' + params.toString() : ''));
                }
            }, 1000);
        }
    }, [isSigned, checkToken]);

    return (
        <>
            <Routes location={location}>
                <Route element={<EmptyLayout />}>
                    <Route index element={<LoginPage />} />
                    <Route path={routes.resetPassword()} element={<ResetPasswordPage setLocation={setLocation} />} />
                    <Route path={routes.login()} element={<LoginPage />} />
                    <Route path={routes.welcome()} element={<WelcomePage />} />
                </Route>
                <Route path={`${routes.MAIN_URL}/*`} element={<MainLayout />} />
                <Route path="*" element={<EmptyLayout />} />
            </Routes>
            <Suspense fallback={<CircularProgress disableShrink />}>
                <PopupAlert />
            </Suspense>
            <NotificationStack />
        </>
    );
}

export default App;
